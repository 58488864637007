export function handleResponse(response) {
  if (response.status === 504) {
    throw new Error("error.global.serviceNotAvailable");
  }

  return response
    .json()
    .then((res) => {
      const data = res;
      if (!response.ok) {
        const error =
          (data && (data.error || data.msg))
            ? (data.error || data.msg)
            : "Error :Bad Request";
        return Promise.reject(error);
      }
      return data;
    })
    .catch((err) => {
      console.log("error", err);
      throw err;
    });
}
