import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";

function Modals({show, setShow, t, noClose, setUser, loginTab = 0}) {

  const fn = () => {};
  const setUserTmp = setUser || fn;
  const [type, setType] = useState(t);

  const location = useLocation();

  useEffect(() => {
    if (show) { setShow(false); }
  }, [location])

  useEffect(() => {
    setType(t);
  }, [t])


  if (!show) { return null; }

  return <LoginModal setUser={setUserTmp} show={show} setShow={noClose ? fn: setShow} toggleType={setType} noClose={noClose} loginTab={loginTab}/>
  if (type === 'login') {
    return <LoginModal setUser={setUserTmp} show={show} setShow={noClose ? fn: setShow} toggleType={setType} noClose={noClose} loginTab={loginTab}/>
  } else if (type === 'signup') {
    return <SignUpModal show={show} setShow={noClose ? fn: setShow} toggleType={setType} noClose={noClose}/>
  }
}

export default Modals;
