import React, { useState, useEffect } from 'react';
import "emoji-mart/css/emoji-mart.css";
import "./emojiMenu/emojiMenuAnimation.css";
import ChatContent from "./ChatContent";
import { Spin } from 'antd';

function PrivateChat({basicInfo, event, rece, updateUnread}) {

  const [ws, setWs] = useState({});
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scroll, setScroll] = useState(true);
  const [sender, setSender] = useState({});
  const [loading, setLoading] = useState(false);

  let receiver = {}
  if (event) {
    receiver = { id: event.universityId, type: 'university' };
  } else {
    receiver = {id: rece.instId, type: rece.userType}
  }

  useEffect(() => {
    createWebSocket();
    return () => ws.close && ws.close();
  }, []);


  const createWebSocket = () => {
    setLoading(true);
    const setMessagesRead = message => {
      ws.send(
        JSON.stringify({
          action: "Set_Message_Readed",
          data: {
            mimeType: "number",
            content: message.id,
          },
        })
      );
    };

    const onmessage = event => {
      const data = JSON.parse(event.data);
      switch (data.action) {
        case 'History':
          // const arr = data.data.reverse().map(i => {
          //   return {data: i, sender: data.sender}
          // });
          const tmp = data.data.reverse()
          setList(list => [...tmp, ...list]);
          setLoading(false);
          const unRead = tmp.filter(i => !i.readed);
          for (let i of unRead) {
            setMessagesRead(i);
          }

          // setSender(data.sender);
          // setList(list => {
          //   const tmp = list.map(i => i.data);
          //   if (tmp.find(i => i.id === data.data.id)) {
          //     return list;
          //   } else {
          //     return [data, ...list];
          //   }
          // });
          // if (!data.data.readed) {
          //   setMessagesRead(data.data);
          // }
          break;
        case 'Message':
          const canAdd1 = data.sender.instId === receiver.id;
          const canAdd2 = data.sender.instId === basicInfo.instId;
          if (canAdd1 || canAdd2) {
            setList(list => {
              const tmp = [...list]
              if (tmp.find(i => i.id === data.data.id)) {
                return list;
              } else {
                return [...list, {...data.data, sender: data.sender}];
              }
            });
            setScroll(true);
            if (!data.data.readed) {
              setMessagesRead(data.data);
            }
          } else {
            updateUnread && updateUnread(data.sender.instId);
          }
          break;
        case 'No_More_Message':
          setHasMore(false);
          setLoading(false);
          break;
      }

    };

    const onerror = () => {

    };

    const onclose = () => {
    };

    const onopen = () => {
      const bindData = {
        action: "Bind",
        data: {
          mimeType: "json",
          content: {
            senderId: basicInfo.id,
            chatRoom: "normal",
            avatar:
              basicInfo && basicInfo.logo
                ? basicInfo.logo
                : "https://udentity.oss-cn-hangzhou.aliyuncs.com/2755/202009261456_avatar.jpg",
            name: basicInfo.displayName,
            userType: basicInfo.type,
            instId: basicInfo.instId,
          },
        },
      };

      ws.send(JSON.stringify(bindData));

      const getHistory = () => {
        const historyData = {
          action: "History",
          data: {
            mimeType: "json",
            content: {
              timestamp: new Date().getTime(),
              other: receiver.id,
              otherType: receiver.type
            },
          },
        }
        ws.send(JSON.stringify(historyData));
      }

      setTimeout(getHistory, 800);

    };

    let url = location.host === "frontend.udty.tech" ? `wss://ws-t.udty.tech` : `wss://ws.udty.tech`;
    if (location.host === 'localhost:3000') url = 'wss://ws-t.udty.tech';
    const ws = new WebSocket(`${url}/api/v3/ws/message`);
    ws.onmessage = onmessage;
    ws.onerror = onerror;
    ws.onclose = onclose;
    ws.onopen = onopen;
    setWs(ws);
  };

  const sendMsg = message => {
    const sendData = {
      action: "Message",
      data: {
        mimeType: "text",
        content: message,
      },
      receiver: receiver.id,
      receiverType: receiver.type,
    };
    ws.send(JSON.stringify(sendData));
  };

  const sendFile = ({fileName, fileUrl}) => {
    const sendData = {
      action: "Message",
      data: {
        mimeType: "file",
        content: { fileName, fileUrl },
      },
      receiver: receiver.id,
      receiverType: receiver.type,
    };
    ws.send(JSON.stringify(sendData));
  };

  const loadMore = () => {
    const historyData = {
      action: "History",
      data: {
        mimeType: "json",
        content: {
          timestamp: list[0].timestamp,
          other: receiver.id,
          otherType: receiver.type
        },
      },
    }
    ws.send(JSON.stringify(historyData));
    setScroll(false);
  };

  const loadEvents = {
    scroll,
    hasMore,
    loadMore
  };

  if (loading) return <div style={{height: '100%', display: 'flex', alignItem: 'center', justifyContent: 'center'}}><Spin /></div>

  return (
    <ChatContent isPrivate={true} sendFile={sendFile} list={list} basicInfo={basicInfo} sendMsg={sendMsg} loadEvents={loadEvents}/>
  );
}

export default PrivateChat;
