import React, {useState, useEffect} from 'react';
import { Modal, Select, message } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import useOutsideAlerter from "../new-event/useOutsideAlerter";
import userService from "../../../services/user-service";
import importAllImg from "../../../helpers/import-all-imgs";
import userHelper from "../../../helpers/user-helper";
import { useHistory } from 'react-router-dom';
import {decrypted, encrypted} from "../../../app/utils";

const { Option } = Select;
const imgs = importAllImg(require.context('../../../../src/assets/img/signup', false, /\.(png|jpe?g|svg)$/));


const years = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026];

const Container = styled.div`
  padding: 60px 40px;
  width: 460px;
  .sp-name {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    & > p {
      display: flex;
      flex-flow: column;
      margin-bottom: 0;
      &:nth-of-type(1) {
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        margin-left: 20px;
      }
    }
  }

  .sp-label {
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #333333;
    text-align: left !important;

    @media (max-width: 991px) {
      font-size: 15px;
    }
  }

  .sp-half-input {
    width: 170px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding-left: 15px;
    transition: border-color 0.5s;

    @media (max-width: 991px) {
      width: 128px;
    }

    &:hover {
      border-color: #1771C7;
    }

    &:focus {
      outline: none;
      border-color: #1771C7;
    }
  }

  .p-margin {
    margin-bottom: 16px;
  }

  .suggestions {
    position: absolute;
    top: 72px;
    left: 0;
    z-index: 2;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    list-style: none;
    padding-left: 0;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    color: rgba(0,0,0,.65);
    line-height: 1.5;

    & > li {
      padding: 5px 12px;
      overflow: hidden;
      color: rgba(0,0,0,.65);
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background .3s ease;
      &:hover {
        background-color: #e6f7ff;
      }
    }
  }

  .sp-input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding-left: 15px;
    transition: border-color 0.5s;


    &:hover {
      border-color: #1771C7;
    }

    &:focus {
      outline: none;
      border-color: #1771C7;
    }
  }
  
  .title {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #595959;
  }
  
  .role {
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 2px solid #1771C7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #1771C7;
    margin: 10px auto;
  }
  
  .selected-role {
    background: #1771C7;
    color: white;
  }
`


function AddInfoModal({show, setShow, setUser, tmpUser}) {

  const [type, setType] = useState('student');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [school, setSchool] = useState({high_school_id: null, high_school: ''}); //schoolText
  const [grade, setGrade] = useState('');
  const [list, setList] = useState([]);
  const { formatMessage } = useIntl();
  const [showSelect, setShowSelect] = useState(false);
  const selectRef = useOutsideAlerter(() => setShowSelect(false));
  const history = useHistory();

  useEffect(() => {
    userService.getHighschoolList().then(res => setList(res));
  }, []);

  const submit = () => {
    if (!firstName) return message.error('名不能为空');
    if (!lastName) return message.error('姓不能为空');
    if (!school.high_school) return message.error('学校不能为空');
    if (type === 'student' && !grade) return message.error('毕业年份不能为空')
    const params = { type, firstName, lastName, schoolText: school.high_school }
    if (type === 'student') params.grade = grade;
    userService.addInfo(params, tmpUser.auth_token).then(res => {
      message.success('success');
      setShow(false);
      setUser({...tmpUser, auth_token: res.msg});
      userHelper.setUser({...tmpUser, auth_token: res.msg});
      history.push(location.pathname);
    }, rej => message.error(rej));
  };

  const fm = id => formatMessage({id});

  let suggestions;
  const haveCn = /.*[\u4e00-\u9fa5]+.*$/.test(school.high_school);
  if (haveCn) {
    suggestions = list.filter(i => i.name && i.name.includes(school.high_school));
  } else {
    suggestions = list.filter(i => i.name_eng && i.name_eng.toLocaleLowerCase().includes(school.high_school.toLocaleLowerCase()));
  }

  const changeTypeSchool = async (e) => {
    setSchool({high_school: e.target.value, high_school_id: null})
    try {
      const aesData = encrypted({keyword: e.target.value});
      const { data } = await userService.getHighschool(aesData);
      const list = decrypted(data);
      setList(list);
      setShowSelect(true);
    } catch (e) {
      console.log(e)
      message.error(JSON.stringify(e))
    }
  };

  return (
    <Modal
      title={null}
      footer={null}
      centered={true}
      visible={show}
      onCancel={() => setShow(false)}
      width='auto'
      bodyStyle={{padding: 0}}
      closeIcon={<img src={imgs['Group 1981.svg']}/>}
    >
      <Container>
        <div className='title'>{fm('*Continue to log in after supplementing the information')}</div>
        <div className={type === 'student' ? 'selected-role role' : 'role'} onClick={() => setType('student')}>STUDENT</div>
        <div className={type === 'parent' ? 'selected-role role' : 'role'} onClick={() => setType('parent')}>PARENT</div>
        <div className='sp-name'>
          <p>
            <label className='sp-label'>{fm('First Name')}</label>
            <input required className='sp-half-input' placeholder={fm('First Name')} value={firstName} onChange={e => setFirstName(e.target.value)}/>
          </p>
          <p>
            <label className='sp-label'>{fm('Last Name')}</label>
            <input required className='sp-half-input' placeholder={fm('Last Name')} value={lastName} onChange={e => setLastName(e.target.value)}/>
          </p>
        </div>
        <div className='position-relative p-margin'>
          <label className='sp-label'>{type === 'student' ? fm('Current School') : fm("Child’s School")}</label>
          <input required className='sp-input' placeholder={fm('Type a School')} value={school.high_school} onChange={e => {changeTypeSchool(e)}}/>
          <ul ref={selectRef} className='suggestions' style={{display: showSelect ? 'block' : 'none'}}>
            {list.map(i => <li key={i.id} onClick={() => { setSchool({high_school_id: i.id, high_school: haveCn ? i.name : i.nameEng}); setShowSelect(false) }}>{haveCn ? i.name : i.nameEng}</li>)}
          </ul>
        </div>

        <div style={{display: type === 'student' ? '' : 'none'}}>
          <label className='sp-label'>{fm('Expected Year of Graduation')}</label>
          <Select
            placeholder={fm('Select Expected Year of Graduation')}
            style={{ width: '100%', height: '40px', borderRadius: '8px', marginBottom: '15px' }}
            suffixIcon={<img src={imgs['Vector.svg']} />}
            onChange={value => {setGrade(value)}}
          >
            {years.map(i => <Option key={i} value={i}>{i}</Option>)}
          </Select>
        </div>

        <div className='role selected-role' onClick={submit}>{fm('Confirm')}</div>
      </Container>
    </Modal>
  );
}

export default AddInfoModal;
