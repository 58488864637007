import React from 'react';
import fallbackCopyTextToClipboard from "../../../helpers/fallback-copy";
import { message } from 'antd';
import { useIntl } from 'react-intl';


function useCopyText() {

  const { formatMessage } = useIntl();

  const copy = text => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        message.success(formatMessage({ id: "copySuccess" }));
      });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  return copy;
}

export default useCopyText;
