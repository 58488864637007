const PUBLIC_PAGES = [
  { url: "/" },
  { url: "/about-us" },
  { url: "/features" },
  { url: "/events" },
  { url: "/faqs" },
  { url: "/counselor/signup" },
  { url: "/university/signup" },
  { url: "/student/signup" },
  { url: "/reset-password" },
  { url: "/counselor/signup-confirmed" },
  { url: "/university/signup-confirmed" },
  // { url: "/college-fair"}

];

export default {
  PUBLIC_PAGES
};
