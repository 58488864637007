import React, { useRef, useEffect } from "react";

function useOutsideAlerter(fn) {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref) { return; }

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        fn();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return ref;
}

export default useOutsideAlerter;