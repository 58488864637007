import React, { useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import styled, { createGlobalStyle } from "styled-components";
import importAllImg from "../../../helpers/import-all-imgs";

const SolutionContainer = styled.div`
  background: #F3F8FC;
  padding: 26px 0 36px 0;
  display: flex;
  text-align: left;
  @media (max-width: 991px) {
    background: white;
    display: block;
    padding: 12px 0;
  }
  
  .bottom-panel {
    margin-top: 27px;
    text-align: right;
    @media (max-width: 991px) {
      margin-top: 13px;
      text-align: center;
    }
    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.1px;
      color: #F2F2F7;
      background: #1771C7;
      border-radius: 5px;
      width: 390px;
      height: 54px;
      cursor: pointer;
      @media (max-width: 991px) {
        width: 200px;
        height: 30px;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  & > img {
    width: 450px;
    height: 270px;
    margin-right: 60px;
    //margin-top: 30px;

    @media (max-width: 991px) {
      display: none;
    }
  }
  
  & > div {
    margin-top: 30px;
  }

  .trust-item {
    background: #FFFFFF;
    border: 2px solid #C4C4C4;
    border-radius: 5px;
    padding: 18px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
    

    & > img:nth-child(1) {
      margin-right: 17px;
      @media (max-width: 991px) {
        height: 30px;
      }
    }
    & > div:nth-child(2) {
      min-width: 278px;
      @media (max-width: 991px) {
        min-width: 70%;
      }
    }
    & > img:nth-child(3) {
      align-self: flex-start;
      margin-left: 23px;
    }
  }

  .trust-item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #1C1C1E;
    margin-bottom: 8px;


    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 0;
    }

  }

  .trust-item-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1C1C1E;
    //margin-left: 8px;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .trust-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    width: 90%;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      margin: 0 auto;
      grid-gap: 16px;
      width: 85%;
    }
  }
  
  .top-panel {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #1C1C1E;
    margin-right: 15px;
  }
  
  .content {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #1C1C1E;
    //max-width: 490px;
    text-align: left;
  }
`;

const CustomPopover = createGlobalStyle`
  .cus-popover  {
    left: 0 !important;
    right: 0 !important;
    .ant-popover-content {
    }
    
    .ant-popover-inner-content {
      padding: 0;
    }
    
    .ant-popover-arrow {
      display: none;
    }
  }
  

`;

const imgs = importAllImg(require.context('../../../../src/assets/img/new-solutions', false, /\.(png|jpe?g|svg)$/));

function InsightsPanel({children}) {

  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const solutionContent = (
    <SolutionContainer>
      <img src={imgs['Udentity Social Media Post 2.png']} />
      <div>
        <div className='top-panel'>
          <div className='title'>Udentity Insights</div>
          <div className='content'>Resources for education representatives to build a digital presence for <br/> international recruitment </div>
        </div>
        <div className='trust-items'>

          <div className='trust-item' onClick={() => {history.push('/chinese-student-recruitment/strategy-solutions'); setVisible(false)}}>
            <img src={imgs['bulb-icon 1.svg']} />
            <div>
              <div className='trust-item-title'>Resources Page</div>
              <div className='trust-item-content'>Guides, videos, webinars and tools to help with your digital recruitment</div>
            </div>
            <img src={imgs['right arrow.svg']} />
          </div>

          <div className='trust-item' onClick={() => {history.push('/chinese-student-recruitment/strategy-solutions');  setVisible(false)}}>
            <img src={imgs['trend-two.svg']} />
            <div>
              <div className='trust-item-title'>Udentity Trends Report</div>
              <div className='trust-item-content'>Udentity China International Higher Education Trends 2021-2022</div>
            </div>
            <img src={imgs['right arrow.svg']} />
          </div>

        </div>

      </div>

    </SolutionContainer>
  );

  return (
    <>
      <CustomPopover />
      <Popover placement="bottom" trigger="hover" onVisibleChange={visible => setVisible(visible)}  visible={visible} overlayClassName='cus-popover' content={solutionContent}>
        {children}
        {/*{ React.cloneElement( children, { onMouseOver: () => setVisible(true) } ) }*/}
      </Popover>
    </>
  );
}

export default InsightsPanel
