import React, { useState } from 'react';
import styled from "styled-components";
import { copyMessage, moreOptions, translate, quoteMessage } from "../../../helpers/images-helper";
import { Popover, Tooltip } from 'antd';
import messageService from "../../../services/shared/message";
import _ from 'lodash';
import { useIntl } from 'react-intl';
import useOutsideAlerter from "./useOutsideAlerter";
import Linkify from 'react-linkify';
import useCopyText from "../fair-components/useCopyText";
import importAllImg from "../../../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));
const imgs2 = importAllImg(require.context('../../../../src/assets/img/msg', false, /\.(png|jpe?g|svg)$/));
const ItemContainer = styled.div`
  display: flex;
  flex-flow: ${ props => props.isCurrentUser ? 'row-reverse nowrap' : 'row nowrap' };
  margin-top: 10px;
  align-items: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  // & > div:nth-child(1) {
  //   margin: 0 5px;
  // }
  // & > div:nth-child(1) {
  //   //background: #EAEAEA;
  //   background-color: ${ props => props.isCurrentUser ? 'rgb(23, 113, 199)' : '#EAEAEA' };
  //   padding: 10px;
  //   border-radius: 50%;
  // }
  a {
    color: ${ props => props.isCurrentUser ? 'white' : '#212529' };;
    text-decoration: underline;
  }
  & > div:nth-child(1) {
    background-color: ${ props => props.isCurrentUser ? '#5BA6EC' : '#F2F2F7' };
    
    display: flex;
    flex-flow: column nowrap;
    border-radius: 14px;
    padding: 10px;
    //margin: 0 10px;
    
    & > div:nth-child(1) {
      color: ${ props => props.isCurrentUser ? '#1C1C1E' : '#1771C7' };
    }

    & > div:nth-child(2) {
      color: ${ props => props.isCurrentUser ? '#FFFFFF' : '#1C1C1E' };
    }
    
    & > div {
      width: 100%;
      word-break: break-word;
    }
    
    & > div:nth-child(1) {
      font-size: 12px;
    }
  }
`;

const TsPanel = styled.div`
  display: flex;
  justify-content: ${props => props.current ? 'flex-end' : 'flex-start'};
  
  & > div {
    color: ${props => props.current ? '#fff' : '#000'};
    background-color: ${props => props.current ? '#5BA6EC' : '#F2F2F7'};
    margin-left: ${props => props.current ? 'auto' : '2.5%'};
    margin-right: ${props => props.current ? '2.5%' : 'auto'};
    margin-top: 5px;
    margin-bottom: 10px; 
    word-break: break-word;
    border-radius: 10px; 
    padding: 5px 10px;
  }
`;

function ChatItem({ user, itemInfo, setMessage, scrollToBottom, isLast, scrollTextArea }) {

  const [ts, setTs] = useState({ has: false, ms: '' });
  const [visible, setVisible] = useState(false);
  const ref = useOutsideAlerter(() => setVisible(false));
  const { formatMessage } = useIntl();
  const copy = useCopyText();
  const { sender, content, mimeType } = itemInfo;
  // const mimeType = data.mimeType;


  const isCurrentUser = sender.userId === user.id;

  const formatContent = () => {
    let tmp = [];
    if (mimeType === 'text') {
      if (content.includes("\n")) {
        tmp = content.split("\n").map((i, index) => <React.Fragment key={index}>{i}<br/></React.Fragment>)
      } else {
        tmp = content;
      }
      return (
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
          {tmp}
        </Linkify>
      );
    } else if (mimeType === 'file') {
      let fileUrl = ''
      if (typeof content === 'object') {
        tmp = content.fileName;
        fileUrl = content.fileUrl;
      } else if (typeof content === 'string') {
        tmp = JSON.parse(content).fileName;
        fileUrl = JSON.parse(content).fileUrl;
      }
      return (
        <a target='_blank' href={fileUrl}>{tmp}</a>
      );
    }


  };

  const handleTs = () => {
    let tmp = ''
    if (mimeType === 'text') {
      tmp = content;
    } else if (mimeType === 'file') {
      if (typeof content === 'object') {
        tmp = content.fileName;
      } else if (typeof content === 'string') {
        tmp = JSON.parse(content).fileName;
      }
    }
    messageService.translation(tmp).then(res => {
      setVisible(false);
      setTs({ has: true, ms: res.data.translated });
      if (scrollToBottom && isLast) {
        scrollToBottom();
      }
    });
  };

  const renderOptions = () => {

    let handleCopy = () => {
      if (mimeType === 'text') {
        copy(content);
      } else if (mimeType === 'file') {
        if (typeof content === 'object') {
          const tmp = content.fileName;
          copy(tmp);
        } else if (typeof content === 'string') {
          const tmp = JSON.parse(content).fileName;
          copy(tmp);
        }
      }
      setVisible(false);
    };

    let handleQuote = () => {
      let quoteText = "";
      if (mimeType === 'text') {
        if (content.includes("—————————")) {
          quoteText = `「${
            sender.name ? sender.name : user.name
          }: ${
            content.split("—————————")[1].split("\n")[1]
          }」\n—————————\n`;
        } else {
          quoteText = `「${
            sender.name ? sender.name : user.name
          }: ${content}」\n—————————\n`;
        }
      } else if (mimeType === 'file') {
        if (typeof content === 'object') {
          const tmp = content.fileName;
          quoteText = `「${
            sender.name ? sender.name : user.name
          }: ${tmp}」\n—————————\n`;
        } else if (typeof content === 'string') {
          const tmp = JSON.parse(content).fileName;
          quoteText = `「${
            sender.name ? sender.name : user.name
          }: ${tmp}」\n—————————\n`;
        }
      }

      setMessage(quoteText);
      if (scrollTextArea) scrollTextArea();
      setVisible(false);
    }

    handleCopy = _.debounce(handleCopy, 500);
    handleQuote = _.debounce(handleQuote, 500);

   const content2 = (
     <div ref={ref} className="flex-row align-items-center justify-content-center" style={{margin: '-6px -10px'}}>
       <div
         className="flex-row flex-column align-items-center cursor"
         style={{padding: '10px'}}
         onClick={handleCopy}
       >
         <Tooltip title={formatMessage({
           id: "copy",
         })}>
           <img
             width="20"
             height="20"
             src={copyMessage}
           />
         </Tooltip>

         {/*<div*/}
         {/*  className="flex-row flex-column  font-12 align-items-center cursor mt-2"*/}
         {/*>*/}
         {/*  {formatMessage({*/}
         {/*    id: "copy",*/}
         {/*  })}*/}
         {/*</div>*/}
       </div>
       <div
         className="flex-row flex-column align-items-center cursor"
         style={{padding: '10px'}}
         onClick={handleQuote}
       >
         <Tooltip title={formatMessage({
           id: "quoteMessage",
         })}>
         <img
           width="20"
           height="20"
           src={quoteMessage}
         />
         </Tooltip>
         {/*<div*/}
         {/*  className="flex-row flex-column  font-12 align-items-center cursor mt-2"*/}
         {/*>*/}
         {/*  {formatMessage({*/}
         {/*    id: "quoteMessage",*/}
         {/*  })}*/}
         {/*</div>*/}
       </div>
       <div
         className="flex-row flex-column align-items-center cursor"
         style={{padding: '10px'}}
         // onClick={() => handleTranslateMessage(data.content, `${sender.userId}${data.id}`)}
         onClick={handleTs}
       >
         <Tooltip title={formatMessage({
           id: "translate",
         })}>
         <img
           width="20"
           height="20"
           src={translate}
         />
         </Tooltip>
         {/*<div*/}
         {/*  className="flex-row flex-column  font-12 align-items-center cursor mt-2"*/}
         {/*>*/}
         {/*  {formatMessage({*/}
         {/*    id: "translate",*/}
         {/*  })}*/}
         {/*</div>*/}
       </div>
     </div>
   ) ;
   return (
     <Popover content={content2} trigger="click" visible={visible}>
       <img height="24" src={imgs2['more_vertical.svg']} onClick={() => setVisible(true)} style={{cursor: 'pointer', marginTop: -20}}/>
     </Popover>
   );
  };

  if (mimeType === 'entered_event_page') {
    return (
      <div className='entered-panel' ><img src={imgs['形状结合3.svg']} />{content} has entered the chat</div>
    );
  } else {
    return (
      <>
        <ItemContainer isCurrentUser={isCurrentUser}>
          {/*<div>*/}
          {/*  { renderOptions() }*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <img*/}
          {/*    width="30"*/}
          {/*    height="30"*/}
          {/*    style={{borderRadius: '50%'}}*/}
          {/*    src={sender && sender.avatar}*/}
          {/*  />*/}
          {/*</div>*/}
          <div>
            <div>
              {sender && sender.name}
            </div>
            <div>
              {formatContent()}
            </div>
          </div>
          <div>
            { renderOptions() }
          </div>
        </ItemContainer>
        { ts.has && <TsPanel current={isCurrentUser}><div>{ts.ms}</div></TsPanel> }
      </>
    );
  }


}

export default ChatItem;
