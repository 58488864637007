import styled, { createGlobalStyle } from "styled-components";
import {useContext} from "react";
import LangContext from "../../../app/LangContext";

function LangTag() {

    const { lang, setLang } = useContext(LangContext);


    const handleLanguage = () => {
        const target = lang === 'en' ? 'zh' : 'en';
        setLang(target)
    };

    return (
      <Container onClick={handleLanguage}>
          { lang === 'en' ? <span>中文</span> : <span>English</span> }
      </Container>
    )

}

const Container = styled.div`
  span {
    font-size: 16px;
    font-weight: 700;
    color: rgb(23, 113, 199);
    cursor: pointer;
      @media (max-width: 991px) {
        font-size: 14px;  
      }  
  }
`;

export default LangTag;
