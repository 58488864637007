import React, { useEffect, useState } from 'react';
import userService from "../../../services/user-service";
import eventService from "../../../services/student/event-service";
import { useParams, useLocation } from "react-router-dom";
import { message } from 'antd';

function usePublicChat(isWechat) {

  const [basicInfo, setBasicInfo] = useState({});
  const [event, setEvent] = useState(null);
  const [ws, setWs] = useState({});
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scroll, setScroll] = useState(true);
  const { id } = useParams();
  const rrLocation = useLocation();

  useEffect(() => {
    let promise1 = null;
    if (isWechat) {
      const openid = new URLSearchParams(rrLocation.search).get('openid');
      promise1 = userService.getUsrBasicInfoByOpenidUsingGET(openid).then(res => {
        let { data } = res;
        data = data || {}
        return data;
      });
    } else {
      promise1 = userService.getUserBasicInfo().then(res => {
        let { data } = res;
        data = data || {}
        return data;
      });
    }
    const promise2 = eventService.getEventById({ id }, 'en').then(res => res, rej => message.error(rej));
    Promise.all([promise1, promise2]).then(values => {
      setBasicInfo(values[0]);
      setEvent(values[1]);
      createWebSocket(values[0], values[1]);
    });
  }, []);


  const createWebSocket = (basicInfo, event) => {

    const onmessage = event => {
      const data = JSON.parse(event.data);
      switch (data.action) {
        case 'History':
          // const arr = data.data.reverse().map(i => {
          //   return {data: i, sender: data.sender}
          // });
          // console.log(list, data.data, 888)
          setList(list => [...data.data.reverse(), ...list]);
          // setList(data.data.reverse());
          // setList(list => {
          //   const tmp = list.map(i => i.data);
          //   if (tmp.find(i => i.id === data.data.id)) {
          //     return list;
          //   } else {
          //     return [data, ...list];
          //   }
          // });
          break;
        case 'Message':
          setList(list => {
            const tmp = [...list]
            if (tmp.find(i => i.id === data.data.id)) {
              return list;
            } else {
              return [...list, {...data.data, sender: data.sender}];
            }
          });
          setScroll(true);
          break;
        case 'Entered_Event_Page':
          if (data.data.content) {
            setList(list => [...list, data]);
          }
          break;
        case 'No_More_Message':
          setHasMore(false);
          break;
      }

    };

    const onerror = () => {

    };

    const onclose = () => {

    };

    const onopen = () => {
      const bindData = {
        action: "Bind",
        data: {
          mimeType: "json",
          content: {
            senderId: basicInfo.id,
            chatRoom: "group",
            roomId: event.id,
            avatar:
              basicInfo && basicInfo.logo
                ? basicInfo.logo
                : "https://udentity.oss-cn-hangzhou.aliyuncs.com/2755/202009261456_avatar.jpg",
            name: basicInfo.displayName,
            userType: basicInfo.type,
          },
        },
      };

      ws.send(JSON.stringify(bindData));

      const getHistory = () => {
        const historyData = {
          action: "History",
          data: {
            mimeType: "json",
            content: {
              timestamp: new Date().getTime(),
            },
          },
        }
        ws.send(JSON.stringify(historyData));
      }

      setTimeout(getHistory, 800);

    };

    let url = location.host === "frontend.udty.tech" ? `wss://ws-t.udty.tech` : `wss://ws.udty.tech`;
    if (location.host === 'localhost:3000') url = 'wss://ws-t.udty.tech';
    const ws = new WebSocket(`${url}/api/v3/ws/message`);
    ws.onmessage = onmessage;
    ws.onerror = onerror;
    ws.onclose = onclose;
    ws.onopen = onopen;
    setWs(ws);
  };

  const loadMore = () => {
    const historyData = {
      action: "History",
      data: {
        mimeType: "json",
        content: {
          timestamp: list[0].timestamp,
        },
      },
    }
    ws.send(JSON.stringify(historyData));
    setScroll(false);
  };

  const loadEvents = {
    scroll,
    hasMore,
    loadMore
  };


  return { basicInfo, event, ws, list, loadEvents };
}

export default usePublicChat;
