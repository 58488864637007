import userHelper from "../../helpers/user-helper";
import { handleResponse } from '../../helpers/api-helper';
// import { get,put } from 'helpers/request'

// 与某一特定用户聊天
function getChatUser({ id }) {
  const user = userHelper.getUser();
  const url = `/api/chat/users/${id}`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  myHeaders.append('Authorization', 'Basic ' + user.auth_token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    }).catch(function(err) {
      throw err;
    });
}

// 返给接口与某一特定用户的第三方
function putChatUser(id, params) {
  const user = userHelper.getUser();
  const url = `/api/chat/users/${id}`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  myHeaders.append('Authorization', 'Basic ' + user.auth_token);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    }).catch(function(err) {
      throw err;
    });
}

// 列出可以聊天的用户列表
function getChatUsers(auth) {
  const user = userHelper.getUser();
  // const url = `/api/chat/users`;
  const url = `/api/v3/message/users`;

  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  user && myHeaders.append('Authorization', 'Basic ' + user.auth_token);
  if (auth) {
    myHeaders.append('Authorization', 'Basic ' + auth);
  }

  const requestOptions = {
    method: 'GET',
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    }).catch(function(err) {
      throw err;
    });
}


/**
 * {
 *  "SDKAppID": "sdkappid",
 *  "userSig": "eJwtzM0KgkAYheF7mXXYN7*Z0MLCNIuIUYjaBTPZhySDSUrRvSfq8jwH3i-JD5n3tjUJCPOAzIaNxlYN3nFgvlQTv0x5cw4NCagA4ECpXIxPg0-bq-SZEsAZH9V2DuveFQgfYGpg0Te1TE6VDndhVKq53KSXOIn2R1sVrXmsr2cWp7Tj2fZT6nZFfn-Zqy7Q"
 * }
 */

// 列出可以聊天的用户列表
function getSignature() {
  const user = userHelper.getUser();
  const url = `/api/chat/get_signature`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  myHeaders.append('Authorization', 'Basic ' + user.auth_token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    }).catch(function(err) {
      throw err;
    });
}

// 实际对接TIM
// 接口1：
// /api/chat/to_inst/:id
function createGroupCustomInfo(inst_id) {
  const user = userHelper.getUser();
  const url = `/api/chat/to_inst/${inst_id}`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  myHeaders.append('Authorization', 'Basic ' + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (!response.groupID) response.groupID = response.groupId;
      response.groupCustomField = response.groupCustomField.map(f => {
        f.value = JSON.stringify(f.value);
        return f;
      });
      return response;
    }).catch(function(err) {
      throw err;
    });
}

function getGroupCustomInfos() {
  const user = userHelper.getUser();
  const url = `/api/chat/list`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');
  myHeaders.append('Authorization', 'Basic ' + user.auth_token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      response = response.map(g => {
        g.groupCustomField = g.groupCustomField.map(f => {
          f.value = JSON.stringify(f.value);
          return f;
        });
        return g;
      })
      return response;
    }).catch(function(err) {
      throw err;
    });
}


// const getChatUser = (uid)=>get(`/api/chat/users/${uid}`);
// const putChatUser = (uid)=>put(`/api/chat/users/${uid}`);
// const getChatUsers = get('/api/chat/users')
// {
//   "groupId": "@TGS#1NICGKFGX",
//   "groupCustomField": [{ "key": "user_types", "value": ["university", "highschool"] }, { "key": "university", "value": { "avatar": "//udentity.oss-cn-hangzhou.aliyuncs.com/373/university%20(2).png", "name": "Test University" } }, { "key": "highschool", "value": { "avatar": null, "name": "深圳万科梅沙书院" } }]
// }
// function createGroupCustomInfo(instId) {
//   // mock 由373调用本接口，to_user_ids=[373,396]的情况，返回如下数据，其中对应数据需替换成真实数据，结构保留。
//   const users = [
//     { user_id: 373, name: '我是373', user_type: "university", avatar: 'http://image.biaobaiju.com/uploads/20180803/23/1533308847-sJINRfclxg.jpeg' },
//     { user_id: 396, name: '我是396', user_type: "counselor", avatar: 'http://5b0988e595225.cdn.sohucs.com/images/20170724/ad28da0d658b43aba84ce91df9cacdad.jpeg' },
//     // { user_id: 396, name: '我是396', user_type: "student", avatar: 'http://5b0988e595225.cdn.sohucs.com/images/20170724/ad28da0d658b43aba84ce91df9cacdad.jpeg' },
//   ];
//   // 用于显示对方的头像和名称在对话列表中。
//   const customInfo = {
//     'user_types': ['university', 'counselor'], // 保存对话双方的 user_type，用户信息更新时需要同步更新里边的信息。
//     'university': { // 对话中，高中，需要看到--大学这个机构的信息
//       avatar: 'http://image.biaobaiju.com/uploads/20180803/23/1533308847-sJINRfclxg.jpeg',
//       name: '某大学',
//     },
//     'counselor': { // 对话中，大学，需要看到--高中这个机构的信息
//       avatar: 'http://5b0988e595225.cdn.sohucs.com/images/20170724/ad28da0d658b43aba84ce91df9cacdad.jpeg',
//       name: '某高中',
//     },
//     // 同理，'user_types': ['university', 'student'] 的情况下需要用到。
//     // 'student': { // 对话中，大学，需要看到--这个学生的信息
//     //   avatar: 'http://pic2.zhimg.com/50/v2-6a803ac7428a2276f7201c83a0ed9db3_hd.jpg',
//     //   name: '某学生',
//     // },
//   }
//   const groupID = "TGS#1PVIX5EGH";
//   // const groupID = '';
//   const res = { users, customInfo, groupID };
//   return Promise.resolve(res);
// }
// https://cloud.tencent.com/document/product/269/1625
// 后端协助批量拉取群组信息。 数据应该都可以从第三方接口获取到
// function getGroupCustomInfos() {
//   const res = [{
//       conversationID: "GROUP@TGS#1PVIX5EGH",
//       groupID: "@TGS#1PVIX5EGH",
//       groupCustomField: [{ "key": "user_types", "value": "['university','counselor']" }, { "key": "university", "value": "{'avatar':'http://image.biaobaiju.com/uploads/20180803/23/1533308847-sJINRfclxg.jpeg','name':'某大学'}" }, { "key": "counselor", "value": "{'avatar':'http://5b0988e595225.cdn.sohucs.com/images/20170724/ad28da0d658b43aba84ce91df9cacdad.jpeg','name':'某高中'}" }]
//     },
//     {
//       conversationID: "GROUP@TGS#1RZAX5EGF",
//       groupID: "@TGS#1RZAX5EGF",
//       groupCustomField: [{ "key": "user_types", "value": "['university','counselor']" }, { "key": "university", "value": "{'avatar':'http://image.biaobaiju.com/uploads/20180803/23/1533308847-sJINRfclxg.jpeg','name':'某大学'}" }, { "key": "counselor", "value": "{'avatar':'http://5b0988e595225.cdn.sohucs.com/images/20170724/ad28da0d658b43aba84ce91df9cacdad.jpeg','name':'某高中'}" }]
//     },
//   ];
//   return Promise.resolve(res);
// }

const translation = text => {
  if (text.length > 5000) return;
  // const reg = /[A-Za-z]+/;
  const reg =  /[\u2E80-\u9FFF]+/;
  let data = {
    "formatType": "text",
    "sourceText": text,
  };
  if (!reg.test(text)) {
    data['sourceLanguage'] = 'en';
    data['targetLanguage'] = 'zh';
  } else {
    data['sourceLanguage'] = 'zh';
    data['targetLanguage'] = 'en';
  }
  const user = userHelper.getUser();
  const url = `/api/v3/common/translation`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  // myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
};

function getToken(openid) {
  const url = `/api/v3/openidToAuthToken?openid=${openid}`;
  const myHeaders = new Headers();
  myHeaders.append('content-type', 'application/json');

  const requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    }).catch(function(err) {
      throw err;
    });
}



export default {
  getChatUser,
  putChatUser,
  getChatUsers,
  getToken,

  // tim
  createGroupCustomInfo,
  getGroupCustomInfos,
  getSignature,
  translation

};
