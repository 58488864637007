import React from 'react';
import { Modal } from 'antd';
import { Link } from "react-router-dom";
import styled, {createGlobalStyle} from "styled-components";
import { useIntl } from 'react-intl';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {signupHigh, signupParents, signupStudent, signupUni} from "../../../helpers/images-helper";
import importAllImg from "../../../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../../../../src/assets/img/signup', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  //border-radius: 20px;
  width: 425px;
  height: 428px;
  padding: 20px 50px;
  @media (max-width: 991px) {
    //padding: 15px 25px;
    width: 350px;
    height: 465px;
  }
  // background-image: url("${imgs['illustration.png']}");
  // background-size: contain;
  // background-position: bottom;
  // background-repeat: no-repeat;
  .signup-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #757575;
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 27px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  
  .item-dot {
    margin-right: 24px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #666666;
    display: inline-block;
  }
  
  .jump-item {
    display: flex;
    align-items: center;
    background: rgba(23, 113, 199, 0.85);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    & > img {
      margin-right: 15px;
      width: 22px;
    }
    @media (max-width: 991px) {
      padding: 10px 15px;
      //width: 280px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px;
    }

 
  }
  
  .item-right {
    flex: 1;
    padding-bottom: 15px;
    border-bottom: 3px solid #1771C7;
    & > img {
      margin-right: 20px;
    }
  }
  
  .aready-btn {
    margin-top: 30px;
    //height: 45px;
    //background: #1771C7;
    //border: 1px solid #1771C7;
    //border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #757575;
    cursor: pointer;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: 30px;
    }
    & > span {
      color: #1771C7;
      text-decoration: underline;
    }
  }
`;

const CustomModal = createGlobalStyle`
  .ant-modal-content {
    //border-radius: 10px;
  }
`;

function SignUpModal({ show, setShow, toggleType }) {

  const { formatMessage } = useIntl();
  const history = useHistory()


  const handleJump = url => {
    history.push(url);
  };

  return (
    <>
      <CustomModal />
      <Modal
        // closeIcon={<span />}
        title={null}
        footer={null}
        centered={true}
        visible={show}
        onCancel={() => setShow(false)}
        width='auto'
        bodyStyle={{padding: 0}}
      >
        <Container>
          <div className='signup-title'>{formatMessage({ id: "SignUpAs" })}</div>

          <div className='jump-item' onClick={() => handleJump(`/student/signup?redirect=${encodeURIComponent(window.location.pathname)}`)}>
            <img src={imgs['形状结合.svg']} />
            <span>{formatMessage({ id: "student" })}</span>
          </div>

          <div className='jump-item' onClick={() => handleJump(`/parent/signup?redirect=${encodeURIComponent(window.location.pathname)}`)}>
            <img src={imgs['peoples2.svg']} />
            <span>{formatMessage({ id: "parent" })}</span>
          </div>

          <div className='jump-item' onClick={() => handleJump(`/university/signup?redirect=${encodeURIComponent(window.location.pathname)}`)}>
            <img src={imgs['palace2.svg']} />
            <span>{formatMessage({ id: "universitySignUpBox" })}</span>
          </div>

          <div className='jump-item' onClick={() => handleJump(`/counselor/signup?redirect=${encodeURIComponent(window.location.pathname)}`)}>
              <img src={imgs['HighSchoolIcon2.svg']} />
              <span>{formatMessage({ id: "highschoolSignUpBox" })}</span>
          </div>

          <div className='aready-btn' onClick={() => toggleType('login')}>
            {formatMessage({ id: "alreadyamember" })}&nbsp;
            <span>{formatMessage({ id: "login" })}</span>
          </div>
        </Container>
      </Modal>
    </>
  );

}

export default SignUpModal;
