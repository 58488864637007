import userHelper from "../../helpers/user-helper";
import { handleResponse } from "../../helpers/api-helper";
import { get } from "helpers/request";

const getFairVisitCounter = (fairName) =>
  get(`/api/v3/fair/${fairName}/visitCounter`);

function unsubscribeFairUniversity({ university_id, fairName }) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/unsubscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ university_id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function subscribeFairUniversity({ university_id, fairName }) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/subscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ university_id }),
  };
  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function updateFairSchedule(id, params, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/updateSchedule/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function cancelFairSchedule(university_id, schedule_id, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/appointment/cancel/${university_id}/${schedule_id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function sendFairSchedulerData(params, fairName, id) {
  const user = userHelper.getUser();

  const url = `/api/v3/fair/${fairName}/appointment/submit/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getFairCsvExport(id, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/download/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        const error = data && data.error ? data.error : "Error :Bad Request";
        return Promise.reject(error);
      }
      return response.blob();
    })
    .then((myBlob) => {
      var objectURL = URL.createObjectURL(myBlob);
      const data = objectURL;
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

function getFairSchedulerData(universityId, fairName) {
  const url = `/api/v3/fair/${fairName}/appointment/${universityId}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getFairAppointments(id, fairName) {
  const user = userHelper.getUser();

  const url = `/api/v3/fair/${fairName}/appointments/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getFairUniList(fairName) {
  const url = `/api/v3/fair/${fairName}/universities`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
    user.auth_token &&
    myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getFairVideoList(fairName) {
  const url = `/api/v3/fair/${fairName}/videos`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function getFairVisitCounter2(fairName) {
  const url = `/api/v3/fair/${fairName}/visitCounter`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}
function getFairUserPreferences() {
  const user = userHelper.getUser();

  const url = `/api/v3/user/universityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function acceptFairTerms(terms, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/acceptConditions`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user?.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(terms),
  };
  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function getAcceptFairTerms(fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/acceptConditions`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function setUniversityPrefereces(data) {
  const user = userHelper.getUser();
  const url = `/api/v3/user/setUniversityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function fairPageEvent(data, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v2/pageEvent/log`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  let tmp = { }
  if (user) {
    tmp = {
      profile: user.user_id,
      userType: user.user_type,
    }
  }
  if (data) {
    tmp = { ...tmp, ...data }
  }

  const params = { eventCode: fairName, data: tmp }
  const anonymousId = userHelper.getUuid();
  if (anonymousId) {params.anonymousId = anonymousId}

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  // return console.log(params, 111)
  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function fairPageEvent2(data, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v2/pageEvent/log`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  let tmp = { }
  if (user) {
    tmp = {
      profile: user.user_id,
      userType: user.user_type,
    }
  }
  if (data) {
    tmp = { ...tmp, ...data }
  }

  const params = { eventCode: fairName, data: tmp }
  const anonymousId = userHelper.getUuid();
  if (anonymousId) {params.anonymousId = anonymousId}

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  // return console.log(params, 111)
  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}


function getFairEventInfo(eventId, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/event/${eventId}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function list() {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/list`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function seminarSignup(fairName, index) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/seminarSignUp/${index}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({}),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function getNySignedUp() {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/ny/signedUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function nYSignup() {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/ny/signUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({}),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function getFairSignedUp(fairName) {
  const user = userHelper.getUser();
  // const url = `/api/v3/fair/${fairName}/signedUp`;
  const url = `/api/v3/fair/getSignedUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function fairSignup(fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/signUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({}),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function consultationSessions() {
  const url = `/api/v3/fair/hsylc/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function workshops() {
  const url = `/api/v3/fair/hsylc/workshops`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getWorkshops(fairName) {
  const url = `/api/v3/fair/${fairName}/workshops`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getOfficeHours(fairName) {
  const url = `/api/v3/fair/${fairName}/officeHours`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getInfoSessions(fairName) {
  const url = `/api/v3/fair/${fairName}/infoSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function infoSessions() {
  const url = `/api/v3/fair/hsylc/infoSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}



function idSet() {
  const url = `/api/v3/fair/hsylc/idSet`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function idSet2() {
  const url = `/api/v3/fair/hsylc2022/idSet`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function idSetting2(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc2022/idSetting`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function idSet3() {
  const url = `/api/v3/fair/hsylc2023/idSet`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function idSetting3(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc2023/idSetting`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function idSet4() {
  const url = `/api/v3/fair/hsylc2024/idSet`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function idSetting4(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc2024/idSetting`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function idSetting(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc/idSetting`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function workshopSignUp(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc/workshopSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({}),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function infoSessionSignUp(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc/infoSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({}),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function infoSessionSignUp2(name, id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${name}/infoSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    // body: JSON.stringify({}),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function workshopSignUp2(name, id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${name}/workshopSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    // body: JSON.stringify({}),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function officeHoursSignUp(name, id, params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${name}/officeHoursSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function consultationSessionSignUp(id, params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc/consultationSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function fairList(fairName) {
  const url = `/api/v3/dict/${fairName}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022Hs() {
  const url = `/api/v3/fair/act2022/getHighSchoolPanelDiscussions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022Uni() {
  const url = `/api/v3/fair/act2022/getUniversityPanelDiscussions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022HsSign(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/act2022/highSchoolPanelDiscussionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022isIdSet() {
  const url = `/api/v3/fair/act2022/idSet`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022idSet(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/act2022/idSetting`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022UniSign(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/act2022/universityPanelDiscussionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function act2022HsList() {
  const url = `/api/v3/fair/act2022/highSchools`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function actHsList(fairName) {
  const url = `/api/v3/fair/${fairName}/highSchools`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function fairLiveSessions(fairName) {
  const url = `/api/v3/fair/${fairName}/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function sessionSignUp(id, fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function cieConsultationSessions() {
  const url = `/api/v3/fair/ciee/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function cieLiveSessions() {
  const url = `/api/v3/fair/ciee/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function cieLiveSession(id) {
  const url = `/api/v3/fair/ciee/consultationSession/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function cieLiveSessionSignUp(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/ciee/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function cieConsultationSessionSignUp(id, params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/ciee/consultationSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getOpenHours(fairName) {
  const url = `/api/v3/fair/getOpenHours/${fairName}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getOpenHoursSignedUp(fairName) {
  const url = `/api/v3/fair/${fairName}/getOpenHoursSignedUp`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2022Pay() {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc2022/pay`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2022Paid() {
  const url = `/api/v3/fair/hsylc2022/hasPay`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2022DataPack() {
  const url = `/api/v3/fair/hsylc2022/dataPack`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2022Download() {
  const url = `/api/v3/fair/hsylc2022/download`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    // .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2023Pay() {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/hsylc2023/pay`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2023Paid() {
  const url = `/api/v3/fair/hsylc2023/hasPay`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2023DataPack() {
  const url = `/api/v3/fair/hsylc2023/dataPack`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function hsylc2023Download() {
  const url = `/api/v3/fair/hsylc2023/download`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    // .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getZoomSig(zoomId) {
  const url = `/api/v3/common/zoomGenerateSignature/${zoomId}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getSeminars(fairName) {
  const url = `/api/v3/fair/getSeminars/${fairName}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getMdPv(id) {
  const url = `/api/v3/event/mudu/pv/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function seminarSignUp(fairName, id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/seminarSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function getSeminarSignedUp(fairName) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/getSeminarSignedUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function conSignUp(fairName, id, params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/appointment/submit/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}



function openHourSignUp(fairName, id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/openHoursSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function openHourSignUp2(fairName, id, params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/officeHoursSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessions2() {
  const url = `/api/v3/fair/nus2022/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessions3() {
  const url = `/api/v3/fair/nus20231118/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionsByFairName(fairName) {
  const url = `/api/v3/fair/${fairName}/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusConsultationSessions2() {
  const url = `/api/v3/fair/nus2022/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusConsultationSessions3() {
  const url = `/api/v3/fair/nus20231118/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusConsultationSessionsByFairName(fairName) {
  const url = `/api/v3/fair/${fairName}/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionSignUp2(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/nus2022/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessions() {
  const url = `/api/v3/fair/nus/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function nusConsultationSessions() {
  const url = `/api/v3/fair/nus/consultationSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionSignUp(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/nus/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionSignUp3(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/nus2023/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionSignUp4(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/nus20231118/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessionSignUpByFairName(fairName, id) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/liveSessionSignUp/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function nusLiveSessions2023() {
  const url = `/api/v3/fair/nus2023/liveSessions`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function eduJulySignUp(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/edusa2023/quesSubmit`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function eduJuneSignUp2024(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/edusa20240649/quesSubmit`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


export default {
  subscribeFairUniversity,
  unsubscribeFairUniversity,
  sendFairSchedulerData,
  getFairCsvExport,
  getFairSchedulerData,
  getFairAppointments,
  getFairUniList,
  getFairVisitCounter,
  getFairUserPreferences,
  acceptFairTerms,
  setUniversityPrefereces,
  updateFairSchedule,
  getAcceptFairTerms,
  cancelFairSchedule,
  fairPageEvent,
  fairPageEvent2,
  getFairEventInfo,
  list,
  getFairVisitCounter2,
  getSeminarSignedUp,
  seminarSignup,
  getNySignedUp,
  nYSignup,
  getFairSignedUp,
  fairSignup,
  consultationSessions,
  workshops,
  infoSessions,
  idSet,
  idSetting,
  workshopSignUp,
  infoSessionSignUp,
  consultationSessionSignUp,
  fairList,
  fairLiveSessions,
  sessionSignUp,
  cieConsultationSessions,
  cieLiveSessions,
  cieLiveSessionSignUp,
  cieConsultationSessionSignUp,
  cieLiveSession,
  getOpenHours,
  openHourSignUp,
  openHourSignUp2,
  getOpenHoursSignedUp,
  getSeminars,
  seminarSignUp,
  conSignUp,
  nusLiveSessions,
  nusConsultationSessions,
  nusLiveSessionSignUp,
  nusLiveSessions2,
  nusConsultationSessions2,
  nusLiveSessionSignUp2,
  getInfoSessions,
  getOfficeHours,
  getWorkshops,
  infoSessionSignUp2,
  workshopSignUp2,
  officeHoursSignUp,
  idSet2,
  idSetting2,
  idSet3,
  idSetting3,
  idSet4,
  idSetting4,
  hsylc2022Pay,
  hsylc2022Download,
  hsylc2022Paid,
  hsylc2022DataPack,
  hsylc2023Pay,
  hsylc2023Download,
  hsylc2023Paid,
  hsylc2023DataPack,
  act2022Hs,
  act2022Uni,
  act2022isIdSet,
  act2022idSet,
  act2022HsSign,
  act2022UniSign,
  act2022HsList,
  getZoomSig,
  getMdPv,
  nusLiveSessions2023,
  nusLiveSessionSignUp3,
  eduJulySignUp,
  actHsList,
  nusLiveSessions3,
  nusLiveSessionSignUp4,
  nusConsultationSessions3,
  eduJuneSignUp2024,
  getFairVideoList,
  nusLiveSessionsByFairName,
  nusConsultationSessionsByFairName,
  nusLiveSessionSignUpByFairName
};
