import React, { useState } from 'react';
import { Modal } from 'antd';
import OldLoginInModal from "../../shared/app/signupmodal/LoginInModal";
import '../../shared/app/css/signupmodal.css'
import {useHistory} from "react-router-dom";
import {udentityLogo} from "../../../helpers/images-helper";
import { useIntl } from 'react-intl';
import importAllImg from "../../../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../../../../src/assets/img/signup', false, /\.(png|jpe?g|svg)$/));

function LoginModal({ show, setShow, toggleType, noClose, setUser, loginTab }) {

  const fn = () => {};
  const option = {};
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const fm = id => formatMessage({id});

  const [mobileTab, setMobileTab] = useState(loginTab);
  if (noClose) { option.closeIcon = <span /> }

  const handleTab = index => {
    const redirect = encodeURIComponent(window.location.pathname)
    switch (index) {
      case 0:
        return history.push(`/student/signup?redirect=${redirect}`);
      case 1:
        return history.push(`/parent/signup?redirect=${redirect}`);
      case 2:
        return history.push(`/university/signup?redirect=${redirect}`);
      case 3:
        return history.push(`/counselor/signup?redirect=${redirect}`);
    }
  };

  const isMobile = window.innerWidth <= 991;
  if (isMobile) {
    return (
      <Modal
        {...option}
        title={null}
        footer={null}
        centered={true}
        visible={show}
        onCancel={() => setShow(false)}
        width='auto'
        bodyStyle={{padding: 0}}
      >
        <div className='modal-panel'>
          <div className="box" style={{display: mobileTab === 0 ? '': 'none'}}>
            <OldLoginInModal
              updateState={() => toggleType('signup')}
              displayBackButton={fn}
              backToggle={fn}
              setLoadingState={fn}
              setLoadingText={fn}
              setUser={setUser}
              setMobileTab={setMobileTab}
            />
          </div>
          <div className='right-panel' style={{display: mobileTab === 1 ? '': 'none'}}>
            <div className='right-panel-title'>{fm('Create Account')}</div>
            <div className='right-panel-text right-panel-text1'>
              {
                locale === 'en' ? <>
                  New to Udentity? Create a <span>free</span> account to <br/>
                  access the platform’s features and events.
                </> : <>
                  还没有账号? 注册<span>免费</span>账号 <br/>
                  访问平台的功能和活动。
                </>
              }

            </div>
            <div className="login-title login-input mt-3 mobile-icon-img">
              <img
                className='fit-mobile-img'
                src={udentityLogo}
              ></img>
            </div>
            <div className='right-panel-text'>{fm('Sign up as:')}</div>
            <div>
              <div className='change-icons'>
                <div onClick={() => handleTab(0)}>
                  {fm('Student')}
                </div>
                <div onClick={() => handleTab(1)}>
                  {fm('Parent')}
                </div>
                <div onClick={() => handleTab(3)}>
                  {fm('High School2')}
                </div>
                <div onClick={() => handleTab(2)}>
                  {fm('University2')}
                </div>
              </div>
            </div>

            <div
              className="mobile-new"
            >
              <div className="login-with-phone">{fm('Already on Udentity?')}</div>
              <div className="login-with-phone" onClick={() => setMobileTab(0)}>{fm('Go to Login')}</div>
            </div>
          </div>

        </div>

      </Modal>
    )
  }

  return (
    <Modal
      {...option}
      title={null}
      footer={null}
      centered={true}
      visible={show}
      onCancel={() => setShow(false)}
      width='auto'
      bodyStyle={{padding: 0}}
    >
      {/*<div className='logo-title'>*/}
      {/*  <img src={imgs['Udentity 4.svg']} />*/}
      {/*</div>*/}
      <div className='modal-panel'>
        <div className="box">
          <OldLoginInModal
            updateState={() => toggleType('signup')}
            displayBackButton={fn}
            backToggle={fn}
            setLoadingState={fn}
            setLoadingText={fn}
            setUser={setUser}
          />
        </div>
        <div className='middle-line'></div>
        <div className='right-panel'>
          <div className='right-panel-title'>{fm('Create Account')}</div>
          <div className='right-panel-text'>
            {
              locale === 'en' ? <>
                New to Udentity? Create a <span>free</span> account to <br/>
                access the platform’s features and events.
              </> : <>
                还没有账号? 注册<span>免费</span>账号 <br/>
                访问平台的功能和活动。
              </>
            }
          </div>
          <div className='right-panel-text'>{fm('Sign up as:')}</div>
          <div>
            <div className='change-icons'>
              <div onClick={() => handleTab(0)}>
                {fm('Student')}
              </div>
              <div onClick={() => handleTab(1)}>
                {fm('Parent')}
              </div>
              <div onClick={() => handleTab(3)}>
                {fm('High School2')}
              </div>
              <div onClick={() => handleTab(2)}>
                {fm('University2')}
              </div>
            </div>
          </div>
        </div>

      </div>

    </Modal>
  );
}

export default LoginModal;
