import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import importAllImg from "../../../helpers/import-all-imgs";
import { Empty, Pagination, DatePicker, Popover, Input, message } from 'antd';
import universityService from "../../../services/student/university-service";
import moment from 'moment';
import api from "../consultation/api";

const imgs = importAllImg(require.context('../../../../src/assets/img/events2', false, /\.(png|jpe?g|svg)$/));

const { RangePicker } = DatePicker;

const Container = styled.div`
  
  .analytics-table {
    /* other styles */
    margin-top: 20px;
    display: block;
    overflow-x: auto;

    table {
      /* other styles */
      width: max-content;
      min-width: 100%;
      /* other styles */
    }

    th, td {
      min-width: 100px;
      /* other styles */
    }

      th {
        background: #F2F2F7;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.15px;
        color: #757575;
        text-align: left;
        padding: 15px;
        font-weight: 400;
        min-width: 100px;
        @media (max-width: 991px) {
          font-size: 12px;
          padding: 5px;
          line-height: 1.5;
        }
      }

      td {
        text-align: left;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.15px;
        color: #1C1C1E;
        padding: 15px;
        min-width: 100px;
        //&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
        //  transform: translateX(10px);
        //}
      }

   

    &-link {
      font-weight: bold;
      color: #1771C7;
    }

    /* other styles */
  }

  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  .top-panel {
    //display: flex;
    //justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .event-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .event-date {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #1771C7;
  }
  
  .export-btn {
    cursor: pointer;
    width: 257.13px;
    height: 58px;
    background: #F6821F;
    border-radius: 4px;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }


  .long-td {
    width: 280px;
  }

  .short-td {
    width: 120px;
  }
  .pagination-panel {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  
  .top-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .top-text {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #000000;
  }
  
  .search-panel {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
  }

  .select-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    opacity: 0.5;
    cursor: pointer;
    width: 254px;
    height: 40px;
    left: 453.86px;
    top: 260.07px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.15px;

    border-radius: 4px;
    /* Interface Color/light grey */

    background: #F2F2F7;
    margin: 0 25px;
    & > img {
      margin-left: 5px;
    }
  }

  .cus-input > .ant-input {
    background: #F2F2F7 !important;
    border-radius: 14px;
    color: #757575;
    border: none;
    width: 356.12px;
    height: 40px;
    padding-left: 50px;
    margin-right: 40px;
    @media (max-width: 991px) {
      width: 100%;
      margin: 0 auto;
    }
  }
  
`;

const TypeContainer = styled.div`
  width: 230px;
  & > div {
    font-size: 16px;
    color: #1C1C1E;
    margin-bottom: 5px;
    cursor: pointer;
    text-align: center;
  }
  & > .selected {
    color: white;
    background-color: #0b7dda;
  }
  
  .ant-calendar-picker-input {
    font-size: 16px;
  }
  
  
`;

function Viewers() {

  const [list, setList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(10);
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const [dates, setDates] = useState([]);

  const getList = () => {
    const params = {
      pageNum: 1,
    }
    if (type) {
      params.userType = type;
    }
    if (value) {
      params.username = value;
    }
    if (dates.length) {
      params.dateStart = dates[0];
      params.dateEnd = dates[1];
    }
    universityService.getViewersByParams(params).then(res => {
      if (res.code !== 200) {
        return message.error(res.msg);
      }
      setList(res.rows);
      setTotal(res.total);
    }, rej => message.error(JSON.stringify(rej)))
  };

  useEffect(getList, [current, type, value, dates])

  const toggleType = i => setType(i);

  const onChange = (date, dateString) => {
    setDates(dateString);
  };

  const exportList = () => {
    const params = {
      pageNum: 1,
    }
    if (type) {
      params.userType = type;
    }
    if (value) {
      params.username = value;
    }
    if (dates.length) {
      params.dateStart = dates[0];
      params.dateEnd = dates[1];
    }
    let fileName = '';
    universityService.exportList(params).then(resp => {
      fileName = resp.headers.get('Content-Disposition').split('=')[1];
      return resp.blob();
    }).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });

  };

  const tagContent = (
    <TypeContainer>
      {/*<Checkbox.Group onChange={types => setTypes(types)}>*/}
      {/*  {['Student', 'Parent', 'Counselor'].map(i => (*/}
      {/*    <Tag key={i}>*/}
      {/*      <Checkbox value={i}>{i}</Checkbox>*/}
      {/*    </Tag>*/}
      {/*  ))}*/}
      {/*</Checkbox.Group>*/}
      {['student', 'parent', 'counselor'].map(i => (
        <div className={i === type ? 'selected': ''} onClick={() => toggleType(i)}>{i}</div>
      ))}
    </TypeContainer>
  );



  return (
    <Container>
      <div className='top-panel'>
        <div className='top-title'>Leads </div>
        <div className='top-text'>Users that have consented sharing their information with your institution.</div>
      </div>

      <div className='search-panel'>
        <div>
          <RangePicker onChange={onChange} />
        </div>
        <Popover content={tagContent} placement='bottom' trigger='click' overlayClassName='cus-popover'>
          <div className='select-item'>
            {type ? type : 'User Type'}
            <img src={imgs['down.svg']} />
          </div>
        </Popover>
        <div className='input-panel'>
          <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            size='large'
            className="cus-input"
            prefix={<img style={{marginLeft: 10}} src={imgs['Magnifier2.svg']}/>}
            placeholder=' Search User Name '
          />
        </div>
        <div className='export-btn' onClick={exportList}>EXPORT LIST</div>
      </div>



      <div className='list-panel'>
        <div className="analytics-table">
          <table>
            <thead>
            <tr>
              <th>Time Added </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>User Type</th>
              <th className="long-td">School Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Grade</th>
              <th>Province</th>
              <th>City</th>
              <th>Address</th>
              <th>Birthday</th>
              <th>Child Birthday</th>
              <th>Child First Name</th>
              <th>Child Last Name</th>
            </tr>
            </thead>
            <tbody>
            {
              list.map(i => (
                <tr key={i.id}>
                  <td>{i.addTime && moment(i.addTime).format('YYYY-MM-DD HH:MM')}</td>
                  <td>{i.firstName}</td>
                  <td>{i.lastName}</td>
                  <td>{i.userType}</td>
                  <td className="long-td">{i.schoolName}</td>
                  <td>{i.phoneNumber}</td>
                  <td>{i.email}</td>
                  <td>{i.grade}</td>
                  <td>{i.province}</td>
                  <td>{i.city}</td>
                  <td>{i.address}</td>
                  <td>{i.birthday}</td>
                  <td>{i.childBirthday}</td>
                  <td>{i.childFirstName}</td>
                  <td>{i.childLastName}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>

      </div>
      {list.length === 0 && <Empty />}
      <div className='pagination-panel'>
        <Pagination current={current} pageSize={10} total={total} onChange={page => setCurrent(page)}/>
      </div>

    </Container>
  )
}

export default Viewers;
