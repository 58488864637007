import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useIntl } from 'react-intl';
import {Popover} from "antd";
import importAllImg from "../../../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../../../../src/assets/img/2023-home', false, /\.(png|jpe?g|svg)$/));

function NewFooter() {

  const { formatMessage, locale } = useIntl();
  const fm = id => formatMessage({id});
  return (
    <Container>
      <div className='desktop-show'>
        <div className='top'>
          <div className='center-panel'>
            <img className='top-img' alt='Udentity logo' src={imgs['Udentity2 2.svg']}/>
          </div>

          <div className='list'>
            <div className='list-item'>
              <div className='list-title'>
                <a>{fm('COMPANY')}</a>
              </div>
              <div className='list-detail'>
                <a href='/about-udentity' target='_blank'>{fm('About Udentity')}</a>
              </div>
              <div className='list-detail'>
                <a href='/about-udentity' target='_blank'>{fm('Our Story')}</a>
              </div>
              <div className='list-detail'>
                <a href="mailto:team@udty.tech">{fm('Contact Us')}</a>
              </div>
              <div className='list-detail'>
                <a href='/support' target='_blank'>{fm('Support Center')}</a>
              </div>
            </div>

            <div className='list-item'>
              <div className='list-title'>
                <span>{fm('INSIGHTS')}</span>
              </div>
              <div className='list-detail'>
                <a href='/insights' target='_blank'>{fm('Blog')}</a>
              </div>
              <div className='list-detail'>
                <a href='/insights' target='_blank'>{fm('Reports and Guides')}</a>
              </div>
              <div className='list-detail'>
                <a href='/insights' target='_blank'>{fm('Video Tutorials')}</a>
              </div>
              <div className='list-detail'>
                <a href='/insights' target='_blank'>{fm('Our Webinars')}</a>
              </div>
            </div>

            <div className='list-item'>
              <div className='list-title'>
                <span>{fm('SOLUTIONS')}</span>
              </div>
              <div className='list-detail'>
                <a target="_blank" href="https://arrangr.com/udentity">{fm('Schedule a Demo')}</a>
              </div>
              <div className='list-detail'>
                <a target="_blank" href='/university-admissions-information-sessions'>{fm('College Fairs and Events')}</a>
              </div>
              <div className='list-detail'>
                <a target="_blank" href='/WeChat-Official-Account-Management'>{fm('Digital Recruitment Solutions')}</a>
              </div>
              <div className='list-detail'>
                <a target="_blank" href='/chinese-student-recruitment/events-fairs'>{fm('Fairs on Udentity')}</a>
              </div>
            </div>

            <div className='list-item'>
              <div className='list-title'>
                <span>{fm('FOLLOW US')}</span>
              </div>
              <div className='list-item-imgs'>
                <Popover content={<img width={100} src={imgs['image 2433.png']} />}>
                  <img src={imgs['Group 2953 (1).svg']} />
                </Popover>
                <img onClick={() => window.open('https://www.facebook.com/udty.tech/')} src={imgs['Vector (9).svg']} />
                <img onClick={() => window.open('https://www.linkedin.com/company/udentity/')} src={imgs['Vector (10).svg']} />
                <img onClick={() => window.open('https://www.instagram.com/udentity_teams/')} src={imgs['Group (10).svg']} />
                <img onClick={() => window.open('https://medium.com/@udentity')} src={imgs['Vector (11).svg']} />
                <img onClick={() => window.open('https://www.youtube.com/@udentity5941')} src={imgs['Group (11).svg']} />
              </div>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <a target='_blank' href='/privacy'>{fm('Privacy')}</a>
          <a target='_blank' href='/faqs'>{fm('FAQs')}</a>
          <a target='_blank' href='/terms'>{fm('Terms & Conditions')}</a>
          <a href='https://beian.miit.gov.cn' target='_blank' className='special'>沪ICP备18003806号-1</a>
          <span>Copyright © 2024, Udentity Pte Ltd. All Rights Reserved.</span>
        </div>
      </div>

      <div className='mobile-show'>
         <div className='mobile-content'>
           <img className='top-img' alt='Udentity logo' src={imgs['Udentity2 2.svg']}/>
           <div className='mobile-middle-part'>
             <div className='list-title'>
               <span>{fm('FOLLOW US')}</span>
             </div>
             <div className='list-item-imgs'>
               <Popover content={<img width={100} src={imgs['image 2433.png']} />}>
                 <img src={imgs['Group 2953 (1).svg']} />
               </Popover>
               <img onClick={() => window.open('https://www.facebook.com/udty.tech/')} src={imgs['Vector (9).svg']} />
               <img onClick={() => window.open('https://www.linkedin.com/company/udentity/')} src={imgs['Vector (10).svg']} />
               <img onClick={() => window.open('https://www.instagram.com/udentity_teams/')} src={imgs['Group (10).svg']} />
               <img onClick={() => window.open('https://medium.com/@udentity')} src={imgs['Vector (11).svg']} />
               <img onClick={() => window.open('https://www.youtube.com/@udentity5941')} src={imgs['Group (11).svg']} />
             </div>
           </div>
           <a href='/about-udentity' target='_blank'>{fm('About Udentity')}</a>
           <a href='/WeChat-Official-Account-Management' target='_blank'>{fm('Digital Recruitment Solutions')}</a>
           <a href='/insights' target='_blank'>{fm('Insights')}</a>
           <a target='_blank' href='/privacy'>{fm('Privacy')}</a>
           <a target="_blank" href="https://arrangr.com/udentity">{fm('Schedule a Demo')}</a>
           <div className='mobile-bottom-part'>
             <span>Copyright © 2024, Udentity Pte Ltd. All Rights Reserved.</span>
             <a href='https://beian.miit.gov.cn' target='_blank' className='special'>沪ICP备18003806号-1</a>
           </div>
         </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .center-panel {
    text-align: center;
  }
  .mobile-bottom-part {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .mobile-middle-part {
    margin-bottom: 20px;
  }
  .mobile-content {
    padding: 20px;
    background: #F2F2F7;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1771C7;


  }
  .mobile-show {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }

  .desktop-show {
    @media (max-width: 991px) {
      display: none;
    }
  }

  a {
    color: #1771C7 !important;
    text-decoration: none;
  }
  .bottom {
    background: white;
    padding: 15px 8% 15px 5%;
    display: flex;
    column-gap: 40px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1771C7;
    justify-content: center;
    
    & > a {
      cursor: pointer;
      
      &.special {
        text-decoration: underline;
      }
    }

  }
  .list-item-imgs {
    display: flex;
    column-gap: 10px;
    & > img {
      cursor: pointer;
    }
  }
  .list-detail {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1771C7;
    margin-bottom: 10px;
    
    & > a {
      cursor: pointer;
    }
  }
  .list-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0015em;
    color: #1771C7;
    min-width: 200px;
    padding-bottom: 10px;
    border-bottom: 1px solid #1771C7;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  
  .list-item {
    
  }
  
  .top-img {
    margin-left: -8px;
  }
  
  .top {
    background: #F2F2F7;
    padding: 20px 8% 20px 5%;
  }
  
  .list {
    //margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
  }
`

export default NewFooter
