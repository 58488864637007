import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import * as echarts from 'echarts';
import importAllImg from "../../../helpers/import-all-imgs";
import MapChart from "../../university/event/toMyEvents/Map";
import eventService from "../../../services/student/event-service";
import {message} from 'antd';

const imgs = importAllImg(require.context('../../../../src/assets/img/events2', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  padding: 50px 10%;
  display: flex;
  .first-item {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #1C1C1E;
    padding: 20px 0;
    width: 260px;
    border-bottom: 1px solid #BDBDBD;
    cursor: pointer;
  }

  .second-item {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #1C1C1E;
    padding: 20px 0;
    margin-left: 30px;
    border-bottom: 1px solid #BDBDBD;
    cursor: pointer;
  }

  .selected {
    color: #1771C7;
  }

  .right {
    margin-left: 100px;
    flex: 1;
  }
  
  .top-numbers {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px #DEE1E3;
    border-radius: 10px;
    padding: 40px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .top-number {
    display: flex;
    align-items: center;
    & > img {
      margin-right: 32px;
    }
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #1C1C1E;
  }

  .chart-panel-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-top: 20px;
  }

  .middle-margin {
    margin: 30px auto;
  }

  .chart-panel {
    //border-bottom: 1px solid #BDBDBD;
    //padding-bottom: 20px;
  }

  .chart-panel-content {
    //background: #F2F2F7;
    //box-shadow: 0px 4px 10px rgba(204, 204, 204, 0.25);
    //padding: 23px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 50px 0px 100px;
    //border-bottom:  1px solid #BDBDBD;
  }

  .chart-panel-left-title {
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: center;
    transform: translate(-20px);
  }

  .chart-info {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    align-items: center;
    margin: 20px auto;

    &-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 5px;
    }

    &-text {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
      margin: 0 10px 0 0;
    }

    &-percent {
      ont-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }
  .chart-panel-right {
    flex: 0 0 200px;
  }

  .middle-margin-bottom {
    margin-bottom: 30px;
  }

  .chart-info-text {
    margin: 0 50px;
    display: inline-block;
    width: 170px;
  }

  .map-panel {
    padding: 30px 50px 40px 0px;
    display: flex;
    //align-items: center;
    overflow-x: hidden;
  }

  .tables {
    flex: 0 0 400px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #000000;
    transform: translateX(-70px);
  }
  .table-item {
    & > span {
      margin-right: 10px;
      margin-bottom: 20px;
      display: inline-block;
      &:nth-of-type(1) {
        width: 80px;
      }
      &:nth-of-type(2) {
        margin-left: 200px;
      }
    }
  }

  .map {
    transform: translateX(-100px);
  }

  .table-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 20px;
  }

`;

function EventOverview() {

  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [viewers, setViewers] = useState(0);
  const [sourceData1, setSourceData1] = useState([
    // {
    //   "key": "student",
    //   "value": 112,
    //   "size": 2
    // },
    // {
    //   "key": "parent",
    //   "value": 45,
    //   "size": 2
    // },
    // {
    //   "key": "counselor",
    //   "value": 27,
    //   "size": 2
    // }
  ]);
  const sourceDataColors1 = ['#9DD393', '#B6D7F6', '#58BD6A', '#0F4A82'];
  const [foreignData, setForeignData] = useState([
    // {
    //   "key": "China",
    //   "value": 184,
    //   "size": 2
    // }
  ]);
  const [chinaData, setChinaData] = useState({
    // "Anhui": 5,
    // "Beijingshi": 1,
    // "Chongqingshi": 4,
    // "Fujian": 4,
    // "Gansu": 6,
  });
  const data = { sourceData1, chinaData, foreignData};

  useEffect(() => {
    eventService.eventStatistics().then(res => {
      if (res.code !== 200) {
        return message.error(res.msg);
      }
      setChinaData(res.data.chinaData);
      const arr = [];
      const userTypeData = res.data.userTypeData;
      const keys = Object.keys(userTypeData);
      for (let i of keys) {
        arr.push({key: i, value: userTypeData[i]})
      }
      setSourceData1(arr);
      const arr2 = [];
      const foreignData = res.data.foreignData;
      const keys2 = Object.keys(foreignData);
      for (let i of keys2) {
        arr2.push({key: i, value: foreignData[i]})
      }
      setForeignData(arr2);
      setTotal(res.data.total);
      setViewers(res.data.eventViews)
    })
  }, [])

  useEffect(() => {
    const chartData = [];
    for (let i of sourceData1) {
      chartData.push({value: i.value, name: i.key});
    }
    const option = {
      title: {
        show: chartData.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20
        },
        text: "No data",
        left: "center",
        top: "center"
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        show: false
      },
      series: [
        {
          name: 'User Type',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          color: sourceDataColors1,
          data: chartData
        }
      ]
    };
    const chartDom = document.getElementById('chart-pie1');
    const myChart = echarts.init(chartDom);
    myChart.setOption(option);
  }, [sourceData1]);

  const totalFn2 = type => {
    return () => {
      const keys = data[type];
      let sum = 0;
      for (let i of keys) {
        sum += parseInt(i.value)
      }
      return sum;
    };
  };

  const totalFn = type => {
    return () => {
      const keys = Object.keys(data[type]);
      let sum = 0;
      for (let i of keys) {
        sum += parseInt(data[type][i])
      }
      return sum;
    };
  }

  const regions = () => {
    if (!chinaData) return [];
    const arr = [];
    const keys = Object.keys(data.chinaData);
    for (let i of keys) {
      arr.push({[i]: data.chinaData[i]});
    }
    return { domestic: arr };
  };

  const sourceDataTotal1 = totalFn2('sourceData1');
  const chinaDataTotal = totalFn('chinaData');
  const foreignDataTotal = totalFn2('foreignData');


  return (
    <Container>
      <div className='left'>
        <div className='first-item' onClick={() => history.push('/uni-event')}>Post Event</div>
        <div className='first-item'>Event Analytics </div>
        <div>
          <div className='second-item selected'>Overview</div>
          <div className='second-item' onClick={() => history.push('/event-analytics')}>Analytics </div>
        </div>
      </div>

      <div className='right'>
        <div className='top-numbers'>
          <div className='top-number'>
            <img src={imgs['calendar.svg']} />
            <div>
              {total}<br/>Total Events
            </div>
          </div>

          <div className='top-number'>
            <img src={imgs['eye2.svg']} />
            <div>
              {viewers}<br/>Total Events
            </div>
          </div>
        </div>

        <div className='top-numbers'>
          <div className='chart-panel'>
            <div className='chart-panel-title'>Viewer Types</div>
            <div className='chart-panel-content'>
              <div className='chart-panel-left'>
                <div id='chart-pie1' style={{width: '180px', height: '180px'}}></div>
              </div>
              <div className='chart-panel-right'>
                {
                  sourceData1 && sourceData1.map((i, index) => (
                    <div className='chart-info' key={i.key}>
                      <span className='chart-info-icon' style={{backgroundColor: sourceDataColors1[index]}}/>
                      <span className='chart-info-text'>{i.key}</span>
                      <span className='chart-info-percent'>{(parseInt(i.value) * 100 / sourceDataTotal1()).toFixed(2)} %</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='map-panel'>
            <div className='map'>
              <MapChart dataprop={regions()} />
            </div>
            <div className='tables'>
              <div className='china-table'>
                <div className='table-title'>China</div>
                <div>
                  {
                    chinaData && Object.keys(chinaData).map(i => (
                      <div key={i} className='table-item'>
                        <span>{i}</span>
                        <span>{(parseInt(chinaData[i]) * 100 / chinaDataTotal()).toFixed(2)} %</span>
                      </div>
                    ))
                  }
                  {chinaData && Object.keys(chinaData).length === 0 && <p className='no-data'>No Data</p>}
                </div>
              </div>
              <div className='china-table'>
                <div className='table-title'>International</div>
                <div>
                  {
                    foreignData && foreignData.map(i => (
                      <div key={i} className='table-item'>
                        <span>{i.key}</span>
                        <span>{(parseInt(i.value) * 100 / foreignDataTotal()).toFixed(2)} %</span>
                      </div>
                    ))
                  }
                  {foreignData.length === 0 && <p className='no-data'>No Data</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default EventOverview;
