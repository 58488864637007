import React, { useState, useRef } from "react";
import styled from "styled-components";

const StyledInputCode = styled.div`
  .App {
    font-family: sans-serif;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .code-input {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .code-label {
    margin-bottom: 16px;
  }
  .code-inputs {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .code-inputs input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;

    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    font-size: 20px;
  }
  .code-inputs input:focus {
    outline: none;
  }

  /* Responsive */

  @media only screen and (min-width: 991px) {
    .code-inputs input {
      height: 50px;
      width: 50px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .code-inputs input {
      height: 5.5vmax;
      width: 5.5vmax;
    }
  }

  /* END OF responsive */
`;
const InputCode = ({ length, loading, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);
  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <StyledInputCode>
      <div className="code-input p-3">
        <div className="code-inputs">
          {code.map((num, idx) => {
            return (
              <input
                key={idx}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={num}
                autoFocus={!code[0].length && idx === 0}
                readOnly={loading}
                onChange={(e) => processInput(e, idx)}
                onKeyUp={(e) => onKeyUp(e, idx)}
                ref={(ref) => inputs.current.push(ref)}
              />
            );
          })}
        </div>
      </div>
    </StyledInputCode>
  );
};

export default InputCode;
