import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import userHelper from '../helpers/user-helper.js';

const PublicRoute = ({ component: Component, ...rest }) => {
    const user = userHelper.getUser();
    const userType = user && user.user_type ? user.user_type : '' ;
    return (
        <Route {...rest} render={props => {
            if (props.match.path.includes('/message/:id')) {
              if (!user || !userType) {
                return <Redirect to="/"></Redirect>
              } else if (userType !== 'university' && userType !== 'counselor' && userType !== 'student' && userType !== 'parent' ) {
                return <Redirect to="/international-college-search" />
              }
            }
            // if (props.match.path.includes('/university/:id')) {
            //   if (!user || !userType) {
            //     return <Redirect to='/university/'></Redirect>
            //   }

            // }
            return <Component {...props} key={props.match.url + '/' + userType}  />
        }} />
    );
};

export default PublicRoute;
