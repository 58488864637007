import userHelper from "../../helpers/user-helper";
import { handleResponse } from "../../helpers/api-helper";
import { post } from "helpers/request";
import Cookies from "universal-cookie";
import { useIntl } from 'react-intl';
const cookies = new Cookies();

function signupEvent({ id }) {
  const user = userHelper.getUser();
  const url = `/api/event/${id}/signup`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders
    // body: JSON.stringify({ id })
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function signUpEventWithVendorId({ id }) {
  const user = userHelper.getUser();
  const url = `/api/event/${id}/vendor/signup`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow"
  };

  return fetch(url, requestOptions)
    .then(function(response) {
      if (response.status !== 200 && response.status !== 301) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    })
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

// Get Upcoming Events
function getAllEvents(language) {
  const user = userHelper.getUser();
  const url = `/api/event/all/${language}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

// Get Upcoming Events
function getUpcomingEvents() {
  const user = userHelper.getUser();
  const url = `/api/event/upcoming`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function getAttendingEvents(language) {
  const user = userHelper.getUser();
  const url = `/api/event/attending/${language}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function sendSZBComment(commentID, cookieId, message) {
  const user = userHelper.getUser();
  const url = `https://live.udty.tech/api/user/activity/${commentID}/comment\?parSid=${cookieId}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ content: message })
  };

  return fetch(url, requestOptions)
    .then(function(response) {
      if (response.status !== 200 && response.status !== 301) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    })
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function transformId(tmp) {
  const url = `/api/v3/event/realId/${tmp}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    })
    .catch(function(err) {
      throw err;
    });

}

// Get Event By Id
function getEventById({ id }, language) {
  const user = userHelper.getUser();
  // const url = `/api/event/${id}/${language}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then(response => {
        return response.data;
      })
      .catch(function(err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => {
          return response.data;
        })
        .catch(function(err) {
          throw err;
        });
    })
  }


}

function getSZBComments(commentID) {
  const user = userHelper.getUser();
  const url = `https://shangzhibo.tv/api/user/activity/${commentID}/comment?page=1&limit=100`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
    // credentials: 'include'
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function createNewEvent(params, vendor) {
  const user = userHelper.getUser();
  const url = `/api/event/new/${vendor}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {

      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function createExistingEvent(params) {
  const user = userHelper.getUser();
  const url = `/api/event/existing`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(function(err) {
        throw err;
      })
  );
}

function getRecommendList(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/event/recommend?id=${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}


function getComments(id, parentId) {
  const user = userHelper.getUser();

  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/eventComment/getComments?eventId=${id}`;
    if (parentId) url += `&parentId=${parentId}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(function(err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/eventComment/getComments?eventId=${res}`;
      if (parentId) url += `&parentId=${parentId}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }



}

function getIsLikeStatus(id) {
  const user = userHelper.getUser();
  // const url = `/api/v3/event/isLike/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/isLike/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/isLike/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }
}

function postLike(id){
  const user = userHelper.getUser();
  // const url = `/api/v3/event/like/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/like/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/like/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }

}

function cancelLike(id){
  const user = userHelper.getUser();
  // const url = `/api/v3/event/cancelLike/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/cancelLike/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/cancelLike/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }
}



function postComment(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/eventComment/postComment`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  if (isNumeric(params.eventId)) {
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(params)
    };

    return (
      fetch(url, requestOptions)
        .then(handleResponse)
        .then(response => {
          return response;
        })
        .catch(function(err) {
          throw err;
        })
    );
  } else {
    return transformId(params.eventId).then(res => {
      params.eventId = res;
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(params)
      };
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }


}

function agreeComment(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/eventComment/agree/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(function(err) {
        throw err;
      })
  );
}

function getStatus(id) {
  const user = userHelper.getUser();
  // let url = `/api/v3/event/stats/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/stats/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/stats/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }
}

function feedback(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/feedback`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return (
    fetch(url, requestOptions)
      .then(handleResponse)
      .then(response => {
        return response;
      })
      .catch(function(err) {
        throw err;
      })
  );
}


function create(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/event/create`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function getMeeting(id) {
  const user = userHelper.getUser();
  // let url = `/api/v3/fair/may/event/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  if (user != undefined) {
    myHeaders.append("Authorization", "Basic " + user.auth_token);
  }

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/fair/may/event/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/fair/may/event/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }
}


function eventAction(action, id) {
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  let url = '';
  if (id) {
    const isNumeric = (str) => {
      if (typeof str != "string") return false
      return !isNaN(str) &&
        !isNaN(parseFloat(str))
    }
    if (isNumeric(id)) {
      url = `/api/v3/event/other/${action}/${id}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    } else {
      return transformId(id).then(res => {
        url = `/api/v3/event/other/${action}/${res}`;
        return fetch(url, requestOptions)
          .then(handleResponse)
          .then((response) => {
            return response;
          })
          .catch(function (err) {
            throw err;
          });
      });
    }
  } else {
    url = `/api/v3/event/other/${action}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  }


}

function logProfile(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/profile/log/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function signupEvent2(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/event/signUp`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      "channel": "pc",
      "eventId": id
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function hasSignedup(id) {
  const user = userHelper.getUser();
  let url = `/api/v3/event/signedUp/${id}`;
  const myHeaders = new Headers();
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };


  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function eventAnalyticsList(pageNum, pageSize) {
  const user = userHelper.getUser();
  let url = `/api/v3/event/analyticsList?pageNum=${pageNum}&pageSize=${pageSize}`;
  const myHeaders = new Headers();
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };


  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function eventStatistics() {
  const user = userHelper.getUser();
  let url = `/api/v3/event/statistics`;
  const myHeaders = new Headers();
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };


  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}



const createNewOfflineEvent = post("/api/event/new/offline");


export default {
  getMeeting,
  signupEvent,
  signUpEventWithVendorId,
  getAllEvents,
  getAttendingEvents,
  getEventById,
  getUpcomingEvents,
  createNewOfflineEvent,
  sendSZBComment,
  getSZBComments,
  createExistingEvent,
  createNewEvent,
  getRecommendList,
  getIsLikeStatus,
  postLike,
  cancelLike,
  getComments,
  postComment,
  agreeComment,
  getStatus,
  feedback,
  create,
  eventAction,
  logProfile,
  signupEvent2,
  hasSignedup,
  eventAnalyticsList,
  eventStatistics
}
