import React, { useState, useEffect } from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import { Popover } from "antd";
import styled from "styled-components";
import _ from 'lodash';
import { useIntl } from 'react-intl';

import FooterLangTag from "./FooterLangTag";

// import "./footer.css";
import {
  weChatQRCode,
  wechatIcon,
  inIcon,
  facebookIcon,
  logoName, translateNew
} from "../../../helpers/images-helper.js";
import importAllImg from "../../../helpers/import-all-imgs";
import SolutionPanel from "../../public/header/SolutionPanel";
import LangTag from "./LangTag";

const imgs = importAllImg(require.context('../../../../src/assets/img/new-home-page', false, /\.(png|jpe?g|svg)$/));

const imgs2 = importAllImg(require.context('../../../../src/assets/img/insights', false, /\.(png|jpe?g|svg)$/));

const imgs3 = importAllImg(require.context('../../../../src/assets/img/new-solutions', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  position: relative;
  .footer-img {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }
  .middle-panel {
    justify-content: center;
    column-gap: 40px;
    width: 80%;
    margin-left: 10%;
  }
  .top-panel{
    justify-content: center;
  }
  .follow-panel {
    display: none;
  }
  .icp {
    text-align: center;
    padding: 10px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #1771C7;
    background: #F3F8FC;
  }
  padding: 30px;
  background: #f2f2f7;
  .desktop-display-none {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
    
    & > div {
      flex: 1;
      @media (max-width: 991px) {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 15px;
      }
    }
    
    & > div:nth-child(1) {
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    
    .title {
      font-size: 20px;
      color: #1771c7;
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 20px;

      @media (max-width: 991px) {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > img {
          margin-left: 5px;
        }
      }
    }
    
    .content {
      display: flex;
      flex-flow: column nowrap;
      & > a {
        text-decoration: none;
        color: #1771c7;
        margin: 10px 0px;
        letter-spacing: .1em;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

      }
    }
    
    .icons {
      display: flex;
      @media (max-width: 991px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
        //margin-top: 20px;
        width: 140%;
        //margin-left: -20%;
      }
      & > img {
        height: 25px;
        @media (max-width: 991px) {
          height: 40px;
        }
      }
      & > img, & > a {
        margin-right: 10px;
        @media (max-width: 991px) {
          margin-right: 0;
          display: flex;
          justify-content: center;
        }
        
        & > img {
          height: 25px;
          @media (max-width: 991px) {
            height: 40px;
          }
        }
      }
      
      
    }

    .lang-title {
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
    
  }
  
  .copy-right {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0015em;
    color: #1771C7;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-top: 15px;
  }
  
  .copy-right-mobile {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #1771C7;
    margin-top: 30px;
  }
  
  .lang-content {
    @media (max-width: 991px) {
      margin-bottom: 20px !important;
    }
  }

`;

const MobileContainer = styled.div`
  
  .follow-panel {
    display: none;
  }

  .logo {
    text-align: center;
    & > img {
      width: 150px;
      height: 82.67px;
    }
  }
  .lang-panel {
    display: inline-flex;
    align-items: center;
    
  }
  .copy-right {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #000000;
    padding-top: 20px;
    margin-bottom: 0;
    display: flex;
    flex-flow: column;
    padding-bottom: 10px;
  }
  section {
    background: #F2F2F7;
    padding: 20px 20%;
  }
  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #1C1C1E;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    & > a {
      text-decoration: none;
      color: #1771c7;
      margin: 10px 0px;
      letter-spacing: .1em;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

    }
  }

  .icons {

    margin: 0 auto;
    @media (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px 40px;
      
      margin-top: 20px;
    }
    & > img {
      height: 25px;
      @media (max-width: 991px) {
        height: 40px;
      }
    }
    & > img, & > a {
      margin-right: 10px;
      @media (max-width: 991px) {
        margin-right: 0;
        display: flex;
        justify-content: center;
      }

      & > img {
        height: 25px;
        @media (max-width: 991px) {
          height: 40px;
        }
      }
    }


  }

  background: white;
  & > div {
    background: #F2F2F7;
    padding: 10px 5% 0 5%;

    & > div {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      border-bottom: 1px solid white;
      & > div {
        width: 40%;
        margin-left: -15%;
        text-align: right;

        & > a {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.1em;
          color: #000000;
          display: inline-flex;
          align-items: center;
          & > img {
            margin-right: -20px;
            margin-left: 10px;
          }

        }
      }

    }
  }
`;


function Footer(props) {
  const [lessAbout, setLessAbout] = useState(false);
  const [lessSupport, setLessSupport] = useState(false);
  const [lessLang, setLessLang] = useState(false);
  const [lessNew, setLessNew] = useState(false);
  const [lessNew2, setLessNew2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { formatMessage, locale } = useIntl();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setLessAbout(true);
      setLessSupport(true);
      setIsMobile(true);
      setLessLang(true);
      setLessNew(true);
      setLessNew2(true);
    }
  }, []);

  const handleLessNew = () => {
    if (isMobile) setLessNew(i => !i);
  };

  const handleLessNew2 = () => {
    if (isMobile) setLessNew2(i => !i);
  };

  const handleAbout = () => {
    if (isMobile) setLessAbout(i => !i);
  };

  const handleSupport = () => {
    if (isMobile) setLessSupport(i => !i);
  };

  const handleLang = () => {
    if (isMobile) setLessLang(i => !i);
  }

  const navTo = path => {
    history.push(path)
  };

  const hasFooter = ['/', '/about-us', '/events', '/cambridgeinternational2020', '/college-fair-registration',
    '/cambridgeinternational-onboarding', '/college-fair', '/june-fair', '/faqs', '/features', '/privacy',
    '/terms', '/april-fair-onboarding', '/april-fair', '/EducationUSA2021fair', '/international-education-insights', '/wechat-miniprogram-bilibili-weibo', '/university-admissions-information-sessions',
    '/WeChat-Official-Account-Management', '/WeChat-mini-program-functions-development', '/bilibili', '/weibo', '/chinese-student-recruitment/strategy-solutions', '/chinese-student-recruitment/events-fairs'
  ];

  const fm = id => formatMessage({id});

  if ((hasFooter.includes(location.pathname) || props.isNoMatch) && isMobile) {

    return (
      <MobileContainer>
        <div className='logo'>
          <img alt='Udentity logo' src={imgs3['Udentity2 1.png']}/>
        </div>
        <div>
          <div>
            <div>
              <SolutionPanel>
                <a>
                  {formatMessage({ id: "solutions" })}
                  <img src={imgs3['Vector.svg']} />
                </a>
              </SolutionPanel>
            </div>
          </div>


        </div>
        <div>
          <div>
            <div>
              <a onClick={() => navTo('university-admissions-information-sessions')} >{formatMessage({ id: "events" })}</a>
            </div>
          </div>

        </div>
        {/*<div>*/}
        {/*  <div>*/}
        {/*    <div>*/}
        {/*      <a onClick={() => navTo('insights')} >INSIGHTS</a>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*</div>*/}
        <div>
          <div>
            <div>
              <a onClick={() => window.open('/about-udentity')} >{fm('ABOUT')}</a>
            </div>
          </div>

        </div>

        <div style={{marginBottom: 0}}>
          <div>
            <div>
            <span className='lang-panel'>
              <img alt='toggle lang icon' src={translateNew}/>
              <LangTag />
            </span>
            </div>
          </div>

        </div>
        <section className='follow-panel'>
          <div className='title' style={{marginLeft: locale === 'zh' && 10}}>{fm('FOLLOW US')}</div>
          <div className='icons'>
            <Popover content={<img src={weChatQRCode} height="120" width="120"/>} trigger="hover">
              <a>
                <img alt='wechat' src={wechatIcon}/>
              </a>
            </Popover>
            <a href="https://www.facebook.com/udty.tech/" target="_blank">
              <img alt='facebook' src={facebookIcon}/>
            </a>
            <a
              href="https://www.linkedin.com/company/udentity/"
              target="_blank"
            >
              <img alt='linkedin' src={inIcon}/>
            </a>
            <a
              href="https://www.instagram.com/udentity_teams/"
              target="_blank"
            >
              <img alt='instagram' src={imgs2['iconmonstr-instagram-13.svg']}/>
            </a>
            <a
              href="https://medium.com/@udentity"
              target="_blank"
            >
              <img alt='medium' src={imgs2['iconmonstr-medium-3.svg']}/>
            </a>
            <a
              href="https://www.youtube.com/@udentity5941"
              target="_blank"
            >
              <img alt='youtube'  src={imgs2['image 2.png']}/>
            </a>

          </div>
        </section>

        <div className='copy-right'>
          <span>Copyright © 2023, Udentity Pte Ltd. <br/> All Rights Reserved.</span>
          <a target="_blank" href='https://beian.miit.gov.cn' >沪ICP备18003806号-1</a>
        </div>

      </MobileContainer>
    );

  }

  if (hasFooter.includes(location.pathname) || props.isNoMatch) {

    if (location.pathname === '/EducationUSA2021fair' && isMobile) return null;
    return (
      <Container>
        <img className='footer-img' src={imgs['Subtract (1).svg']} />

        <div className='top-panel'>
          <img alt='Udentity logo' src={imgs3['Udentity2 1.svg']}/>
        </div>

        <div className='middle-panel'>

          <div>
            <div className='title' onClick={handleLessNew}>
              {formatMessage({id: "SOLUTIONS"})}
              <img src={lessNew ? imgs['CaretLeft.svg'] : imgs['CaretLeft1.svg']} className='desktop-display-none'/>
            </div>
            <div className='content' style={{display: lessNew ? 'none' : ''}}>
              <Link to="/university-admissions-information-sessions">College Fairs and Events</Link>
              <Link to="/wechat-miniprogram-bilibili-weibo">Digital Outreach and Recruitment</Link>
            </div>
          </div>

          <div>
            <div className='title' onClick={handleAbout}>
              INSIGHTS
              <img src={lessAbout ? imgs['CaretLeft.svg'] : imgs['CaretLeft1.svg']} className='desktop-display-none'/>
            </div>
            <div className='content' style={{display: lessAbout ? 'none' : ''}}>
              {/*<Link to="/chinese-student-recruitment/strategy-solutions">Resources</Link>*/}
              <Link to="/faqs">{formatMessage({id: "FAQS"})}</Link>
              <a href="https://arrangr.com/udentity" target="_blank">{formatMessage({id: "scheduleDemo"})}</a>
            </div>
          </div>

          <div>
            <div className='title' onClick={handleLessNew2}>
              COMPANY
              <img src={lessNew2 ? imgs['CaretLeft.svg'] : imgs['CaretLeft1.svg']} className='desktop-display-none'/>
            </div>
            <div className='content' style={{display: lessNew2 ? 'none' : ''}}>
              <a onClick={() => window.open('/about-udentity')}>About Udentity</a>
              <a href="mailto:team@udty.tech">{formatMessage({id: "contactUs"})}</a>
            </div>
          </div>


          <div>
            <div className='title' onClick={handleSupport}>
              {formatMessage({id: "Support"})}
              <img src={lessSupport ? imgs['CaretLeft.svg'] : imgs['CaretLeft1.svg']} className='desktop-display-none'/>
            </div>
            <div className='content' style={{display: lessSupport ? 'none' : ''}}>
              <Link to="/support">{formatMessage({id: "supportCenter"})}</Link>
              <Link to="/privacy">{formatMessage({id: "Privacy"})}</Link>
              <Link to="/terms">{formatMessage({id: "termsAndConditions"})}</Link>
            </div>
          </div>


          <div>
            <div className='title lang-title' onClick={handleLang}>
              {formatMessage({id: "Language"})}
              <img src={lessLang ? imgs['CaretLeft.svg'] : imgs['CaretLeft1.svg']} className='desktop-display-none'/>
            </div>
            <div className='content' style={{display: lessLang ? 'none' : ''}}>
              <FooterLangTag/>
            </div>
            <div className='follow-panel'>
              <div className='title'>FOLLOW US</div>
              <div className='icons'>
                <Popover content={<img src={weChatQRCode} height="120" width="120"/>} trigger="hover">
                  <a>
                    <img alt='wechat' src={wechatIcon}/>
                  </a>
                </Popover>
                <a href="https://www.facebook.com/udty.tech/" target="_blank">
                  <img alt='facebook' src={facebookIcon}/>
                </a>
                <a
                  href="https://www.linkedin.com/company/udentity/"
                  target="_blank"
                >
                  <img alt='linkedin' src={inIcon}/>
                </a>
                <a
                  href="https://www.instagram.com/udentity_teams/"
                  target="_blank"
                >
                  <img alt='instagram' src={imgs2['iconmonstr-instagram-13.svg']}/>
                </a>
                <a
                  href="https://medium.com/@udentity"
                  target="_blank"
                >
                  <img alt='medium' src={imgs2['iconmonstr-medium-3.svg']}/>
                </a>
                <a
                  href="https://www.youtube.com/@udentity5941"
                  target="_blank"
                >
                  <img alt='youtube'  src={imgs2['image 2.png']}/>
                </a>

              </div>
            </div>
          </div>

        </div>

        <div className='copy-right'>
          <span>Copyright © 2022, Udentity Pte Ltd. All Rights Reserved.</span>
          <a target="_blank" href='https://beian.miit.gov.cn' >沪ICP备18003806号-1</a>
        </div>



      </Container>
    );
  } else {
    return null;
  }


}

export default Footer;
