export const emojiFilter = {
  "1F604": true,
  "1F603": true,
  "1F600": true,
  "1F60A": true,
  "263A": true,
  "1F609": true,
  "1F60D": true,
  "1F618": true,
  "1F61A": true,
  "1F617": true,
  "1F619": true,
  "1F61C": true,
  "1F61D": true,
  "1F61B": true,
  "1F633": true,
  "1F601": true,
  "1F614": true,
  "1F60C": true,
  "1F612": true,
  "1F61E": true,
  "1F623": true,
  "1F622": true,
  "1F602": true,
  "1F62D": true,
  "1F62A": true,
  "1F625": true,
  "1F630": true,
  "1F605": true,
  "1F613": true,
  "1F629": true,
  "1F62B": true,
  "1F628": true,
  "1F631": true,
  "1F620": true,
  "1F621": true,
  "1F624": true,
  "1F616": true,
  "1F606": true,
  "1F60B": true,
  "1F637": true,
  "1F60E": true,
  "1F634": true,
  "1F635": true,
  "1F632": true,
  "1F61F": true,
  "1F626": true,
  "1F627": true,
  "1F608": true,
  "1F47F": true,
  "1F62E": true,
  "1F62C": true,
  "1F610": true,
  "1F615": true,
  "1F62F": true,
  "1F636": true,
  "1F607": true,
  "1F60F": true,
  "1F611": true,
  "1F49B": true,
  "1F499": true,
  "1F49C": true,
  "1F49A": true,
  "2764": true,
  "1F494": true,
  "1F497": true,
  "1F493": true,
  "1F495": true,
  "1F496": true,
  "1F49E": true,
  "1F498": true,
  "1F48C": true,
  "1F48B": true,
  "00000": true
  // "1F48D": true,
  // "1F48E": true,
  // "1F389": true,
};
