import React from 'react';
import styled from 'styled-components';
import importAllImg from "../../../helpers/import-all-imgs";
import { useIntl } from 'react-intl';
import {Link, useHistory} from "react-router-dom";

const Container = styled.div`
  width: 520px;
  //width: 620px;
  //height: 38px;
  height: 52px;
  overflow-x: hidden;
  //width: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  margin: 5px auto;
  //padding-left: 10px;
  display: flex;
  align-items: center;

  @media(max-width: 991px) {
    width: 43px;
  }


  .edu-img {
    @media(max-width: 991px) {
      width: 43px;
    }
  }

  .act-img {
    @media(max-width: 991px) {
      width: 32px;
    }
  }

  .visit-icon {
    width: 130px;
    height: 100%;
    margin-left: auto;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background: #1D427C;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @media(max-width: 991px) {
      width: 75px;
      height: 20px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      font-size: 10px;
    }
  }

  .pr-60 {
    padding-right: 60px;
    @media(max-width: 991px) {
      padding-right: 5px;
      margin-right: auto;
    }
  }
  .pr-10 {
    padding-right: 10px;
  }
  //margin-top: 5px;
  //margin-bottom: 5px;

  @media(max-width: 991px) {
    //display: none;
    //width: fit-content;
    height: 20px;
    border-radius: 5px;
    flex: 1;
    //width: 90%;
    width: 80%;
    margin: 5px auto;
  }

  .icon-container{
    //width: 54px;
    //height: 38px;
    //background: #C8102E;
    height: 100%;
    border-radius: 7px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
    @media(max-width: 991px) {
      height: 100%;
      width: 21px;
      border-radius: 5px;
      display: none;
    }
  }

  .icon {
    box-sizing: content-box;
    margin-top: 8px;
    //padding: 9px;

    @media(max-width: 991px) {
      //width: 12px;
      height: 12px;
      //padding: 3px;
      //border-radius: 3px;
      display: none;
    }
  }


  .content {
    //margin-left: 30px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.2;
    color: #757575;

    //color: #1C1C1E;



    @media(max-width: 991px) {
      margin-left: 6px;
      font-size: 9px;
    }

    & > a {
      margin-left: 5px;
      color: #376EC0;
      text-decoration: none;
    }
  }
`;

const TempContainer = styled.div`
  .mr-40 {
    margin-right: 40px;
    @media(max-width: 991px) {
      margin-right: 0;
    }
  }
  display: flex;
  justify-content: center;
  //width: 75%;
  margin: 0 auto;
  position: relative;
  //z-index: 3;
  .icon-red {
    background: #A43138;
  }
  @media(max-width: 991px) {
    width: 100%;
    flex-flow: column nowrap;
  }
  
  .banner2 {
    display: flex;
    height: 52px;
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin: 5px auto;
    //padding-right: 10px;
    padding: 4px 10px;
    align-items: center;
    @media(max-width: 991px) {
      height: auto;
      width: 98%;
      padding: 4px 0;
    }
  }


  .icon2 {
    width: 28px;
    height: 36px;
    @media(max-width: 991px) {
      width: 11px;
      height: 14px;
    }
  }

  .content2 {
    //margin-left: 30px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #757575;
    display: flex;
    flex-flow: column;

    @media(max-width: 991px) {
      margin-left: 6px;
      font-size: 10px;
      line-height: 14px;
      flex-flow: row;
      align-items: center;
    }

    & > a {
      color: #376EC0;
    }
  }
`

const imgs = importAllImg(require.context('../../../../src/assets/img/new-fair', false, /\.(png|jpe?g|svg)$/));

function Notification() {

  const { formatMessage, locale } = useIntl();
  const history = useHistory();

  const fm = id => formatMessage({id});

  const pathname = location.pathname;
  if (pathname === '/cambridgeinternational2021' || pathname === '/jiangnanfair' || /\/cie-event\/\d+/.test(pathname)) return null;
  if (pathname === '/EducationUSA2024ShanghaiFair') return null;
  if (pathname === '/EducationUSA2024Fair') return null;
  if (pathname === '/nus-2024') return null;
  if (pathname === '/NUS2024') return null;
  if (pathname === '/nus-2025') return null;
  if (pathname === '/nusadmissionsopenhouse') return null;
  const isMobile = window.innerWidth <= 991;
  return (
    <Container>
      {/*<span className='icon-container'>*/}
      {/*  <img className='icon' src={imgs['image 2276.svg']} />*/}
      {/*</span>*/}
      <img className='icon' src={isMobile ? imgs['NUS_coat_of_arms 24.png'] : imgs['image 2074.svg']} />
      <span className='content'>
          {/*{formatMessage({id: 'EducationUSA University Fair (September 22nd)'})}*/}
          {/*{formatMessage({id: 'ACT GAC China HS Fair (Sept 29th) is Live '})}*/}
        {/*The Cambridge International 2021 fair is live! &nbsp;*/}
        {/*{formatMessage({id: 'EducationUSA college Fair (August 20th)'})} &nbsp;*/}
        {/*Udentity X HSYLC College Fair 2021 is Live ! &nbsp;*/}
        {/*<a target="_blank" href='/EducationUSA2024Fair'>{formatMessage({id: 'Visit Page →'})}</a>*/}
        {
          locale === 'en' ? <>
            {isMobile ? 'National University of Singapore (March 1st)' : <>National University of Singapore (NUS) <br/> E-Open House - March 1st</>}
          </>: <>新加坡国立大学在线开放日（03月01日）</>
        }
      </span>
       <div className='visit-icon' onClick={() => window.open('/nusadmissionsopenhouse')}>
         {fm('Visit Now →')}
       </div>
    </Container>
    // <TempContainer>
    //   <Container className='mr-40'>
    //     <img className='edu-img' src={imgs['image 2097.svg']} />
    //
    //     <span className='content pr-60'>
    //       {
    //         locale === 'en' ? <>
    //           International Education Fair 2023 {!isMobile &&<br/> }
    //           19th - 20th {isMobile ? 'Sept': 'September'}
    //         </>: <>
    //           ACT 国际教育展 2023 {!isMobile &&<br/> }
    //           9月19-20号
    //         </>
    //       }
    //
    //     </span>
    //     <div className='visit-icon' onClick={() => history.push('/ACTfair2023')}>
    //       {fm('Visit Now →')}
    //     </div>
    //   </Container>
    //   <Container>
    //
    //     <img className='act-img' src={imgs['image 2263.svg']} />
    //     <span className='content pr-60'>
    //       {
    //         locale === 'en' ? <>
    //           EducationUSA 2023 University Fair {!isMobile &&<br/> } 23rd Sept
    //         </> : <>
    //           EducationsUSA 大学展2023  {!isMobile &&<br/> }
    //           9月23号
    //         </>
    //       }
    //
    //     </span>
    //     <div className='visit-icon' onClick={() => history.push('/EducationUSA2023Fair')}>
    //       {fm('Visit Now →')}
    //     </div>
    //   </Container>
    // </TempContainer>
  );
}

export default Notification;
