import {
  CHANGE_LANG,
  TOKEN_EXPIRED,
  DISPLAY_BANNER,
  ADMIN_STATUS
} from "../actionTypes";
const initialState = {
  lang: navigator.language.split("-")[0],
  tokenExpired: false,
  banner: true,
  admin: false
};
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANG:
      return {
        ...state,
        lang: action.payload
      };

    case TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload
      };
    case DISPLAY_BANNER:
      return {
        ...state,
        banner: action.payload
      };

    case ADMIN_STATUS:
      return {
        ...state,
        admin: action.payload
      };

    default:
      return state;
  }
}

export default rootReducer;
