import userHelper from "../helpers/user-helper";
import { handleResponse } from "../helpers/api-helper";
import { get, put, post } from "../helpers/request";

function getProfile({ id }) {
  const user = userHelper.getUser();
  const url = `/api/v3/highschool/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    })
    .catch(function(err) {
      throw err;
    });
}

function getProfile2(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/highschool?aesData=${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}


function getStudents2(pageNum) {
  const user = userHelper.getUser();
  const url = `/api/v3/highschool/students?pageNum=${pageNum}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function updateCalendar(data) {
  const user = userHelper.getUser();
  const url = `/api//high_school/calendar`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(data)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}
function getCalendar(month, year, id) {
  const user = userHelper.getUser();
  const url = user.user_type==="counselor"?`/api/high_school/calendar?month=${month}&year=${year}`:`/api/high_school/${id}/calendar?month=${month}&year=${year}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}


function getShowcaseHighscools() {
  const url = `/api/high_school/showcase_list`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}

function bilibiliList() {
  const url = `/api/v3/event/bilibili`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      throw err;
    });
}


const getStudents = id => get(`/api/high_school/${id}/students`)();

const searchHighSchools = get("/api/high_school/search");

const getHighSchoolProfile = get("/api/v3/profile");

const updateHighSchoolProfile = put("/api/high_school/profile");
const updateHighSchoolProfile2 = put("/api/v3/highschool");

const getUpcomingEvents =language=> get(`/api/event/upcoming/${language}`)();

const getPastEvents = language => get(`/api/event/past/${language}`)();

const getAllEvents = language => get(`/api/event/all/${language}`)();

const signEvent = id => post(`/api/event/${id}/signup`)();

const getSettings = get("/api/settings");

const updateSettings = put("/api/high_school/settings");

const getAnalytics = get("/api/analytics/profile");

const getPk = get('/api/v3/auth/getPublicKey');

const getKey = post('/api/v3/auth/getAesKey');


function getUpcomingEvents2(params) {
  const url = `/api/v3/event/upcoming`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: params,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getPastEvents2(params) {
  const url = `/api/v3/event/past`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getUni(params) {
  const url = `/api/v3/university/searchv2`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: params,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getSp(params) {
  const url = `/api/v3/summerProgram/getSummerPrograms`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: params,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getSpDetail(id) {
  const url = `/api/v3/summerProgram/getSummerProgram?aesData=${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHs(params) {
  const url = `/api/v3/highschool/searchv2`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: params,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function subUni(params) {
  const url = `/api/v3/fair/subscribe`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

export default {
  updateSettings,
  getSettings,
  getCalendar,
  getStudents,
  getProfile,
  signEvent,
  getUpcomingEvents,
  getPastEvents,
  getAllEvents,
  getHighSchoolProfile,
  searchHighSchools,
  updateHighSchoolProfile,
  getAnalytics,
  updateCalendar,
  getShowcaseHighscools,
  bilibiliList,
  getUpcomingEvents2,
  getPastEvents2,
  getPk,
  getKey,
  getUni,
  getHs,
  subUni,
  updateHighSchoolProfile2,
  getProfile2,
  getStudents2,
  getSp,
  getSpDetail
};
