import React, { useState, useEffect } from "react";
import userService from "../../../../services/user-service.js";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { message } from "antd";

const StyledTimer = styled.div`
  width: 100%;
  @media only screen and (min-width: 991px) {
    .timer-btn {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .timer-btn {
      font-size: ${(props) => (props.smsLogin ? "16px" : "xx-small")};
      height: ${(props) => (props.smsLogin ? "" : "45px")};
    }
  }
`;

const VerificationTimer = (props) => {
  const [seconds, setSeconds] = useState(props.seconds);
  const [isActive, setIsActive] = useState(props.instantStart ? true : false);

  const {
    intl: { formatMessage },
    sendVerificationCode
  } = props;

  // const sendVerificationCode = async () => {
  //   props.setLoadingText("Getting verification code");
  //   props.setLoadingState(true);
  //   try {
  //     // await userService.sendVerificationCodeLogin(props.phoneNumber);
  //     await userService.sendVerificationCodeLogin(props.phoneNumber);
  //
  //     props.setLoadingState(false);
  //   } catch (e) {
  //     props.setLoadingState(false);
  //     Array.isArray(e)
  //       ? props.setErrorMessage(e[0].msg.toLowerCase())
  //       : props.setErrorMessage(e.toLowerCase());
  //     setSeconds(props.seconds);
  //     setIsActive(false);
  //   }
  // };

  const toggle = () => {
    props.smsLogin && sendVerificationCode();

    props.smsLogin
      ? setIsActive(!isActive)
      : props.canBeClicked && setIsActive(!isActive);
  };

  const activeStateChange = () => {
    props.activeState && props.activeState(isActive);
  };

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setSeconds(props.seconds);
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    activeStateChange();
  }, [isActive]);

  return (
    <StyledTimer smsLogin={props.smsLogin}>
      <button
        type="button"
        className={`btn btn-purple timer-btn nunito-extra-bold clr-white`}
        style={props.buttonStyle}
        onClick={seconds === props.seconds && toggle}
      >
        {isActive
          ? props.activeText
            ? `${seconds} ${formatMessage({ id: "seconds" })} ${formatMessage({
                id: `${props.activeText}`,
              })}`
            : `${seconds} ${formatMessage({ id: "seconds" })} ${formatMessage({
                id: "toRetrieveANewCode",
              })}`
          : props.notActiveText
          ? `${formatMessage({ id: `${props.notActiveText}` })}`
          : `${formatMessage({ id: "getVerificationCode" })}`}
      </button>
    </StyledTimer>
  );
};

export default injectIntl(VerificationTimer);
