import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import UserContext from './UserContext';
import LangContext from './LangContext';
import forge from 'node-forge';
import { connect } from "react-redux";
import { getLanguage } from "../redux/selectors";
import { v4 as uuidv4 } from 'uuid';

import { IntlProvider } from "react-intl";

import enMessages from "../locales/en.json";
import zhMessages from "../locales/zh.json";
import './App.css';
import { Helmet } from "react-helmet";

const localeMessages = {
  en: enMessages,
  zh: zhMessages,
};

const UpcomingFair = loadable(() =>
  import("../components/public/upcoming-fair/UpcomingFair")
);

const HomePage = loadable(() =>
  import("../components/public/home-page/HomePage")
);
const AboutUs = loadable(() => import("../components/public/about-us/AboutUs"));
const Features = loadable(() =>
  import("../components/public/features/Features")
);

const Events = loadable(() => import('../components/public/events/Events'));
const FAQS = loadable(() => import("../components/public/faqs/FAQS"));
const PrivacyPage = loadable(() =>
  import("../components/public/privacy/PrivacyPage")
);

const TermsAndConditionsPage = loadable(() =>
  import("../components/public/terms-and-conditions/TermsAndConditions")
);

const SupportPage = loadable(() =>
  import("../components/public/privacy/SupportPage")
);
const ForgotPassword = loadable(() =>
  import("../components/public/forgot-password/ForgotPassword")
);
const LogoutComponent = loadable(() =>
  import("../components/public/logout/Logout")
);
/*message*/
const MessageComponent = loadable(() =>
  import("../components/shared/app/message/Message")
);

const Message = loadable(() => import('../components/public/message/Message'));

const StudentProfile = loadable(() =>
  import("../components/university/profile/StudentProfile")
);
const StudentSignup = loadable(() =>
  import("../components/student/signup/Signup")
);

const StudentEventsComponent = loadable(() =>
  import("../components/student/event/Event")
);

const StudentSeeAllEventsComponent = loadable(() =>
  import("../components/student/event/SeeAllEvents")
);

const UniversitySignup = loadable(() =>
  import("../components/university/signup/Signup")
);
const CounselorSignup = loadable(() =>
  import("../components/counselor/signup/Signup")
);
const UniversitySignupConfirmation = loadable(() =>
  import("../components/university/signup/SignupConfirmation")
);
const CounselorSignupConfirmation = loadable(() =>
  import("../components/counselor/signup/SignupConfirmation")
);

const Profile = loadable(() => import("../components/student/profile/Profile"));
const Explore = loadable(() => import("../components/student/explore/Explore"));
const NewExplore = loadable(() => import("../components/public/explore/Explore"));
const CollegeFair = loadable(() =>
  import("../components/public/fairs/CollegeFair")
);

const HSLYCFair = loadable(() =>
  import("../components/public/fairs/hsylc-fair/HSLYCFair")
);

const AUCAFair = loadable(() => import("../components/public/fairs/auca-fair/AUCAFair"));
const IndonesiaFair = loadable(() => import("../components/public/fairs/indonesia-fair/IndonesiaFair"));
const EducationUSAFair = loadable(() => import("../components/public/fairs/educationUSA-fair/EducationUSAFair"));
const CIEFair = loadable(() => import("../components/public/fairs/CIE-fair/CIEFair"));

const CollegeFairRegistration = loadable(() =>
  import("../components/public/fairs/CollegeFairRegistration")
);

const CollegeFairJune = loadable(() =>
  import("../components/public/fairs/june-fair/CollegeFairJune")
);

const CIEOnBoarding = loadable(() =>
  import("../components/public/fairs/cie-onboarding/CIEOnBoarding")
);

const HsFair = loadable(() =>
  import("../components/public/fairs/hs-fair/HsFair")
);

const SearchResultsStudentComponent = loadable(() =>
  import("../components/student/search-results/SearchResults")
);
const EventComponent = loadable(() =>
  import("../components/public/event/Event")
);

const ZoomEventWatch = loadable(() =>
  import("../components/public/event/ZoomEventWatch")
);
const UniversityComponent = loadable(() =>
  import("../components/public/university/University")
);
const WechatQR = loadable(() => import("../components/public/wechat-qr/WechatQR"));
const MyUniversitiesComponent = loadable(() =>
  import("../components/student/my-universities/MyUniversities")
);
const MyUniversities = loadable(() =>
  import("../components/public/my-uni/MyUni")
);
const Settings = loadable(() =>
  import("../components/student/settings/Settings")
);

const StudentSetting = loadable(() =>
  import("../components/public/student-setting/StudentSetting")
);
const StudentNotificationsComponent = loadable(() =>
  import("../components/student/notifications/Notifications")
);

const UniversityProfileComponent = loadable(() =>
  import("../components/university/profile/Profile")
);
const MyFollowersComponent = loadable(() =>
  import("../components/public/my/Followers")
);
const CreateEventsComponent = loadable(() =>
  import("../components/university/event/CreateEventsComponent")
);
const MyEventsComponent = loadable(() =>
  import("../components/university/event/ToMyEvents")
);
const EventConfirmedComponent = loadable(() =>
  import("../components/university/event/Confirmed")
);
const EventEditComponent = loadable(() =>
  import("../components/university/event/Edit")
);
const EventHaveTokenComponent = loadable(() =>
  import("../components/university/event/HaveToken")
);
const EventNoTokenComponent = loadable(() =>
  import("../components/university/event/NoToken")
);

const SettingsAndPrivacyComponent = loadable(() =>
  import("../components/university/settings-and-privacy/SettingsAndPrivacy")
);
const ExploreHighSchoolsComponent = loadable(() =>
  import("../components/university/explore-high-schools/ExploreHighSchools")
);

const ExploreHS = loadable(() =>
  import("../components/public/explore-hs/ExploreHS")
);

const HighSchoolProfileComponent = loadable(() =>
  import("../components/university/high-school-profile/HighSchoolProfile")
);
const SearchResultsUniversityComponent = loadable(() =>
  import("../components/university/search-results/SearchResults")
);

const UdentityLiveComponent = loadable(() =>
  import("../components/university/udentitylive/UdentityLive")
);
const UdentityLiveRequestTokenComponent = loadable(() =>
  import("../components/university/udentitylive/RequestToken")
);
const UdentityLiveConfirmedTokenComponent = loadable(() =>
  import("../components/university/udentitylive/ConfirmedToken")
);
const UniversityNotificationsComponent = loadable(() =>
  import("../components/university/notifications/Notifications")
);

//Counselor Components
const CounselorExploreComponent = loadable(() =>
  import("../components/counselor/explore/Explore")
);

const CounselorSearchResultsComponent = loadable(() =>
  import("../components/counselor/search-results/SearchResults")
);

const CounselorProfileComponent = loadable(() =>
  import("../components/counselor/profile/Profile")
);

const HsSetting = loadable(() =>
  import("../components/public/hs-setting/HsSetting")
);

const CounselorCalendar = loadable(() =>
  import("../components/counselor/calendar/CounselorCalendar")
);

const CounselorProfileEditComponent = loadable(() =>
  import("../components/counselor/profile/ProfileEdit")
);

const CounselorMyStudentsComponent = loadable(() =>
  import("../components/counselor/my-students/MyStudents")
);

const MyStudents = loadable(() =>
  import("../components/public/my-students/MyStudents")
);

const CounselorEventsComponent = loadable(() =>
  import("../components/counselor/events/Events")
);

const CounselorSeeAllEventsComponent = loadable(() =>
  import("../components/counselor/events/SeeAllEvents")
);

const CounselorMyStudentsProfileComponent = loadable(() =>
  import("../components/counselor/my-students/Profile")
);

const CounselorSettingsComponent = loadable(() =>
  import("../components/counselor/settings/Settings")
);

//Parent components
const ParentExploreComponent = loadable(() =>
  import("../components/parent/explore/Explore")
);

const SearchResultsParentComponent = loadable(() =>
  import("../components/parent/search-results/SearchResults")
);

const ParentSeeAllEventsComponent = loadable(() =>
  import("../components/parent/events/SeeAllEvents")
);

const ParentStudentSignup = loadable(() =>
  import("../components/student/signup/StudentSignup")
);

const ParentSignup = loadable(() =>
  import("../components/parent/signup/Signup")
);

const ParentEventsComponent = loadable(() =>
  import("../components/parent/events/Events")
);

const InviteChild = loadable(() =>
  import("../components/parent/signup/InviteChild")
);

const ParentSettingsComponent = loadable(() =>
  import("../components/parent/settings/Settings")
);

const NoMatch = loadable(() => import('components/public/NoMatch'));
const CieFair = loadable(() => import('../components/public/cie-fair/CieFair'));
const NusFair = loadable(() => import('../components/public/nus-fair/NusFair'));
const NusFair2022 = loadable(() => import('../components/public/nus-2022/NusFair'));
const NusFair2023 = loadable(() => import('../components/public/nus-2023-nov/NusFair'));
const NusFair2024 = loadable(() => import('../components/public/nus-2024/NusFair'));
const NusFair2025 = loadable(() => import('../components/public/nus-2025/NusFair'));



//End of parent components
const EventWechat = loadable(() => import('../components/public/event-wechat/EventWechat'));
const ScienceWechat = loadable(() => import('../components/public/science-wechat/ScienceWechat'));
const RankScienceWechat = loadable(() => import('../components/public/rank-science-wechat/RankScienceWechat'));
const ScienceWechatContainer = loadable(() => import('../components/public/science-wechat/ScienceWechatContainer'));
const NewEvent = loadable(() => import('../components/public/new-event/Event'));
const NewEvents = loadable(() => import('../components/public/new-events/Events'));
const NewEvents2 = loadable(() => import('../components/public/new-events2/NewEvents2'));
const NewFair = loadable(() => import('../components/public/new-fair/NewFair'));
const NewFairWrapper = loadable(() => import('../components/public/new-fair/FairWrapper'));
const OnBoarding = loadable(() => import('../components/public/new-fair/OnBoarding'));
const NyFair = loadable(() => import('../components/public/ny-fair/NyFair'));
const MayFair = loadable(() => import('../components/public/may-fair/MayFair'));
const AugustFair = loadable(() => import('../components/public/august-fair/AugustFair'));
const SepFair = loadable(() => import('../components/public/sep-fair/SepFair'));
const MayFairOnBoarding = loadable(() => import('../components/public/may-fair/OnBoarding'));
const UniServices = loadable(() => import('../components/public/uni-services/UniServices'));
const TourUniList = loadable(() => import('../components/public/tour/UniList'));
const GuideList = loadable(() => import('../components/public/tour/GuideList'));
const Guide = loadable(() => import('../components/public/tour/Guide'));
const BookConfirm = loadable(() => import('../components/public/tour/BookConfirm'));
const PaySuccess = loadable(() => import('../components/public/tour/PaySuccess'));
const MyTour = loadable(() => import('../components/public/tour/MyTour'));
const EventEdit = loadable(() => import('../components/university/event/EventEdit'));
const EventConfirm = loadable(() => import('../components/university/event/EventConfirm'));

const ActFair = loadable(() => import('../components/public/act-fair/ActFair'))
const ActFair2022 = loadable(() => import('../components/public/act-2022-fair/ActFair'))
const ActFair2023 = loadable(() => import('../components/public/2023act/Act2023'))
const ActFair2024 = loadable(() => import('../components/public/2024act/Act2024'))
const ActFair2025 = loadable(() => import('../components/public/2025act/Act2025'))
const EduFair = loadable(() => import('../components/public/edu-fair/EduFair'))
const EduFairFall = loadable(() => import('../components/public/new-edu-fair/EduFair'))
const EduFairFall2023 = loadable(() => import('../components/public/2023-sep-edu/EduFair'))
const EduFairFall2024 = loadable(() => import('../components/public/2024-sep-edu/EduFair'))
const TestIframe = loadable(() => import('../components/public/new-edu-fair/TestIframe'))
const Past = loadable(() => import('../components/public/edu-fair/Past'))

const NewSignUp = loadable(() => import('../components/public/new-signup/SignUp'));
const NewCounselorSignUp = loadable(() => import('../components/public/new-signup/CounselorSignUp'));
const UniSignUp = loadable(() => import('../components/public/new-signup/UniSignUp'));

const SignUp2022 = loadable(() => import('../components/public/2022signup/SignUp'));
const UniSignUp2022 = loadable(() => import('../components/public/2022signup/UniSignUp'));
const CounselorSignUp2022 = loadable(() => import('../components/public/2022signup/CounselorSignUp'));

const PrivateChatWechat = loadable(() => import('../components/public/mini-program/PrivateChat/PrivateChat'));
const ChatListMini = loadable(() => import('../components/public/chat-list-mini/ChatListMini'));
const MyEvents = loadable(() => import('../components/public/my-events/MyEvents'));
const ServiceAccount = loadable(() => import('../components/public/service-account/ServiceAccount'));
const MyEvent = loadable(() => import('../components/public/my-events/MyEvent'));

import PublicRoute from "../routes/public-route";
import StudentRoute from "../routes/student-route";
import UniversityRoute from "../routes/university-route";
import HighSchoolRoute from "../routes/highschool-route";
import ParentRoute from "../routes/parent-route";
import Header from '../components/public/header/Header';
import Footer from "../components/shared/public/Footer";
import userHelper from "../helpers/user-helper";
import HsEvent from "../components/public/hs-event/HsEvent";
import CieEvent from "../components/public/cie-event/CieEvent";
import Viewers from "../components/public/uni-event/Viewers";
import EventOverview from "../components/public/uni-event/EventOverview";
import highschoolService from "../services/highschool-service";
import {changeLang} from "../redux/actions";
import NewFooter from "../components/public/new-footer/NewFooter";
// import Xhs from "../components/public/xhs/Wechat";
const SignupList = loadable(() => import('../components/public/service-account/SignupList'))
const AllUsers = loadable(() => import('../components/public/service-account/AllUsers'))
const EventsOnUdentity = loadable(() => import('../components/public/new-create-event/EventsOnUdentity'))
const MyServiceAccountIntro = loadable(() => import('../components/public/service-account/MyServiceAccountIntro'))
const MyMiniProgramIntro = loadable(() => import('../components/public/service-account/MyMiniProgramIntro'))
const EventIntro = loadable(() => import('../components/public/new-create-event/EventIntro'))
const NewSolution = loadable(() => import('../components/public/new-solution/NewSolution'))
const NewHomeEvents = loadable(() => import('../components/public/new-home-events/NewHomeEvents'))
const NewAboutAs = loadable(() => import('../components/public/new-about-as/NewAboutAs'))
const OpenConsultation = loadable(() => import('../components/public/consultation/OpenConsultation'))
const ConsultationSuccess = loadable(() => import('../components/public/consultation/ConsultationSuccess'))
const ConsultationSignUps = loadable(() => import('../components/public/consultation/ConsultationSignUps'))
const UserInfo = loadable(() => import('../components/public/user-info/UserInfo'));
const HsApplication = loadable(() => import('../components/public/hs-application/HsApplication'));
const MayFair2022 = loadable(() => import('../components/public/new-may-fair/MayFair2022'));
const CollegeFair2022 = loadable(() => import('../components/public/college-fair/CollegeFair'));
const AugustHsylc = loadable(() => import('../components/public/august-hsylc/CollegeFair'));
const AugustHsylc2024 = loadable(() => import('../components/public/2024-august-hsylc/CollegeFair'));
const OpenHour = loadable(() => import('../components/public/new-may-fair/OpenHour'));
const Edu2023 = loadable(() => import('../components/public/2023edu/EduFair'));
const Edu2024 = loadable(() => import('../components/public/2024edu/EduFair'));
const PaySuccess2 = loadable(() => import('../components/public/pay-success/PaySuccess'))
const NewHome = loadable(() => import('../components/public/new-home/NewHome'));
const Home2023 = loadable(() => import('../components/public/2023home/Home20232'));
const Wechat = loadable(() => import('../components/public/wechat/Wechat'));
const Xhs = loadable(() => import('../components/public/xhs/Xhs'));
const LocalWebsite = loadable(() => import('../components/public/local-website/LocalWebsite'));
const MiniProgramServices = loadable(() => import('../components/public/mini-program-services/MiniProgramServices'));
const Bilibili = loadable(() => import('../components/public/bilibili/Bilibili'));
const Weibo = loadable(() => import('../components/public/weibo/Weibo'));
const NewAboutUs2 = loadable(() => import('../components/public/new-about-us2/NewAboutUs'));
const NewInsights = loadable(() => import('../components/public/new-insights/NewInsights'));
const UniEvent = loadable(() => import('../components/public/uni-event/UniEvent'));
const EventAnalytics = loadable(() => import('../components/public/uni-event/EventAnalytics'));
// const EventViewers = loadable(() => import('../components/public/uni-event/Viewers'));
const EventOverview2 = loadable(() => import('../components/public/uni-event/EventOverview'));
const Events2 = loadable(() => import('../components/public/events2/Events'));
const Report = loadable(() => import('../components/public/report/Report'));
const Nus2023 = loadable(() => import('../components/public/nus2023/Nus2023'));
const JulyEdu = loadable(() => import('../components/public/july-edu/JulyEdu'));
const JuneEdu = loadable(() => import('../components/public/june-edu/JuneEdu'));
const SatelliteWeb = loadable(() => import('../components/public/satellite-web/SatelliteWeb'));
const HsProfile = loadable(() => import('../components/public/hs-profile/HsProfile'));
const MarchFair = loadable(() => import('../components/public/march-fair/MarchFair'));
const SummerPrograms = loadable(() => import('../components/public/summer-program'));
const SummerProgram = loadable(() => import('../components/public/summer-program/detail'));


// Backup the original Headers class outside of the component
const OriginalHeaders = Headers;

class AppEx extends Component {

  constructor(props) {
    super(props);
    this.state = {user: null, lang: userHelper.getLang() || 'en' };
  }

  setUser = user => {
    this.setState({user});
  };

  setLang = lang => {
    this.setState({lang});
    userHelper.setLang(lang);
    this.overrideHeadersWithLang(lang);
  }

  overrideHeadersWithLang(lang) {
    Headers = function(...args) {
      const headers = new OriginalHeaders(...args);
      headers.append("x-language", lang);
      return headers;
    };
  }

  componentDidMount() {
    const user = userHelper.getUser();
    this.setUser(user);
    this.overrideHeadersWithLang(this.state.lang);

    if (!userHelper.getUuid()) {
      const tmp = uuidv4();
      userHelper.setUuid(tmp);
    }
  }

  render() {
    const setUser = this.setUser;
    const setLang = this.setLang;
    const { user, lang } = this.state;

    return (
      <IntlProvider locale={lang} messages={localeMessages[lang]} onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          return;
        }
        console.error(err);
      }}>
        <Helmet>
          <title>Udentity - Digital Outreach & Recruitment</title>
          <meta name="description" content="Digital student outreach and recruitment goes beyond Zoom and email campaigns. From virtual college fairs and open houses to WeChat campaigns and mini-programs, Udentity helps institutional users to access and connect with audiences in Asia (especially China) through our network of over 400 high schools and our tech expertise in China." />
        </Helmet>
        <div id='main-app' className="main-wrap" style={{minHeight: '100%', overflowX: 'hidden'}}>
          <UserContext.Provider value={{ user, setUser }}>
            <LangContext.Provider value={ { lang, setLang }}>
              <Router>
                <Header setUser={setUser} user={user}/>
                <Switch>
                  <Route path='/summer-programs' exact component={SummerPrograms}/>
                  <Route path='/summer-programs-for-students' exact component={SummerPrograms}/>
                  <Route path='/summer-programs/:id' exact component={SummerProgram}/>
                  <Route path='/Satellite-website-solution' exact component={SatelliteWeb}/>
                  {/*<Route path='/NUSAdmissionsOpenHouse' exact component={MarchFair}/>*/}
                  <Route path='/HS-profile' exact component={HsProfile}/>
                  <Route path='/high-school-profiles' exact component={HsProfile}/>
                  <Route path='/EducationUSApre-departure2023' exact component={JulyEdu}/>
                  <Route path='/EducationUSApre-departure2024' exact component={JuneEdu}/>
                  <Route path='/NUSCounsellorsSessions' exact component={Nus2023}/>
                  <Route path='/upcoming-fairs' exact component={UpcomingFair}/>
                  <Route path='/chinese-student-recruitment/events-fairs' exact component={UpcomingFair}/>
                  <PublicRoute path="/" exact component={Home2023} />
                  <PublicRoute path="/new-home" exact component={NewHome} />
                  <PublicRoute path="/China-International-Higher-Education-Trend-2021-22-Report" exact component={Report} />
                  <PublicRoute path="/WeChat-Official-Account-Management" exact component={Wechat} />
                  <PublicRoute path="/redbook-xiao-hong-shu-management" exact component={Xhs} />
                  <PublicRoute path="/chinese-website-development" exact component={LocalWebsite} />
                  <PublicRoute path="/WeChat-mini-program-functions-development" exact component={MiniProgramServices} />
                  <PublicRoute path="/Bilibili-video-sharing-china" exact component={Bilibili} />
                  <PublicRoute path="/bilibili-account-management" exact component={Bilibili} />
                  <PublicRoute path="/weibo" exact component={Weibo} />
                  <PublicRoute path="/insights" exact component={NewInsights} />
                  <PublicRoute path="/chinese-student-recruitment/strategy-solutions" exact component={NewInsights} />
                  <Route path='/report2021-22' exact component={PaySuccess2}/>
                  <Route path='/NUSCounsellorsSessions' exact component={Nus2023}/>
                  <Route path='/my-service-account-intro' exact component={MyServiceAccountIntro}/>
                  <Route path='/my-mini-program-intro' exact component={MyMiniProgramIntro}/>
                  <Route path='/event-intro' exact component={EventIntro}/>
                  <Route path='/event-on-udentity' component={EventsOnUdentity}/>
                  <Route path='/event/:id/signup-list' exact component={SignupList}/>
                  <Route path='/event/all-users' exact component={AllUsers}/>
                  <Route path='/hs-application' exact component={HsApplication}/>
                  <Route path='/event-wechat/:id' exact component={EventWechat}/>
                  <Route path='/private-wechat/:id' exact component={PrivateChatWechat}/>
                  <Route path='/chat-list-mini' exact component={ChatListMini}/>
                  <Route path='/science-wechat' exact component={ScienceWechat}/>
                  <Route path='/science-wechat-container' exact component={ScienceWechatContainer} />
                  <Route path='/science-rank-wechat' exact component={RankScienceWechat}/>
                  <Route path='/user-info/:id' exact component={UserInfo}/>
                  <Route path='/service-account'  component={ServiceAccount}/>
                  {/*<Route path='/open-consultation'  component={OpenConsultation}/>*/}
                  {/*<Route path='/consultation-success'  component={ConsultationSuccess}/>*/}
                  <Route path='/consultation-signups'  component={ConsultationSignUps}/>
                  <Route path='/event/:id' exact render={props => <NewEvent setUser={setUser} user={user} key={props.match.params.id}/>} />
                  <Route path='/hs-event/:id' exact render={props => <HsEvent setUser={setUser} user={user} key={props.match.params.id}/>} />
                  <Route path='/cie-event/:id' exact render={props => <CieEvent setUser={setUser} user={user} key={props.match.params.id}/>} />
                  <Route path='/tour/universities' exact component={TourUniList}/>
                  <Route path='/tour/university/:id/guides' exact component={GuideList}/>
                  <Route path='/tour/guide/:id' exact component={Guide}/>
                  <Route path='/tour/appointment/:id/confirm' exact component={BookConfirm}/>
                  <Route path='/tour/appointment/:id/pay-success' exact component={PaySuccess}/>
                  <Route path='/tour/mine' exact component={MyTour}/>
                  {/*<Route path='/my-events' exact component={MyEvents}/>*/}
                  <Route path='/my-event/:id' exact component={MyEvent}/>
                  {/*<PublicRoute path="/about-us" exact component={NewAboutUs2} />*/}
                  {/*<PublicRoute path="/new-about-us" exact component={NewAboutAs} />*/}
                  <PublicRoute path="/features" exact component={NewSolution} />
                  <PublicRoute path="/wechat-miniprogram-bilibili-weibo" exact component={MiniProgramServices} />
                  <PublicRoute path="/digital-student-recruitment" exact component={NewSolution} />
                  <PublicRoute path="/wechat-management-for-university" exact component={NewSolution} />
                  <PublicRoute path="/digital-outreach-in-china" exact component={NewSolution} />
                  {/*<Route path="/new-features" exact component={NewSolution} />*/}
                  <Route path='/events' exact render={props => <Events2 setUser={setUser} user={user} /> } />
                  <PublicRoute path='/uni-event' exact component={UniEvent} />
                  <PublicRoute path='/event-analytics' exact component={EventAnalytics} />
                  {/*<PublicRoute path='/event-viewers' exact component={EventViewers} />*/}
                  <PublicRoute path='/event-overview' exact component={EventOverview2} />
                  <Route path='/collegefairs&events' exact render={() => <Events2 setUser={setUser} user={user} /> }  />
                  <Route path='/university-admissions-information-sessions' exact render={() => <Events2 setUser={setUser} user={user} /> }  />
                  <PublicRoute path='/college-fairs-events' exact component={NewHomeEvents} />
                  <PublicRoute path='/online-virtual-university-fairs' exact component={NewHomeEvents} />
                  <PublicRoute path='/university-fairs-events-in-china' exact component={NewHomeEvents} />
                  {/*<PublicRoute path='/new-events' exact component={NewHomeEvents} />*/}
                  <PublicRoute
                    path="/reset-password"
                    exact
                    component={ForgotPassword}
                  />
                  <Route path='/actchinafair' component={() => <ActFair user={user} setUser={setUser}/>} />
                  <Route path='/ACTfair2022' component={() => <ActFair2022 user={user} setUser={setUser}/>} />
                  <Route path='/ACTfair2023' component={ActFair2023} />
                  <Route path='/ACTFair2024' component={ActFair2024} />
                  <Route path='/HS-springtour2025' component={ActFair2025} />
                  {/*<Route path='/april-fair' component={NewFair} />*/}
                  <Route path='/april-fair' component={() => <NewFair user={user} setUser={setUser}/>} />
                  <Route path='/cambridgeinternational2021' component={() => <CieFair user={user} setUser={setUser}/>} />
                  {/*<Route path='/NUS' component={() => <NusFair user={user} setUser={setUser}/>} />*/}
                  <Route path='/NUS2022' component={() => <NusFair2022 user={user} setUser={setUser}/>} />
                  <Route path='/NUS2023' component={() => <NusFair2023 user={user} setUser={setUser}/>} />
                  <Route path='/nus-2024' component={() => <NusFair2024 user={user} setUser={setUser}/>} />
                  <Route path='/nusadmissionsopenhouse' component={() => <NusFair2025 user={user} setUser={setUser}/>} />
                  <Route path='/udentityhsylc2021' component={() => <AugustFair user={user} setUser={setUser}/>} />
                  <Route path='/april-fair-onboarding' component={OnBoarding} />
                  <Route path='/new-fair-wrapper' component={NewFairWrapper} />
                  {/*<Route path='/ny-colleges' component={NyFair} />*/}
                  <Route path='/ny-colleges' component={() => <NyFair user={user} setUser={setUser}/>} />
                  {/*<Route path='/EducationUSA2021fair' component={MayFair} />*/}
                  <Route path='/EducationUSA2021fair' component={() => <SepFair user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2021shanghai' component={() => <MayFair user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2021fair-onboarding' component={MayFairOnBoarding}/>
                  <Route path='/EducationUSA2022ShanghaiFair' component={() => <EduFair user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2022Fair' component={() => <EduFairFall user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2023Shanghaifair' component={Edu2023} />
                  <Route path='/EducationUSA2023Fair' component={() => <EduFairFall2023 user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2024Fair' component={() => <EduFairFall2024 user={user} setUser={setUser}/>} />
                  <Route path='/EducationUSA2024ShanghaiFair' component={Edu2024} />
                  <Route path='/test-iframe' component={TestIframe} />
                  <Route path='/uni-viewers' component={Viewers} />
                  <Route path='/educationusapastwebinars' component={() => <Past user={user}/>} />
                  <Route path='/jiangnanfair' component={() => <MayFair2022 user={user} setUser={setUser}/>} />
                  <Route path='/open-hour/:id' component={() => <OpenHour user={user} setUser={setUser}/>} />
                  <Route path='/udentityhsylc2022' component={() => <CollegeFair2022 user={user} setUser={setUser}/>} />
                  <Route path='/udentityhsylc2023' component={AugustHsylc} />
                  <Route path='/udentityhsylc2024' component={AugustHsylc2024} />
                  <UniversityRoute exact path='/uni-services' component={UniServices} />
                  <PublicRoute path="/faqs" exact component={FAQS} />
                  <PublicRoute path="/privacy" exact component={PrivacyPage} />
                  <PublicRoute
                    path="/terms"
                    exact
                    component={TermsAndConditionsPage}
                  />

                  <PublicRoute path="/support" exact component={SupportPage} />

                  <PublicRoute
                    path="/student/signup"
                    exact
                    // component={StudentSignup}
                    // component={() => <NewSignUp setUser={setUser} />}
                    component={() => <SignUp2022 setUser={setUser} />}
                  />

                  <PublicRoute
                    path="/university/signup"
                    exact
                    // component={UniversitySignup}
                    // component={() => <UniSignUp setUser={setUser} />}
                    component={() => <UniSignUp2022 setUser={setUser} />}
                  />
                  <PublicRoute
                    path="/parent/signup"
                    exact
                    // component={ParentSignup}
                    // component={() => <NewSignUp setUser={setUser}/>}
                    component={() => <SignUp2022 setUser={setUser} />}
                  />
                  <PublicRoute
                    path="/counselor/signup"
                    exact
                    // component={CounselorSignup}
                    // component={() => <NewCounselorSignUp setUser={setUser} />}
                    component={() => <CounselorSignUp2022 setUser={setUser} />}
                  />
                  <PublicRoute
                    path="/university/signup-confirmed"
                    exact
                    component={UniversitySignupConfirmation}
                  />
                  <PublicRoute
                    path="/counselor/signup-confirmed"
                    exact
                    component={CounselorSignupConfirmation}
                  />
                  <ParentRoute
                    path="/parent/invite"
                    exact
                    component={InviteChild}
                  />
                  {/*<PublicRoute*/}
                  {/*  path="/student/invite/:id"*/}
                  {/*  component={ParentStudentSignup}*/}
                  {/*/>*/}
                  <PublicRoute path="/logout" exact component={LogoutComponent} />
                  <PublicRoute /*Counselor&University*/
                    path="/message2/:id?"
                    component={MessageComponent}
                  />
                  <PublicRoute /*Counselor&University*/
                    path="/message/:id?"
                    component={Message}
                  />
                  <StudentRoute path="/student/profile" exact component={Profile} />

                  {/*<StudentRoute*/}
                  {/*  path="/student/events"*/}
                  {/*  exact*/}
                  {/*  component={NewEvents}*/}
                  {/*/>*/}

                  <Route
                    path="/student/events"
                    render={() => <Events2 setUser={setUser} user={user} /> }
                  />
                  {/*<StudentRoute*/}
                  {/*  path="/student/events/all"*/}
                  {/*  exact*/}
                  {/*  component={StudentSeeAllEventsComponent}*/}
                  {/*/>*/}

                  {/*<PublicRoute path="/explore" exact component={Explore} />*/}
                  <PublicRoute path="/explore" exact component={NewExplore} />
                  <PublicRoute path="/international-college-search" exact component={NewExplore} />
                  <PublicRoute
                    path="/college-fair-march"
                    exact
                    component={CollegeFair}
                  />
                  <PublicRoute
                    path="/college-fair-registration"
                    exact
                    component={CollegeFairRegistration}
                  />
                  <PublicRoute
                    path="/june-fair"
                    exact
                    component={CollegeFairJune}
                  />
                  <PublicRoute path="/hs-fair" exact component={HsFair} />
                  <PublicRoute path="/hsylc-fair" exact component={HSLYCFair} />
                  <PublicRoute path="/auca-fair" exact component={AUCAFair} />
                  <PublicRoute path="/indonesia-fair" exact component={IndonesiaFair} />
                  <PublicRoute path="/educationusa2020fair" exact component={EducationUSAFair} />
                  <PublicRoute path="/cambridgeinternational2020" exact component={CIEFair} />
                  <PublicRoute path="/cambridgeinternational-onboarding" exact component={CIEOnBoarding}/>
                  <PublicRoute path="/college-fair" exact component={HSLYCFair} />

                  <PublicRoute
                    path="/june-fair进入会展页面"
                    exact
                    component={CollegeFair}
                  />
                  <PublicRoute
                    path="/june-fair%E8%BF%9B%E5%85%A5%E4%BC%9A%E5%B1%95%E9%A1%B5%E9%9D%A2"
                    exact
                    component={CollegeFair}
                  />

                  <Route
                    path="/my-universities"
                    exact
                    // component={MyUniversitiesComponent}
                    component={MyUniversities}
                  />
                  <StudentRoute path="/settings" exact component={Settings} />
                  {/*<StudentRoute path="/settings" exact component={StudentSetting} />*/}
                  <StudentRoute
                    path="/search-results"
                    exact
                    component={SearchResultsStudentComponent}
                  />
                  <StudentRoute
                    path="/student/notifications"
                    exact
                    component={StudentNotificationsComponent}
                  />
                  <UniversityRoute
                    path="/myFollowers"
                    component={MyFollowersComponent}
                  />
                  <UniversityRoute
                    path="/university/create-event"
                    component={CreateEventsComponent}
                  />
                  {/*<UniversityRoute*/}
                  {/*  path="/university/my-events"*/}
                  {/*  component={MyEventsComponent}*/}
                  {/*/>*/}
                  <UniversityRoute
                    path="/university/my-events"
                    component={MyEvents}
                  />
                  <UniversityRoute
                    path="/settings-and-privacy"
                    exact
                    component={SettingsAndPrivacyComponent}
                  />
                  <Route
                    path="/international-high-schools-china-asia-database"
                    exact
                    // component={ExploreHighSchoolsComponent}
                    component={ExploreHS}
                  />
                  <Route
                    path="/explore-high-schools"
                    exact
                    // component={ExploreHighSchoolsComponent}
                    component={ExploreHS}
                  />

                  <Route
                    path="/explore-high-schools-new"
                    exact
                    component={ExploreHS}
                  />
                  <UniversityRoute
                    path="/university/profile"
                    exact
                    component={UniversityProfileComponent}
                  />
                  <Route path='/wechat-qr' exact component={WechatQR}/>
                  <UniversityRoute
                    path="/university/notifications"
                    exact
                    component={UniversityNotificationsComponent}
                  />
                  <UniversityRoute
                    path="/university-search-results"
                    exact
                    component={SearchResultsUniversityComponent}
                  />
                  <UniversityRoute
                    path="/udentitylive"
                    exact
                    component={UdentityLiveComponent}
                  />
                  <UniversityRoute
                    path="/udentitylive/request-token"
                    exact
                    component={UdentityLiveRequestTokenComponent}
                  />
                  <UniversityRoute
                    path="/udentitylive/confirmed-token"
                    exact
                    component={UdentityLiveConfirmedTokenComponent}
                  />
                  {/*<UniversityRoute*/}
                  {/*  path="/event/:id/confirmed"*/}
                  {/*  component={EventConfirmedComponent}*/}
                  {/*/>              */}
                  <UniversityRoute
                    path="/event/:id/confirmed"
                    component={EventConfirm}
                  />
                  {/*<UniversityRoute*/}
                  {/*  path="/event/:id/edit"*/}
                  {/*  component={EventEditComponent}*/}
                  {/*/>              */}
                  <UniversityRoute
                    path="/event/:id/edit"
                    component={EventEdit}
                  />
                  <UniversityRoute
                    path="/event/:id/have-token"
                    component={EventHaveTokenComponent}
                  />
                  <UniversityRoute
                    path="/event/:id/no-token"
                    component={EventNoTokenComponent}
                  />
                  <UniversityRoute
                    path="/students/:id"
                    component={StudentProfile}
                  />

                  {/* Start Counselor Routes */}
                  <HighSchoolRoute
                    path="/highschool/explore"
                    exact
                    component={CounselorExploreComponent}
                  />
                  <HighSchoolRoute
                    path="/highschool/search-results"
                    exact
                    component={CounselorSearchResultsComponent}
                  />
                  <HighSchoolRoute
                    path="/highschool/calendar"
                    exact
                    component={CounselorCalendar}
                  />
                  <HighSchoolRoute
                    path="/highschool/profile"
                    exact
                    component={CounselorProfileComponent}
                  />
                  {/*<HighSchoolRoute*/}
                  {/*  path="/hs-setting"*/}
                  {/*  exact*/}
                  {/*  component={HsSetting}*/}
                  {/*/>*/}
                  <HighSchoolRoute
                    path="/highschool/profile/edit"
                    exact
                    component={CounselorProfileEditComponent}
                  />
                  <HighSchoolRoute
                    path="/highschool/my-students"
                    exact
                    // component={CounselorMyStudentsComponent}
                    component={MyStudents}
                  />
                  <HighSchoolRoute
                    path="/highschool/events"
                    exact
                    component={NewEvents}
                  />
                  <HighSchoolRoute
                    path="/highschool/events/all"
                    exact
                    component={CounselorSeeAllEventsComponent}
                  />

                  <HighSchoolRoute
                    path="/highschool/settings"
                    exact
                    // component={HsSetting}
                    component={CounselorSettingsComponent}
                  />
                  {/*<HighSchoolRoute*/}
                  {/*  path="/highschool/my-students/:id/profile"*/}
                  {/*  component={CounselorMyStudentsProfileComponent}*/}
                  {/*/>*/}
                  {/* End Counselor Routes */}
                  <ParentRoute
                    path="/parent/explore"
                    component={ParentExploreComponent}
                  />

                  <ParentRoute
                    path="/parent/search-results"
                    exact
                    component={SearchResultsParentComponent}
                  />

                  <ParentRoute
                    path="/parent/events"
                    exact
                    component={NewEvents}
                  />
                  <ParentRoute
                    path="/parent/events/all"
                    exact
                    component={ParentSeeAllEventsComponent}
                  />
                  <ParentRoute
                    path="/parent/settings"
                    exact
                    component={ParentSettingsComponent}
                  />
                  <PublicRoute
                    path="/university/:id"
                    component={UniversityComponent}
                  />
                  <PublicRoute
                    path="/event/:id/no-access"
                    exact
                    component={EventComponent}
                  />
                  <PublicRoute
                    path="/event/:id/no-access-counselor-exclusive"
                    exact
                    component={EventComponent}
                  />
                  {/*<PublicRoute path="/event/:id" component={EventComponent} />*/}
                  <PublicRoute path="/zoom/:id" component={ZoomEventWatch} />

                  <UniversityRoute
                    path="/highschool/:id"
                    component={HighSchoolProfileComponent}
                  />
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
                {/*<Footer />*/}
                <NewFooter />
              </Router>
            </LangContext.Provider>
          </UserContext.Provider>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return { lang: state.lang };
};

// export default connect(mapStateToProps, { changeLang })(AppEx);
export default AppEx;
