import styled, {createGlobalStyle} from "styled-components";
import importAllImg from "../../../helpers/import-all-imgs";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import LangTag from "../../shared/public/LangTag";
import { Modal, Badge, Drawer } from 'antd';
import React, {useState, useRef, useEffect, useContext} from 'react';
import useOutsideAlerter from "../new-event/useOutsideAlerter";
import fairService from "../../../services/fairs/fair-service";
import { useIntl } from 'react-intl';
import userService from "../../../services/user-service";
import UserContext from "../../../app/UserContext";

const imgs = importAllImg(require.context('../../../../src/assets/img/header', false, /\.(png|jpe?g|svg)$/));
const imgs2 = importAllImg(require.context('../../../../src/assets/img/new-solutions', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  display: flex;
  padding: 15px 30px;
  height: 84px;
  align-items: center;
  @media(max-width: 991px) {
    justify-content: space-between;
    padding: 8px 16px;
    position: relative;
    border-radius: 0px 0px 8px 8px;
    //box-shadow: 4px 4px 4px #ededed;
  }
  
  & > img {
    cursor: pointer;
  }

  //border-bottom: 1px solid #C7C7C7;
  .icons {
    display: flex;
    margin-left: auto;
    align-items: center;
    @media(max-width: 991px) {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      padding: 8px 16px;
      flex-flow: column;
      background: white;
      z-index: 4;
    }
  }
  
  .icon {
    display: flex;
    align-items: center;
    flex-flow: column;
    cursor: pointer;
    margin-left: 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #757575;
    &.selected {
      color: #1771C7;
      &.wechat {
        color: #2ec100;
        & > img {
          width: 50px;
          margin-top: -5px;
        }
        & > span {
          margin-top: -10px;
        }
      }
    }

    @media(max-width: 991px) {
      margin-left: 0;
    }
    & > img {
      width: 32px;
      margin-bottom: 5px;
    }
  }
  
  .lang-panel {
    margin-left: 25px;
    cursor: pointer;
    @media(max-width: 991px) {
      margin-left: 0;
      & > span {
        margin-left: -5px !important;
      }
    }
  }
  
  .account-icon {
    position: relative;
    & > img {
      width: 33px;
    }
  }
  
  .account-list {
    z-index: 3;
    position: absolute;
    width: 230px;
    top: 71.7px;
    right: -28px;
    //border: 1px solid #C7C7C7;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    background: white;

    font-family: 'Nunito Sans';
    font-style: normal;
    color: #757575;


    @media(max-width: 991px) {
      top: 74px;
    }
    & > div {
      padding: 20px 25px;
      border-bottom: 2px solid white;
      background: #F2F2F7;
    }
  }
  
  .badge-count {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .mobile-icon {
    display: none;
    @media(max-width: 991px) {
      display: block;
    }
  }
`;

const CustomPopover = createGlobalStyle`

  .confirm-modal {
    .ant-modal-confirm-btns {
      display: none;
    }

    @media (max-width: 991px) {
      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }

  }

  .custom-drawer {
    //margin-top: 56px;
    //.ant-drawer-mask {
    //  margin-top: 56px;
    //}
    //.ant-drawer-content-wrapper {
    //  margin-top: 56px;
    //}

    .ant-drawer-body {
      padding: 0;
    }
  }

`

function UserHeader() {

  const history = useHistory();
  const [show, setShow] = useState(false);
  const ref = useOutsideAlerter(() => setShow(false));
  const { formatMessage } = useIntl();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const timer = useRef(null);
  const { user } = useContext(UserContext);
  const [showPanel, setShowPanel] = useState(false);
  const [clicked, SetClicked] = useState(null)

  // const location = useLocation();

  useEffect(() => {
    if (showPanel) { setShowPanel(false); }
  }, [location.pathname])

  const fm = id => formatMessage({id});

  const getUnreadMessageCount = async () => {
    try {
      const totalUnreadCount = await userService.getUnreadMessageCount();
      setTotalUnreadCount(totalUnreadCount);
    } catch (e) {
      console.log("getUnreadMessageCount error", e);
    }
  }

  useEffect(() => {
    timer.current = setInterval(getUnreadMessageCount, 5000);
    return () => clearInterval(timer.current);
  }, []);

  const logoutPage = () => {
    userService.logout();
    window.location.replace('/');
  };

  // const logout = () => {
  //   const modal = Modal.info();
  //
  //   const isMobile = window.innerWidth <= 991;
  //   if (isMobile) {
  //     return modal.update({
  //       centered: true,
  //       mask: false,
  //       maskClosable: true,
  //       content:    <MobileLogoout>
  //         <div>{fm('Log out?')}</div>
  //         <div>{fm('Are you sure you want to log out?')}</div>
  //         <div className='btns'>
  //          <div onClick={logoutPage}>{fm('Log Out')}</div>
  //          <div onClick={() => modal.destroy()}>{fm('Cancel')}</div>
  //         </div>
  //       </MobileLogoout>,
  //       icon: '',
  //       className: 'confirm-modal',
  //       width: 540,
  //     })
  //   }
  //   modal.update({
  //     centered: true,
  //     mask: false,
  //     maskClosable: true,
  //     content:     <div
  //       className="container-fluid"
  //       style={{ height: "100%", overflowX: "hidden", overflowY:"hidden" }}
  //     >
  //       <div
  //         style={{ height: "100%" }}
  //         className="flex-row justify-center align-center"
  //       >
  //         <div className="flex-row flex-col justify-center logout-container">
  //           <div className="nunito-bold clr-grey font-16 text-align-ct">
  //             {formatMessage({ id: "Logoutnotice" })}
  //           </div>
  //           <div className="clr-grey font-16 margin-top-30">
  //             {formatMessage({ id: "Areusure" })}
  //           </div>
  //           <div className="flex-row justify-center margin-top-30">
  //             <button
  //               type="button"
  //               className="btn cancel-btn nunito-extra-bold"
  //               style={{backgroundColor: '#1771C7', color: "white", marginRight: 20}}
  //               onClick={() => modal.destroy()}
  //             >
  //               {formatMessage({ id: "Cancellogout" })}
  //             </button>
  //             <button
  //               type="button"
  //               className="btn logout-btn nunito-extra-bold"
  //               style={{backgroundColor: 'white', color: "#1771C7", borderColor: '#1771C7'}}
  //               onClick={logoutPage}
  //             >
  //               {formatMessage({ id: "Logoutforreal" })}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>,
  //     icon: '',
  //     className: 'confirm-modal',
  //     width: 540,
  //   })
  // };

  const logout = () => {
    const modal = Modal.info();

    return modal.update({
      centered: true,
      mask: false,
      maskClosable: true,
      content:    <MobileLogoout>
        <div>{fm('Log out?')}</div>
        <div>{fm('Are you sure you want to log out?')}</div>
        <div className='btns'>
          <div onClick={logoutPage}>{fm('Log Out')}</div>
          <div onClick={() => modal.destroy()}>{fm('Cancel')}</div>
        </div>
      </MobileLogoout>,
      icon: null,
      className: 'confirm-modal',
      width: 540,
    })
  };

  let icons = null;

  const currentPath = window.location.pathname;

  switch (user.user_type) {
    case "student":
    case "parent":
      icons = (
        <div className='icons'>
          <div
            className={`icon ${currentPath === '/international-college-search' ? 'selected' : ''}`}
            onClick={() => history.push('/international-college-search')}
          >
            <img src={currentPath === '/international-college-search' ? imgs['Magnifier1-selected.svg'] : imgs['Magnifier1.svg']} />
            {fm('University1')}
          </div>

          <div
            className={`icon ${currentPath === '/summer-programs-for-students' ? 'selected' : ''}`}
            onClick={() => history.push('/summer-programs-for-students')}
          >
            <img src={currentPath === '/summer-programs-for-students' ? imgs['cup-selected.png'] : imgs['cup.svg']} />
            {fm('Programs')}
          </div>

          <div
            className={`icon ${currentPath === '/events' ? 'selected' : ''}`}
            onClick={() => history.push('/events')}
          >
            <img src={currentPath === '/events' ? imgs['calendar3-selected.svg'] : imgs['calendar3.svg']} />
            {fm('Events1')}
          </div>

          <div
            className={`icon position-relative ${currentPath === '/message' ? 'selected' : ''}`}
            onClick={() => history.push('/message')}
          >
            <img src={currentPath === '/message' ? imgs['message1-selected.svg'] : imgs['message1.svg']} />
            {fm('Message1')}
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>

          <div className={`icon account-icon ${show ? '' : ''}`}>
            <img width={38} src={imgs['Group 2863 (1).svg']} onClick={() => setShow(i => !i)} />
            {fm('Account1')}
            <div ref={ref} className='account-list' style={{ display: show ? '' : 'none' }}>
              <div onClick={() => history.push('/my-universities')}>{fm('Saved Universities')}</div>
              <div onClick={() => history.push(user.user_type === 'student' ? '/settings' : '/parent/settings')}>{fm('Settings and Information')}</div>
              <div onClick={logout}>{fm('Log Out')}</div>
            </div>
          </div>
        </div>
      );
      break;

    case "university":
      icons = (
        <div className='icons'>
          <div
            className={`icon ${currentPath === '/university/profile' ? 'selected' : ''}`}
            onClick={() => history.push('/university/profile')}
          >
            <img src={currentPath === '/university/profile' ? imgs['approve file1-selected.svg'] : imgs['approve file1.svg']} />
            Profile
          </div>

          <div
            className={`icon ${currentPath === '/service-account/overview' ? 'selected wechat' : ''}`}
            onClick={() => history.push('/service-account/overview')}
          >
            <img src={currentPath === '/service-account/overview' ? imgs['image 2457-selected.svg'] : imgs['image 2457.svg']} />
            <span>WeChat</span>
          </div>

          <div
            className={`icon ${currentPath === '/international-high-schools-china-asia-database' ? 'selected' : ''}`}
            onClick={() => history.push('/international-high-schools-china-asia-database')}
          >
            <img src={currentPath === '/international-high-schools-china-asia-database' ? imgs['Magnifier1-selected.svg'] : imgs['Magnifier1.svg']} />
            High Schools
          </div>

          <div
            className={`icon ${currentPath === '/events' ? 'selected' : ''}`}
            onClick={() => history.push('/events')}
          >
            <img src={currentPath === '/events' ? imgs['calendar3-selected.svg'] : imgs['calendar3.svg']} />
            Events
          </div>

          <div
            className={`icon position-relative ${currentPath === '/message' ? 'selected' : ''}`}
            onClick={() => history.push('/message')}
          >
            <img src={currentPath === '/message' ? imgs['message1-selected.svg'] : imgs['message1.svg']} />
            Message
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>

          <div className={`icon account-icon ${show ? '' : ''}`}>
            <img width={38} src={imgs['Group 2863 (1).svg']} onClick={() => setShow(i => !i)} />
            Account
            <div ref={ref} className='account-list' style={{ display: show ? '' : 'none' }}>
              <div onClick={() => history.push('/uni-viewers')}>Leads</div>
              <div onClick={() => history.push('/uni-event')}>My Events</div>
              <div onClick={() => history.push('/settings-and-privacy')}>Settings and Information</div>
              <div onClick={logout}>Log Out</div>
            </div>
          </div>
        </div>
      );
      break;

    case "counselor":
      icons = (
        <div className='icons'>
          <div
            className={`icon ${currentPath === '/highschool/profile' ? 'selected' : ''}`}
            onClick={() => history.push('/highschool/profile')}
          >
            <img src={currentPath === '/highschool/profile' ? imgs['approve file1-selected.svg'] : imgs['approve file1.svg']} />
            {fm('Profile1')}
          </div>

          <div
            className={`icon ${currentPath === '/service-account/overview' ? 'selected wechat' : ''}`}
            onClick={() => history.push('/service-account/overview')}
          >
            <img src={currentPath === '/service-account/overview' ? imgs['image 2457-selected.svg'] : imgs['image 2457.svg']} />
            <span>{fm('WeChat1')}</span>

          </div>

          <div
            className={`icon ${currentPath === '/international-college-search' ? 'selected' : ''}`}
            onClick={() => history.push('/international-college-search')}
          >
            <img src={currentPath === '/international-college-search' ? imgs['Magnifier1-selected.svg'] : imgs['Magnifier1.svg']} />
            {fm('University1')}
          </div>

          <div
            className={`icon ${currentPath === '/summer-programs-for-students' ? 'selected' : ''}`}
            onClick={() => history.push('/summer-programs-for-students')}
          >
            <img src={currentPath === '/summer-programs-for-students' ? imgs['cup-selected.png'] : imgs['cup.svg']} />
            {fm('Programs')}
          </div>

          <div
            className={`icon ${currentPath === '/events' ? 'selected' : ''}`}
            onClick={() => history.push('/events')}
          >
            <img src={currentPath === '/events' ? imgs['calendar3-selected.svg'] : imgs['calendar3.svg']} />
            {fm('Events1')}
          </div>

          <div
            className={`icon position-relative ${currentPath === '/message' ? 'selected' : ''}`}
            onClick={() => history.push('/message')}
          >
            <img src={currentPath === '/message' ? imgs['message1-selected.svg'] : imgs['message1.svg']} />
            {fm('Message1')}
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>

          <div className={`icon account-icon ${show ? '' : ''}`}>
            <img width={38} src={imgs['Group 2863 (1).svg']} onClick={() => setShow(i => !i)} />
            {fm('Account1')}
            <div ref={ref} className='account-list' style={{ display: show ? '' : 'none' }}>
              <div onClick={() => history.push('/highschool/my-students')}>{fm('My Students')}</div>
              <div onClick={() => history.push('/my-universities')}>{fm('Saved Universities')}</div>
              <div onClick={() => history.push('/highschool/settings')}>{fm('Settings and Information')}</div>
              <div onClick={logout}>{fm('Log Out')}</div>
            </div>
          </div>
        </div>
      );
      break;
  }


  let mobileIcons = null;
  switch (user.user_type) {
    case "student":
    case "parent":
      mobileIcons = (
        <div className='icons'>
          <div className='icon' onClick={() => history.push('/international-college-search')}>
            <img src={imgs['Magnifier1.svg']} />
            {fm('University Database')}
          </div>

          <div className='icon' onClick={() => history.push('/events')}>
            <img src={imgs['calendar3.svg']} />
            {fm('University Events')}
          </div>
          <div className='icon' onClick={() => history.push('/summer-programs-for-students')}>
            <img src={imgs['cup.svg']} />
            {fm('Summer Programs')}
          </div>

          <div className='icon position-relative' onClick={() => history.push('/message')}>
            <img src={imgs['message1.svg']} />
            {fm('Messages')}
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>
          <div className='icon account-icon' style={{color: show ? '': ''}} onClick={() => setShow(i => !i)} >
            <img src={show ? imgs['account3.svg'] : imgs['account3.svg']} />
            {fm('Account1')}
            <img src={imgs['Group 736.svg']} />
          </div>
          <div className='account-list' style={{display: show ? '' : 'none'}}>
            <div onClick={() => history.push('/my-universities')}>{fm('Saved Universities')}</div>
            <div onClick={() => history.push(user.user_type === 'student' ?  '/settings' : '/parent/settings')}>{fm('Settings')}</div>
            <div onClick={logout}>{fm('Log Out')} </div>
          </div>

        </div>
      );
      break;
    case "university":
      mobileIcons = (
        <div className='icons'>
          <div className='icon' onClick={() => history.push('/university/profile')}>
            <img src={imgs['approve file1.svg']} />
            University Profile
          </div>

          <div className='icon' onClick={() => history.push('/international-high-schools-china-asia-database')}>
            <img src={imgs['Magnifier1.svg']} />
            High School Search
          </div>

          <div className='icon' onClick={() => history.push('/events')}>
            <img src={imgs['calendar3.svg']} />
            Recruitment Events
          </div>

          <div className='icon position-relative' onClick={() => history.push('/message')}>
            <img src={imgs['message1.svg']} />
            Messages
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>

          {/*<div className='icon' onClick={() => history.push('/service-account/overview')}>*/}
          {/*  <img src={imgs['image 2390.svg']} />*/}
          {/*  WeChat@Udentity*/}
          {/*</div>*/}

          <div className='icon account-icon' style={{color: show ? '': ''}} onClick={() => setShow(i => !i)} >
            <img src={show ? imgs['account3.svg'] : imgs['account3.svg']} />
            Account
            <img src={imgs['Group 736.svg']} />
          </div>
          <div className='account-list' style={{display: show ? '' : 'none'}}>
            <div onClick={() => history.push('/uni-viewers')}>Leads</div>
            <div onClick={() => history.push('/uni-event')}>My Events</div>
            <div onClick={() => history.push('/settings-and-privacy')}>Settings</div>
            <div onClick={logout}>Log Out </div>
          </div>

        </div>
      );
      break;
    case "counselor":
      mobileIcons = (
        <div className='icons'>
          <div className='icon' onClick={() => history.push('/highschool/profile')}>
            <img src={imgs['approve file1.svg']} />
            {fm('High School Profile')}
          </div>

          <div className='icon' onClick={() => history.push('/international-college-search')}>
            <img src={imgs['Magnifier1.svg']} />
            {fm('University Database')}
          </div>

          <div className='icon' onClick={() => history.push('/events')}>
            <img src={imgs['calendar3.svg']} />
            {fm('University Events')}
          </div>

          <div className='icon' onClick={() => history.push('/summer-programs-for-students')}>
            <img src={imgs['cup.svg']} />
            {fm('Summer Programs')}
          </div>


          <div className='icon position-relative' onClick={() => history.push('/message')}>
            <img src={imgs['message1.svg']} />
            {fm('Messages')}
            {totalUnreadCount > 0 && (
              <div className="badge-count">
                <Badge count={totalUnreadCount} />
              </div>
            )}
          </div>

          <div className='icon account-icon' style={{color: show ? '': ''}} onClick={() => setShow(i => !i)} >
            <img src={show ? imgs['account3.svg'] : imgs['account3.svg']} />
            {fm('Account1')}
            <img src={imgs['Group 736.svg']} />
          </div>
          <div className='account-list' style={{display: show ? '' : 'none'}}>
            <div onClick={() => history.push('/highschool/my-students')}>{fm('My Students')}</div>
            <div onClick={() => history.push('/my-universities')}>{fm('Saved Universities')}</div>
            <div onClick={() => history.push('/highschool/settings')}>{fm('Settings and Information')}</div>
            <div onClick={logout}>{fm('Log Out')}</div>
          </div>

        </div>
      );
      break;
  }

  const isDesktop = window.innerWidth > 991;

  return (
    <Container>
      <CustomPopover />
      <img src={imgs['Logo.svg']} onClick={() => window.location.replace('/') }/>
      {
        user.user_type !== 'university' &&
        <div className='lang-panel'>
          <LangTag />
        </div>
      }

      <img className='mobile-icon' src={imgs2['Vector2.svg']} onClick={() => setShowPanel(i => !i)}/>

      {(isDesktop) && icons}
      <Drawer
        width='280'
        className='custom-drawer'
        placement='left'
        visible={showPanel}
        closable={false}
        onClose={() => setShowPanel(false)}
      >
        <MobileContainer>
          <div className='mobile-top-icon-panel'>
            <img src={imgs['Logo (2).svg']} />
          </div>
          { mobileIcons }
        </MobileContainer>
      </Drawer>
    </Container>
  )
}

const MobileLogoout = styled.div`
  & > div:nth-child(1) {
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }

  & > div:nth-child(2) {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      justify-content: center;
    }
    column-gap: 15px;
    & > div:nth-child(1) {
      width: 136.305px;
      height: 38.885px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
      background: var(--Brand-Color-Blue, #1771C7);
    }

    & > div:nth-child(2) {
      width: 136.305px;
      height: 38.885px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: #757575;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border: 2px solid #1771C7;
      cursor: pointer;
      background: var(--white, #FFF);
    }

  }
`;

const MobileContainer = styled.div`
  .mobile-top-icon-panel {
    padding: 15px 20px;
    border-bottom: 1px solid #CCCCCC;
  }
  .icons {
    padding: 15px;
  }
  .icon {
    display: flex;
    align-items: center;
    column-gap: 24px;
    & > img {
      //width: 24px;
    }
    margin-bottom: 15px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #1C1C1E;

  }

  .account-icon {
    position: relative;
  }

  .account-list {
    //position: absolute;
    //bottom: -132px;
    margin-left: 45px;
    //color: #212529;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #1C1C1E;
    & > div {
      margin-bottom: 15px;
    }
  }
`



export default UserHeader;
