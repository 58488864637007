import React, { useState, useEffect } from 'react';
import { Spin, Modal } from 'antd';
import styled from "styled-components";
import _ from 'lodash';
import usePublicChat from "../new-event/usePublicChat";
import importAllImg from '../../../helpers/import-all-imgs';
import Modals from "../modals/Modals";
import PrivateChat from '../new-event/PrivateChat';
import ChatContent from "../new-event/ChatContent";
import ToTop from "../new-event/ToTop";
import { useIntl } from 'react-intl';
import userService from "../../../services/user-service";
import { useParams } from "react-router-dom";
import moment from "moment";

const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));
const { confirm } = Modal;

const Panel1 = styled.div`
  display: flex;
  width: 90%;
  margin: 20px auto;
  
  & > div:nth-child(1) {
    flex: 1.8;
    position: relative;
    & > div:nth-child(1) {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
    }
  }

  .emoji-mart-preview {
    display: none;
  }
`;

const Panel2 = styled.div`
  width: 90%;
  margin: 10px auto;
  & > div:nth-child(1) {
    font-size: 16px;
    color: #000;
    @media(max-width: 991px) {
      font-size: 12px;
    }
  }

  & > div:nth-child(2) {
    color: #000;
    font-size: 26px;
    @media(max-width: 991px) {
      font-size: 16px;
    }
  }
`;

const ChatContainer = styled.div`
  margin-left: 5px;
  flex: 1;
  border: 1px solid #BDBDBD;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
`;

const TabContainer = styled.div`
  display: flex;
  background: #F2F2F7;
  & > div {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 1px;
    cursor: pointer;
    transition: all 0.5s ease-in;
    font-weight: bold;
    font-size: 12px;
    & > img {
      width: 30px;
      margin: 5px 0;
    }
  }
  
  & > div:nth-child(1) {
    border-bottom: 5px solid ${props => props.tab === 0 ? '#1771C7' : '#C4C4C4'};
    color: ${props => props.tab === 0 ? '#1771C7' : '#C4C4C4'};
    margin-right: 5px;
  }

  & > div:nth-child(2) {
    border-bottom: 5px solid ${props => props.tab === 0 ? '#C4C4C4' : '#1771C7'};
    color: ${props => props.tab === 0 ? '#C4C4C4' : '#1771C7'};
  }
`;

const TabContent = styled.div`
  overflow-y: auto;
  flex: 1;
  //use visibility and not display none in order to scrollIntoView work
  visibility: ${props => props.selected ? 'visible' : 'hidden'};
  max-height: ${props => props.selected ? 'none': 0 };
  display: flex;
  flex-flow: column nowrap;
`;

const VideoPanel = styled.div`
  position: relative;
  & > div:nth-child(1) {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
  }
`;


function HsEvent({user, setUser}) {

  const { basicInfo: basicInfoTmp, event, ws, list, loadEvents } = usePublicChat(false);
  const [basicInfo, setBasicInfo] = useState({});
  const [tab, setTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();
  const { id } = useParams();

  useEffect(() => {
    if (user && event) {
      userService.getUserBasicInfo().then(res => {
        let { data } = res;
        if (_.isEmpty(basicInfo)) {
          setBasicInfo(data);
          const bindData = {
            action: "Bind",
            data: {
              mimeType: "json",
              content: {
                senderId: data.id,
                chatRoom: "group",
                roomId: event.id,
                avatar:
                  data && data.logo
                    ? data.logo
                    : "https://udentity.oss-cn-hangzhou.aliyuncs.com/2755/202009261456_avatar.jpg",
                name: data.displayName,
                userType: data.type,
              },
            },
          };
          ws.send(JSON.stringify(bindData));
        }
      });
    }
  }, [user, event]);

  useEffect(() => {
    if (_.isEmpty(basicInfo)) {
      setBasicInfo(basicInfoTmp);
    }
  }, [basicInfoTmp]);

  useEffect(() => {
    userService.eventLog(id).then(res => {});
    const fn = () => {
      confirm({
        title: '检测到网络状态由离线转为在线',
        content: '需要刷新页面才能正常使用聊天室，是否刷新',
        onOk() {
          location.reload();
        },
        onCancel() {},
      });
    }
    window.addEventListener('online', fn);
    return () => window.removeEventListener('online', fn);
  }, []);


  const notLogin = () => {
    if (_.isEmpty(basicInfo)) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  const sendMsg = message => {
    if (notLogin()) return;
    const sendData = {
      action: "Message",
      data: {
        mimeType: "text",
        content: message,
      },
    };
    ws.send(JSON.stringify(sendData));
  };


  const handleTab = tab => {
    if (notLogin()) return;
    setTab(tab);
  }


  if (!_.isObject(event)) {
    return (
      <div style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spin />
      </div>
    );
  }

  const formatDate = () => {
    const date = moment(event.dateTime);
    return `${date.date()} ${date.format('MMMM')} ${date.year()} · ${date.format('HH:mm')} (UTC +8)`;
  };


  const iframePanel = (
      <VideoPanel id='desktop-player-container'>
        { _.isEmpty(basicInfo) && <div onClick={() => setShowModal(true)}/> }
        <iframe
          id="desktop-player"
          width='100%'
          height={($('#desktop-player-container').width() || 0) * 0.5628}
          loading="lazy"
          src={`//live.udty.tech/watch/${event.vendorAssnId}?player`}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          controls
          scrolling="no"
        />
      </VideoPanel>
  );

  const chatPanel = (
    <>
      <TabContainer tab={tab}>
        <div onClick={() => handleTab(0)}>
          <img src={tab === 0 ? imgs['Group 1233.svg'] : imgs['Group 1233(1).svg']} />
          {formatMessage({id: 'publicchat'})}
        </div>
        {/*{ basicInfo.userType !== 'university' && ![923, 944].includes(event.id) && (*/}
        {/*  <div onClick={() => handleTab(1)}>*/}
        {/*    <img src={tab === 1 ? imgs['Vector (Stroke)(1).svg'] : imgs['communication.svg']} height='30px'/>*/}
        {/*    {formatMessage({id: 'privatechat'})}*/}
        {/*  </div>*/}
        {/*)}*/}
      </TabContainer>
      <TabContent selected={tab === 0}>
        <ChatContent list={list} basicInfo={basicInfo} sendMsg={sendMsg} loadEvents={loadEvents}/>
      </TabContent>
      {/*{ basicInfo.userType !== 'university' && tab === 1 && ![923, 944].includes(event.id) &&(*/}
      {/*  <TabContent selected={tab === 1}>*/}
      {/*    <PrivateChat basicInfo={basicInfo} event={event}/>*/}
      {/*  </TabContent>*/}
      {/*)}*/}
    </>
  );

  if (window.innerWidth <= 991) {
    return (
      <>
        { iframePanel }

        <Panel2>
          <div>
            {formatDate()}
          </div>
          <div>
            {event.title}
          </div>
        </Panel2>
        <ChatContainer style={{height: '550px', margin: 0, border: 'none'}}>
          { chatPanel }
        </ChatContainer>

        <Modals setUser={setUser} show={showModal} setShow={setShowModal} t='signup'/>

        <ToTop />

      </>
    );
  }

  return (
    <>
      <Panel1>

        { iframePanel }

        <ChatContainer style={{height: ($('#desktop-player-container').width() || 0) * 0.5628}}>
          { chatPanel }
        </ChatContainer>

      </Panel1>

      <Panel2>
        <div>
          {formatDate()}
        </div>
        <div>
          {event.title}
        </div>
      </Panel2>

      <Modals setUser={setUser} show={showModal} setShow={setShowModal} t='signup'/>
    </>
  );
}

export default HsEvent;
