import React, { useState, useEffect, useRef } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import {injectIntl, useIntl} from "react-intl";
import styled from "styled-components";
import loadable from "@loadable/component";
import { Select, message } from "antd";

import VerificationTimer from "./VerificationTimer";
import InputCode from "./InputCode";

import userService from "../../../../services/user-service.js";
import userHelper from "../../../../helpers/user-helper.js";
import eventService from "../../../../services/student/event-service.js";

import { connect } from "react-redux";
import { udentityLogo } from "../../../../helpers/images-helper.js";
import { displayBanner } from "../../../../redux/actions";
import publicPages from "../../../public/publicPages";
import { useHistory } from 'react-router-dom';
import AddInfoModal from "../../../public/modals/AddInfoModal";

const EMAILPHONE_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)|(^\d{8,11}$)|(^(\+84|\+86|\+65|\+66|\+62)(\d{8,11})$)/;
const scrollToRef = (ref) => {
  ref.current && window.scrollTo(0, ref.current.offsetTop);
};
const { Option } = Select;

const StyledLogin = styled.div`

  button:disabled {
    cursor: not-allowed !important;
  }

  form .ant-select {
    @media (max-width: 991px) {
      width: auto;
    }
  }
  .mobile-phone {
    @media (max-width: 991px) {
      max-width: 278px;
    }
  }
  .mobile-new {
    display: none;
    @media (max-width: 991px) {
      display: block;
      margin-top: 15px;
      & > div:nth-child(1) {
        color: #F6821F;
        margin-bottom: 5px;
        cursor: pointer;
      }
      & > div:nth-child(2) {
        color: #1771C7;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .login-with-phone {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    width: 19vw;
    margin: 5px auto;
    &:hover {
      color: rgb(23, 113, 199);
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    & > a {
      color: #757575 !important;
      &:hover {
        color: rgb(23, 113, 199) !important;
        opacity: 1;
        text-decoration: none;
      }
    }
  }
  .login-title {
    width: 19vw;
    margin: 15px auto;
    color: #757575;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 991px) {
      width: 100%;
      text-align: center;
    }
  }

  .login-text {
    text-transform: capitalize;
    @media (max-width: 991px) {
      width: 100%;
      text-align: left;
      font-size: 16px;
    }
  }
  .phone-login-title {
    font-size: 1.2vw;
    font-weight: bold;
    color: #0f1c70;
  }

  .phone-number-input {
    margin: auto 0px;
  }

  .ant-select {
    height: ${(props) => `${props.phoneInputHeight}px`};
  }
  .bottom-panel {
    .reset-password {
      color: #757575 !important;
    }
    @media (max-width: 991px) {
      flex-flow: column;
      align-items: center;
    }
  }
  
  .box {
    @media (max-width: 991px) {
      width: 350px;
      height: 350px;
    }
 
  }
  
  .fit-mobile-img {
    width: 180px;
    height: 40px;
    @media (max-width: 991px) {
      width: 160px;
      height: 35px;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  @media only screen and (min-width: 991px) {

    
    .box {
      min-height: 50vh;
      width: 24vw;
    }

    input,
    button,
    .signup-content-width {
      width: 19vw;
    }

    .bottom-section-font {
      font-size: 0.8vw;
    }

    .box-button-font {
      font-size: 1vw;
    }
    .bottom-section {
      margin: 0px 3vw;
    }

    .phone-login-title {
      font-size: 1.2vw;
    }

    .phone-number-input {
      width: auto;
    }

    .ant-select {
      width: 70px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) {
    .box,
    input,
    button,
    .forgot-password-section {
      width: 85vw;
      font-size: 2.5vmax;
    }

    input,
    button,
    .forgot-password-section {
      width: 278px;
      height: 43px;
      font-size: 16px !important;
      line-height: 22px !important;
    }

    .box-button-font {
      font-size: 2.5vmax;
    }

    .bottom-section-font {
      font-size: 1.8vmax;
    }

    .bottom-section {
      margin: 0px 3vw;
    }

    .phone-login-title {
      font-size: 2.5vmax;
    }
    .phone-number-input {
      width: 55vw;
    }
    .signup-content-width {
      width: 85vw;
    }
    .ant-select {
      font-size: 2.5vmax;
    }
  }
  
  .login-input {
    & > input {
      border: none;
      background: white !important;
      border-bottom: 2px solid #B3B3B3;;
      border-radius: 0 !important;
      &::placeholder {
        color: #B3B3B3;
      }
    }
  }

  .show-mobile {
    //display: none;
    margin-right: -100%;
    margin-bottom: 50px;
    @media (max-width: 991px) {
      //display: block;
      margin-right: auto;
      margin-bottom: 0px;
    }
  }

  .login-wrapper {
    margin-left: 15px;
    //margin-bottom: -15px;
    @media (max-width: 991px) {
      //display: block;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
`;

const LoginInModal = (props) => {
  const [loginname, setloginname] = useState("");
  const [password, setpassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [redirect, setredirect] = useState(false);
  const [activityIdState, setactivityIdState] = useState(null);
  const [error, seterror] = useState("");
  const [state, setstate] = useState({
    loginname,
    redirect,
    password,
    error,
  });
  const [loginPage, setloginPage] = useState(1);
  const [countryCode, setCountryCode] = useState("+86");
  const [elapsed, setelapsed] = useState(0);
  const [displayTimer, setdisplayTimer] = useState(false);
  const [phoneInputHeight, setphoneInputHeight] = useState(0);
  const history = useHistory();
  const [addInfo, setAddInfo] = useState(false);
  const [tmpUser, setTmpUser] = useState({});

  const submitButton = useRef(null);

  const myRef = useRef(null);
  const {
    intl: { formatMessage },
  } = props;

  useEffect(() => {
    !displayTimer && loginPage !== 1 && setloginPage(loginPage - 1);
    publicPages.PUBLIC_PAGES.filter((option) => {
      option.url === location.href.pathname;
    });
  }, [props.backToggle, displayTimer]);

  useEffect(() => {
    loginPage === 3 && password.length === 4 && submitButton.current.click();
  }, [password]);

  useEffect(() => {
    if (loginPage !== 3) {
      setpassword("");
      setloginname("");
      setphoneNumber("");
    }
    if(loginPage === 2 ){
        setphoneInputHeight($(".phone-number-input").outerHeight()-10);

    }
    if (loginPage !== 1) {
      props.displayBackButton(true);
    } else {
      props.displayBackButton(false);
    }
    seterror("");
  }, [loginPage]);

  const changeToSignUp = () => {
    props.updateState("signup");
  };

  const countryCodeChange = (value) => {
    setCountryCode(value);
  };

  const [time, setTime] = useState(60);
  const timer = useRef(null);
  useEffect(() => {
    return () => clearInterval(timer.current);
  }, [timer]);
  const captchaButtonRef = useRef(null);
  const countryCodeRef = useRef(countryCode);
  const phoneNumberRef = useRef(phoneNumber);

  const fm = id => formatMessage({id});

  useEffect(() => {
    countryCodeRef.current = countryCode;
  }, [countryCode]);

  useEffect(() => {
    phoneNumberRef.current = phoneNumber;
  }, [phoneNumber]);

  let captchaInstance = null;
  const getInstance = (instance) => {
    captchaInstance = instance;
  };

  const captchaVerifyCallback = async (captchaVerifyParam) => {
    const num = phoneNumberRef.current;

    try {
      const res = await userService.sendVerificationCode2({
        emailOrPhoneNumber: num,
        countryCode: countryCodeRef.current,
        captchaVerifyParam,
      });
      if (res.code === 200) {
        setloginPage(3)
        timer.current = setInterval(() => {
          setTime((t) => {
            if (t === 0) {
              clearInterval(timer.current);
              timer.current = null;
              return 60;
            }
            return t - 1;
          });
        }, 1000);
        return {
          captchaResult: true,
          bizResult: true,
        };
      } else {
        message.error(fm( res.msg));
        return {
          captchaResult: false,
          bizResult: false,
        };
      }
    } catch (error) {
      message.error(fm('get code error, please get new code'));
      return {
        captchaResult: false,
        bizResult: false,
      };
    }
  };

  const onBizResultCallback = () => {
    console.log('Aliyun onBizResultCallback: business logic done');
  };

  useEffect(() => {
    if (typeof window.initAliyunCaptcha === 'function') {
      window.initAliyunCaptcha({
        SceneId: '1vr57dne',
        prefix: '1hiuzy',
        mode: 'popup',
        element: '#captcha-element',
        button: '#captcha-button',
        captchaVerifyCallback,
        onBizResultCallback,
        getInstance,
        slideStyle: {
          width: 360,
          height: 40,
        },
        language: 'cn',
      });
    }

    return () => {
      window.document.getElementById('aliyunCaptcha-mask')?.remove();
      window.document.getElementById('aliyunCaptcha-window-popup')?.remove();
    };
  }, []);

  const sendVerificationCode = async () => {
    console.log('sendVerificationCode', 111)
    props.setLoadingText("Getting verification code");
    props.setLoadingState(true);
    if (!phoneNumber) {
      return seterror('Phone number is Required')
    }
    try {
      captchaButtonRef.current?.click();
      // await userService.sendVerificationCodeLogin(phoneNumber, countryCode);
      // props.setLoadingState(false);
      return true;
    } catch (e) {
      props.setLoadingState(false);
      Array.isArray(e)
        ? seterror(e[0].msg.toLowerCase())
        : seterror(e.toLowerCase());
      return false;
    }
  };

  const getCode = sendVerificationCode;

  const setErrorMessage = (value) => {
    seterror(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setLoadingState(true);
    props.setLoadingText("Logging in");
    const activityId = props.activityId ? props.activityId : null;
    setactivityIdState(activityId);
    const destination = props.destination ? props.destination : null;
    if (
      (loginname.match(EMAILPHONE_REGEX) != null && loginPage === 1) ||
      loginPage === 3
    ) {
      let loginBody =
        loginPage === 3
          ? {
              // CHANGE THIS
              loginname: phoneNumber,
              password,
              mode: "verification_code",
              eventVendorId: activityId,
              countryCode: countryCodeRef.current,
            }
          : { loginname, password, eventVendorId: activityId };
      userService
        .login(loginBody)
        .then(function (response) {
          props.setLoadingState(false);
          props.displayBanner(true);
          // const reloads = ['/april-fair', '/ny-colleges', '/EducationUSA2021fair'];
          // if (/\/event\/\d+/.test(location.pathname) || reloads.includes(location.pathname)) {
          //   setTimeout(() => window.location.reload(), 500);
          // }
          const {user_type, verified} = response;
          if ((user_type === 'student' || user_type === 'parent') && !verified) {
            setTmpUser(response);
            setAddInfo(true);
            return;
          }
          response && userHelper.setUser(response);
          props.setUser && props.setUser(response);
          if (response.user_id || response.auth_token) {
            if (activityId && !props.hsFair) {
              eventService
                .signUpEventWithVendorId({ id: activityId })
                .then((response) => {
                  window.location.replace(
                    `${location.protocol}//${location.host}/szb_redirect?event_id=${activityId}`
                  );
                })
                .catch(function (error) {
                  setredirect(true);
                  props.setLoadingState(false);
                  console.log("UpcomingEvents::ERROR ", error);
                });
            }
            // For the HS fair
            else if (activityId && props.hsFair) {
              window.location.replace(`${location.href}`);
              props.setLoadingState(false);
            }
            //END OF For the HS fair
            else {
              setredirect(true);
              props.setLoadingState(false);
            }
          } else if (response.error) {
            if (Array.isArray(response.error)) seterror(response.error[0].msg);
            else seterror(response.error);
            props.setLoadingState(false);
          }
        })
        .catch(function (error) {
          props.setLoadingState(false);
          seterror(error.msg ? error.msg : error);
          console.log("ERROR: ", error);
        });
    } else {
      seterror(formatMessage({ id: "typeEmailOrPhone" }));
      props.setLoadingState(false);
    }
  };

  const user = userHelper.getUser();

  if (redirect && user) {
    if (activityIdState) {
      window.location.replace(
        `${location.protocol}//${location.host}/szb_redirect?event_id=${activityIdState}`
      );
    } else {
      switch (user.user_type) {
        case "student":
          if (
            publicPages.PUBLIC_PAGES.filter(
              (option) => option.url === location.pathname
            ).length != 0
          ) {
            return <Redirect to={{ pathname: "/international-college-search", state: {} }} />;
          } else {
            return <Redirect to={{ pathname: location.pathname, state: {} }} />;
          }
        case "university":
          if (user.verified) {
            if (props.destination === "zoom_event") {
              return (
                <Redirect
                  to={{ pathname: "/university/create-event", state: {} }}
                />
              );
            }
            if (
              publicPages.PUBLIC_PAGES.filter(
                (option) => option.url === location.pathname
              ).length != 0
            ) {
              return (
                <Redirect
                  to={{ pathname: "/international-high-schools-china-asia-database", state: {} }}
                />
              );
            } else {
              return (
                <Redirect to={{ pathname: location.pathname, state: {} }} />
              );
            }
          } else {
            return (
              <Redirect
                to={{ pathname: "/university/signup-confirmed", state: {} }}
              />
            );
          }

        case "parent":
          if (user.verified) {
            if (
              publicPages.PUBLIC_PAGES.filter(
                (option) => option.url === location.pathname
              ).length != 0
            ) {
              return (
                <Redirect to={{ pathname: "/parent/explore", state: {} }} />
              );
            } else {
              return (
                <Redirect to={{ pathname: location.pathname, state: {} }} />
              );
            }
          }
        case "counselor":
          if (user.verified) {
            if (
              publicPages.PUBLIC_PAGES.filter(
                (option) => option.url === location.pathname
              ).length != 0
            ) {
              return (
                <Redirect to={{ pathname: "/highschool/explore", state: {} }} />
              );
            } else {
              return (
                <Redirect to={{ pathname: location.pathname, state: {} }} />
              );
            }
          } else {
            return (
              <Redirect
                to={{ pathname: "/counselor/signup-confirmed", state: {} }}
              />
            );
          }
        default:
          break;
      }
    }
  }

  const returnLoginModal = () => {
    return (
      <>
        <div style={{display: 'none'}} ref={captchaButtonRef} id="captcha-button">
          Trigger Aliyun Captcha
        </div>
        <div id="captcha-element" style={{display: 'none'}}></div>
        {
          addInfo && <AddInfoModal show={addInfo} setShow={setAddInfo} setUser={props.setUser} tmpUser={tmpUser}/>
        }

        <StyledLogin phoneInputHeight={phoneInputHeight}>
          <form onSubmit={handleSubmit}>
            {loginPage === 3 && (
              <button
                type="submit"
                style={{display: "none"}}
                ref={submitButton}
              ></button>
            )}

            <div className="login-title login-input mt-3 show-mobile">
              <img
                className='fit-mobile-img'
                style={{
                  visibility: `${loginPage === 3 ? "hidden" : "visible"}`,
                }}
                src={udentityLogo}
              ></img>
            </div>
            <div className='login-title login-text login-wrapper'>
              {formatMessage({id: 'login'})}
            </div>

            {loginPage === 1 ? (
              <div className="login-input">
                <input
                  ref={myRef}
                  id="loginname"
                  onFocus={() => {
                    scrollToRef(myRef);
                  }}
                  style={{background: '#F2F2F7', borderRadius: '5px'}}
                  type="text"
                  name="loginname"
                  placeholder={formatMessage({id: "EmailOrPhone"})}
                  value={loginname}
                  onChange={(e) => setloginname(String(e.target.value).trim())}
                  required
                />
                <input
                  type="password"
                  placeholder={formatMessage({id: "Password"})}
                  style={{background: '#F2F2F7', borderRadius: '5px'}}
                  value={password}
                  onFocus={() => {
                    scrollToRef(myRef);
                  }}
                  onChange={(e) => setpassword(String(e.target.value).trim())}
                  required
                />
              </div>
            ) : loginPage === 2 ? (
              <div className="d-flex justify-center mt-3 mobile-phone">
                <div className="d-flex align-items-center signup-content-width ">
                  <Select defaultValue={countryCode} onChange={countryCodeChange}>
                    <Option value="+86">+86</Option>
                    <Option value="+84">+84</Option>
                    <Option value="+65">+65</Option>
                    <Option value="+66">+66</Option>
                    <Option value="+62">+62</Option>
                    <Option value="+63">+63</Option>
                    <Option value="+60">+60</Option>

                    {/* <Option value="+370">+370</Option> */}
                  </Select>
                  <input
                    className="phone-number-input w-100"
                    style={{
                      backgroundColor: "#fcfcfc",
                      background:
                        "linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)) 5px calc(100% - 5px) / calc(100% - 10px) 1px no-repeat",
                      // padding: "10px"
                    }}
                    type="phoneNumber"
                    value={phoneNumber}

                    placeholder={formatMessage({
                      id: "typeInPhoneNumber",
                    })}
                    maxLength="13"
                    onFocus={() => {
                      scrollToRef(myRef);
                    }}
                    onChange={(e) => {
                      if (/[^0-9]/.test(e.target.value)) return;
                      else {
                        setphoneNumber(`${e.target.value}`.trim());
                      }
                    }}
                    required
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="d-flex justify-center">
                  <div className="phone-login-title text-center signup-content-width">
                    {" "}
                    {formatMessage({id: "pleaseEnterVerification"})}
                  </div>
                </div>
                <div className="d-flex justify-center">
                  <div className="text-center signup-content-width bottom-section-font">
                    {formatMessage({id: "verificationCodeSent"})} {phoneNumber}
                  </div>
                </div>
                <div className="d-flex justify-center align-items-center">
                  <InputCode
                    length={4}
                    onComplete={(code) => {
                      setpassword(code);
                      // handleSubmit(e)
                    }}
                  />

                  {/* <VerificationTimer seconds={10} phoneNumber={phoneNumber}/> */}
                  {/* <div>paspausk knopke</div> */}
                </div>
              </div>
            )}
            <div className="nunito-bold font-16 text-align-rt error-text text-center mobile-phone">
              {error && formatMessage({id: error})}{" "}
            </div>
            {loginPage === 1 && (
              <>
                <button
                  type="submit"
                  className="btn btn-purple nunito-extra-bold clr-white box-button-font"
                  style={{borderRadius: "5px", background: '#1771C7', boxShadow: 'none'}}
                >
                  {formatMessage({id: "login"})}
                </button>
              </>
            )}

            {loginPage === 3 && (
              <button
                type="button"
                style={{borderRadius: '50px'}}
                className={`btn btn-purple timer-btn nunito-extra-bold clr-white`}
                onClick={getCode} disabled={timer.current}>
                {timer.current ? time : fm('getVerificationCode')}
              </button>
              // <VerificationTimer
              //   smsLogin
              //   sendVerificationCode={sendVerificationCode}
              //   seconds={30}
              //   phoneNumber={phoneNumber}
              //   password={password}
              //   setLoadingText={props.setLoadingText}
              //   setLoadingState={props.setLoadingState}
              //   setErrorMessage={setErrorMessage}
              //   instantStart={true}
              //   buttonStyle={{borderRadius: "50px"}}
              // />
            )}
            {loginPage === 2 && (
              <button
                type='button'
                className="btn btn-purple nunito-extra-bold clr-white box-button-font"
                style={{borderRadius: "50px"}}
                onClick={async (e) => {
                  // e.preventDefault();

                  const verification = await sendVerificationCode();
                  // phoneNumber.length > 5 && verification && setloginPage(3);
                }}
              >
                {loginPage === 3
                  ? formatMessage({id: "login"})
                  : formatMessage({id: "getVerificationCode"})}
              </button>
            )}

            <div
              className="login-with-phone"
            >
              <Link to="/reset-password">
                {formatMessage({id: "ResetPassword"})}
              </Link>
            </div>
            {loginPage !== 3 && (

              <div
                className='login-with-phone'
                onClick={(e) => {
                  e.preventDefault();
                  // setretrieveCode(!retrieveCode);
                  loginPage == 1 ? setloginPage(2) : setloginPage(1);
                }}
              >
                {loginPage !== 2
                  ? formatMessage({id: "loginWithPhone"})
                  : formatMessage({id: "loginWithUserName"})}
              </div>
            )}

            <div
              className="mobile-new"
            >
              <div className="login-with-phone">{formatMessage({id: 'New to Udentity?'})}</div>
              <div className="login-with-phone"
                   onClick={() => props.setMobileTab && props.setMobileTab(1)}>{formatMessage({id: 'Create a Free Account'})}</div>
            </div>

            {loginPage !== 1 && <input style={{visibility: "hidden"}}/>}
          </form>

        </StyledLogin>
      </>
    );
  };

  return returnLoginModal();
};

// export default (LoginInModal);
export default connect(null, {displayBanner})(injectIntl(LoginInModal));
