import styled, { createGlobalStyle } from "styled-components";
import {useContext} from "react";
import LangContext from "../../../app/LangContext";

function FooterLangTag() {

  const { lang, setLang } = useContext(LangContext);


  const handleLanguage = (target) => {
    setLang(target)
  };

  return (
    <Container>
      <div id="zh" className="language zh" onClick={() => handleLanguage('zh')}>
        中文
      </div>
      <div className="language clr-primary-blue mx-3">{` | `}</div>
      <div id="en" className="language en" onClick={() => handleLanguage('en')}>
        English
      </div>
    </Container>
  )

}

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgb(23, 113, 199);
  & > div {
    cursor: pointer;
  }

`;

export default FooterLangTag;
