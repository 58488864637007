import forge from 'node-forge';
import highschoolService from "../services/highschool-service";
import fairService from "../services/fairs/fair-service";
import userHelper from "../helpers/user-helper";

const encrypted = (params, flag = true) => {
  const aesKey = sessionStorage.getItem('aesKey');

  console.log('params', params)
  const plaintext = JSON.stringify(params);

  const keyBytes = forge.util.encodeUtf8(aesKey);

  const cipher = forge.cipher.createCipher('AES-ECB', keyBytes);

  const plaintextBytes = forge.util.createBuffer(plaintext, 'utf8');

  cipher.start();
  cipher.update(plaintextBytes);
  cipher.finish();
  const encryptedBytes = cipher.output;

  const data = forge.util.encode64(encryptedBytes.bytes());

  if (flag) {
    return encodeURIComponent(data);
  } else {
    return data;
  }
}

const decrypted = data => {
  const aesKey = sessionStorage.getItem('aesKey');
  const keyBytes2 = forge.util.encodeUtf8(aesKey);

  const decipher = forge.cipher.createDecipher('AES-ECB', keyBytes2);

  const encryptedBytes2 = forge.util.decode64(data);

  decipher.start();
  decipher.update(forge.util.createBuffer(encryptedBytes2));
  decipher.finish();
  const decryptedBytes = decipher.output;

  const decrypted = decryptedBytes.toString('utf8');

  console.log('data', JSON.parse(decrypted))
  return JSON.parse(decrypted);
};

const loadData = async () => {


  const keypair = forge.pki.rsa.generateKeyPair({bits: 1024});

  const publicKeyPem = forge.pki.publicKeyToPem(keypair.publicKey);
  const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);

  sessionStorage.setItem('publicKeyPem', publicKeyPem);
  sessionStorage.setItem('privateKeyPem', privateKeyPem);

  let formatPublicKeyPem = publicKeyPem.replace('-----BEGIN PUBLIC KEY-----', '');
  formatPublicKeyPem = formatPublicKeyPem.replace('-----END PUBLIC KEY-----', '');
  formatPublicKeyPem = formatPublicKeyPem.replace(/(\r\n|\n|\r)/gm, "");

  const res = await highschoolService.getKey({pk: formatPublicKeyPem})

  const privateKeyObj = forge.pki.privateKeyFromPem(privateKeyPem);

  const decryptedMessage = privateKeyObj.decrypt(forge.util.decode64(res.data));
  const jsonDecryptedMessage = JSON.parse(decryptedMessage);

  sessionStorage.setItem('x-token', jsonDecryptedMessage.token);
  sessionStorage.setItem('aesKey', jsonDecryptedMessage.key);

}

function logClickEvent(details, other) {
  const user = userHelper.getUser()
  const fairName = 'click-log'
  // const fairName = 'march-fair-page'
  if (user) {
    console.log(`User Logging click event: ${details}`);
  } else {
    console.log(`Logging click event: ${details}`);
  }
  let params = { }
  if (user) {
    params = {
      profile: user.user_id,
      userType: user.user_type,
    }
  }
  if (other) {
    params = { ...params, ...other }
  }
  params.type = details
  fairService.fairPageEvent(
    params,
    fairName,
    userHelper.getUuid()
  );
}


function withLogging(originalFunction, details) {
  return function(...args) {
    logClickEvent(details);
    originalFunction(...args);
  };
}

export {
  decrypted,
  encrypted,
  loadData,
  logClickEvent,
  withLogging
}
