import userHelper from "../../helpers/user-helper";
import { handleResponse } from "../../helpers/api-helper";
import store from "../../redux/store";
var setLanguage = null;
// Get Universities By Params
function getUniversitiesByParams(params) {
  const user = userHelper.getUser();
  const url = `/api/university/search`;
  const searchParams = [];
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      searchParams.push(`${key}=${params[key]}`);
    }
  });
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}?${searchParams.join("&")}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

//Get Following Universities
function getFollowingUniversities() {
  const user = userHelper.getUser();
  const url = `/api/university/following`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getShowcaseUniversities() {
  const url = `/api/universities/showcase_list`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function followUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/university/${id}/follow`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        if (response.status === 200) return response;
        return false;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function subscribeUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/v2/hslyc/subscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function unsubscribeUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/v2/hslyc/unsubscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function unsubscribeAUCAUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/unsubscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function subscribeAUCAUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/subscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}



function unsubscribeFairUniversity({ id, fairName }) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/unsubscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function subscribeFairUniversity({ id, fairName }) {
  const user = userHelper.getUser();
  const url = `/api/v3/fair/${fairName}/subscribe`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ universityId: id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function addNotesFollowUniversity({ id, notes }) {
  const user = userHelper.getUser();
  const url = `/api/university/${id}/follow`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({ id, notes }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        if (response.status === 200) return response;
        return false;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function getUniversityProfile(id) {
  const user = userHelper.getUser();
  const url = `/api/university/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getUniversityById({ id }, language) {
  const user = userHelper.getUser();
  const url = `/api/university/${id}/${language}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getUniversityById2({ id }, language) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/${id}/${language}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function getUniversityById3(id) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/v2?aesData=${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  myHeaders.append('X-Token', sessionStorage.getItem('x-token'))
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}





function unfollowUniversity({ id }) {
  const user = userHelper.getUser();
  const url = `/api/university/${id}/unfollow`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ id }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        if (response.status === 200) return response;
        return false;
      })
      .catch(function (err) {
        throw err;
      })
  );
}
function exportList(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/exportAuthList`;
  const searchParams = [];
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      searchParams.push(`${key}=${params[key]}`);
    }
  });
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}?${searchParams.join("&")}`, requestOptions)
    // .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getViewersByParams(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/selectAuthList`;
  const searchParams = [];
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      searchParams.push(`${key}=${params[key]}`);
    }
  });
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(`${url}?${searchParams.join("&")}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

export default {
  getUniversitiesByParams,
  getFollowingUniversities,
  followUniversity,
  unfollowUniversity,
  addNotesFollowUniversity,
  getUniversityById,
  getUniversityProfile,
  subscribeUniversity,
  unsubscribeUniversity,
  getShowcaseUniversities,
  subscribeAUCAUniversity,
  unsubscribeAUCAUniversity,
  // New fair interface
  subscribeFairUniversity,
  unsubscribeFairUniversity,
  getUniversityById2,
  exportList,
  getViewersByParams,
  getUniversityById3

};
