import userHelper from "../helpers/user-helper";
import { handleResponse } from "../helpers/api-helper";
import { get } from "helpers/request";

// Signup User
function signup(data) {
  const url = `/api/v3/${data.type}/signup`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    })
    .catch(function (err) {
      throw err;
    });
}

function invite(data) {
  return
  const url = `/api/parent/invite`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    })
    .catch(function (err) {
      throw err;
    });
}

// Login User
function login({ loginname, password, mode, countryCode }) {
  const url = `/api/v3/login`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: mode
      ? JSON.stringify({ loginname, password, mode, countryCode })
      : JSON.stringify({ loginname, password }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // if (user) userHelper.setUser(user);
      return user;
    })
    .catch(function (err) {
      throw Array.isArray(err) ? err[0] : err;
    });
}

//Logout
function logout() {
  userHelper.removeUser();
}

function getInviteInfo(id) {
  const url = `/api/student/invite/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHighschoolList() {
  const url = `/api/high_schools`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}



function getUnreadMessageCount() {
  const url = `/api/v3/message/getUnreadMessageCount`;
  const user = userHelper.getUser();

  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
    user.auth_token &&
    myHeaders.append("Authorization", "Bearer " + user.auth_token);



  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getCollegeFairList() {
  const url = `/api/fair`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHsFairList() {
  const url = `/api/fair/high_school`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
    user.auth_token &&
    myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function getAucaFairList() {
  const url = `/api/v2/auca/universities`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
    user.auth_token &&
    myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}




function getJuneCollegeFairList() {
  const url = `/api/v2/fair/university/universities`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  // user &&
  //   user.auth_token &&
  //   myHeaders.append("Authorization", "Bearer " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHSLYCUniList() {
  const user = userHelper.getUser();
  const url = `/api/v2/hslyc/universities`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

const getFairAnalytics = get("/api/fair/visit_counter");
const getHSFairAnalytics = get("/api/fair/high_school/visit_counter");
const getHSLYCVisitCounter = get("/api/v2/hslyc/visitCounter");
const getAUCAVisitCounter = get("/api/v2/auca/visitCounter");

const getHSLYCExhibitorCounter = get("/api/v2/hslyc/exhibitorCounter");
const getAUCAExhibitorCounter = get("/api/v2/auca/exhibitorCounter");
// const getJuneCollegeFairAnalyics = get("/api/v2/fair/university/visitCounter");

function getJuneCollegeFairAnalyics() {
  const url = `/api/v2/fair/university/visit_counter`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getSchedulerData(universityId) {
  const url = `/api/v2/fair/university/appointment/${universityId}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHSLYCSchedulerData(universityId) {
  const url = `/api/v2/hslyc/appointment/${universityId}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function getAUCASchedulerData(universityId) {
  const url = `/api/v2/auca/appointment/${universityId}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}



function sendSchedulerData(params) {
  const user = userHelper.getUser();
  const url = `/api/v2/fair/university/appointment/submit`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}




function sendBlockedSchedule(id , params) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/updateSchedule/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}





function sendHSLYCSchedulerData(params) {
  const user = userHelper.getUser();

  const url = `/api/v2/hslyc/appointment/submit`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function sendAUCASchedulerData(params) {
  const user = userHelper.getUser();

  const url = `/api/v2/auca/appointment/submit`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}




function getUniversityList() {
  const url = `/api/universities`;
  // const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getCsvExport(id) {
  const user = userHelper.getUser();
  const url = `/api/v2/fair/university/download/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        const error = data && data.error ? data.error : "Error :Bad Request";
        return Promise.reject(error);
      }
      return response.blob();
    })
    .then((myBlob) => {
      var objectURL = URL.createObjectURL(myBlob);
      const data = objectURL;
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

function getHSLYCCsvExport(id) {
  const user = userHelper.getUser();
  const url = `/api/v2/hslyc/download/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        const error = data && data.error ? data.error : "Error :Bad Request";
        return Promise.reject(error);
      }
      return response.blob();
    })
    .then((myBlob) => {
      var objectURL = URL.createObjectURL(myBlob);
      const data = objectURL;
      return data;
    })
    .catch((err) => {
      throw err;
    });
}


function getAUCACsvExport(id) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/download/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        const error = data && data.error ? data.error : "Error :Bad Request";
        return Promise.reject(error);
      }
      return response.blob();
    })
    .then((myBlob) => {
      var objectURL = URL.createObjectURL(myBlob);
      const data = objectURL;
      return data;
    })
    .catch((err) => {
      throw err;
    });
}





function sendVerificationCode({ emailOrPhoneNumber, countryCode }) {
  // const url = `/api/verify`;
  const url = `/api/v3/sendVerificationCode`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ phonenum: emailOrPhoneNumber, countryCode }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

function sendVerificationCode2({ emailOrPhoneNumber, countryCode, captchaVerifyParam }) {
  const url = `/api/v3/sendVerificationCode`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ phonenum: emailOrPhoneNumber, countryCode, captchaVerifyParam }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

function sendVerificationCodeLogin(phoneNumber, countryCode) {
  return
  const url = `/api/sendSignInVerificationCode`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ phoneNumber, countryCode }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

function resetPassword({ code, newPassword, phoneNumber, verificationCode }) {
  const url = `/api/v3/reset_password`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify({ verificationCode, newPassword, phoneNumber }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

// Get user's institution profile(if apply)
function getInstProfile() {
  const user = userHelper.getUser();
  const url = `/api/v3/profile`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}




// Get user's own profile
function getUserProfile() {
  const user = userHelper.getUser();
  const url = `/api/v3/profile`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function getUserBasicInfo() {
  const user = userHelper.getUser();
  const url = `/api/v3/user/getUsrBasicInfo`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

//update profile
function updateStudentProfile(data) {
  const user = userHelper.getUser();
  const url = `/api/student/profile`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getNotifications() {
  const user = userHelper.getUser();
  const url = `/api/notifications`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function updateNotification(notification) {
  const user = userHelper.getUser();
  const url = `/api/notification/${notification.id}/read`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function updateNotificationsCount() {
  const user = userHelper.getUser();
  const url = `/api//notifications/ts`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function generateZoomSignature(data) {
  const user = userHelper.getUser();
  const url = `/api/zoom/get_signature`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getInsight(universityId) {
  const url = `/api/insight/${universityId}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHSYLCStudentUniversityPreferences() {
  const user = userHelper.getUser();

  const url = `/api/v2/hslyc/universityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}



function getAUCAStudentUniversityPreferences() {
  const user = userHelper.getUser();

  const url = `/api/v2/auca/universityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function setStudentUniversityPreferences(data) {
  const user = userHelper.getUser();
  const url = `/api/v2/hslyc/setUniversityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}




function setAUCAPreferences(data) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/setUniversityPreferences`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHSLYCAppointments(id) {
  const user = userHelper.getUser();

  const url = `/api/v2/hslyc/appointments/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function getAUCAAppointments(id) {
  const user = userHelper.getUser();

  const url = `/api/v2/auca/appointments/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}




function HSYLCPageEvent(data) {
  const user = userHelper.getUser();
  const url = `/api/v2/pageEvent/log`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ eventCode: "hslyc", data }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}


function AUCAPageEvent(data) {
  const user = userHelper.getUser();
  const url = `/api/v2/pageEvent/log`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ eventCode: "auca", data }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  );
}

function cancelAUCAConsultation(id, params) {
  const user = userHelper.getUser();
  const url = `/api/v2/auca/releaseSchedule/${id}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + user.auth_token);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}


function PageEvent(data) {
  const user = userHelper.getUser();
  const url = `/api/v2/pageEvent/log`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ eventCode: "page_events", data }),
  };

  return (
    fetch(url, requestOptions)
      // .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      })
  )
    }

function getUsrBasicInfoByOpenidUsingGET(openid) {
  const user = userHelper.getUser();
  const url = `/api/v3/user/getUsrBasicInfoByOpenid?openid=${openid}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function addInfo(data, auth) {
  const user = userHelper.getUser();
  const url = `/api/v3/user/addInfo`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  myHeaders.append("Authorization", "Basic " + auth);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}



function pickScience(data) {
  const user = userHelper.getUser();
  const url = `/api/v3/tencentYouth/pick`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getRanks() {
  const user = userHelper.getUser();
  const url = `/api/v3/tencentYouth/ranking`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function transformId(tmp) {
  const url = `/api/v3/event/realId/${tmp}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    })
    .catch(function(err) {
      throw err;
    });

}

function eventLog(id) {
  const user = userHelper.getUser();
  // const url = `/api/v3/event/log/${id}`;
  const myHeaders = new Headers();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  myHeaders.append("content-type", "application/json");
  myHeaders.append('_device', isMobile ? 'Phone' : 'PC');
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  const isNumeric = (str) => {
    if (typeof str === 'number') return true;
    if (typeof str !== "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  let url = '';
  if (isNumeric(id)) {
    url = `/api/v3/event/log/${id}`;
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch(function (err) {
        throw err;
      });
  } else {
    return transformId(id).then(res => {
      url = `/api/v3/event/log/${res}`;
      return fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          return response;
        })
        .catch(function (err) {
          throw err;
        });
    });
  }


}

function verify(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/verify`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getPdfLink(checkoutSessionId) {
  const user = userHelper.getUser();
  const url = `/api/v2/stripe/orderSuccess/${checkoutSessionId}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getHighschool(aesData) {
  const user = userHelper.getUser();
  const url = `/api/v3/highschool/simpleSelect?aesData=${aesData}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function getUnischool(aesData) {
  const user = userHelper.getUser();
  const url = `/api/v3/university/simpleSelect?aesData=${aesData}`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user  && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const token = sessionStorage.getItem('x-token');
  myHeaders.append("x-token", token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

function addEmail(params) {
  const user = userHelper.getUser();
  const url = `/api/v3/user/addEmail`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user && myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(function (err) {
      throw err;
    });
}

export default {
  signup,
  invite,
  login,
  logout,
  getInviteInfo,
  sendVerificationCode,
  resetPassword,
  getHighschoolList,
  getCollegeFairList,
  getJuneCollegeFairAnalyics,
  getJuneCollegeFairList,
  getUniversityList,
  getHSLYCUniList,
  getHsFairList,
  getSchedulerData,
  getHSLYCSchedulerData,
  getAucaFairList,
  sendSchedulerData,
  sendHSLYCSchedulerData,
  getInstProfile,
  getUserProfile,
  getUserBasicInfo,
  updateStudentProfile,
  getNotifications,
  getFairAnalytics,
  getHSFairAnalytics,
  AUCAPageEvent,
  updateNotification,
  sendBlockedSchedule,
  updateNotificationsCount,
  generateZoomSignature,
  sendVerificationCodeLogin,
  getInsight,
  getCsvExport,
  getHSLYCCsvExport,
  getAUCACsvExport,
  getAUCASchedulerData,
  getAUCAAppointments,
  cancelAUCAConsultation,
  getAUCAVisitCounter,
  sendAUCASchedulerData,
  getAUCAExhibitorCounter,
  getAUCAStudentUniversityPreferences,
  getHSYLCStudentUniversityPreferences,
  getHSLYCAppointments,
  getHSLYCExhibitorCounter,
  getHSLYCVisitCounter,
  setAUCAPreferences,
  setStudentUniversityPreferences,
  HSYLCPageEvent,
  getUnreadMessageCount,
  PageEvent,
  getUsrBasicInfoByOpenidUsingGET,
  pickScience,
  getRanks,
  eventLog,
  verify,
  addInfo,
  getPdfLink,
  getHighschool,
  getUnischool,
  addEmail,
  sendVerificationCode2
};
