import React, { useState, useEffect } from 'react';
import { Spin, Modal, message } from 'antd';
import styled, { createGlobalStyle } from "styled-components";
import _ from 'lodash';
import importAllImg from '../../../helpers/import-all-imgs';
import Modals from "../modals/Modals";
import ToTop from "../new-event/ToTop";
import { useIntl } from 'react-intl';
import userService from "../../../services/user-service";
import { useParams } from "react-router-dom";
import moment from "moment";
import eventService from "../../../services/student/event-service";
import Description from "../new-event/Description";
import fairService from "../../../services/fairs/fair-service";
import useCopyText from "../fair-components/useCopyText";
import { useHistory } from 'react-router-dom';

const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));
const imgs2 = importAllImg(require.context('../../../../src/assets/img/cie-fair', false, /\.(png|jpe?g|svg)$/));
const { confirm } = Modal;

const Panel1 = styled.div`
  display: flex;
  width: 90%;
  margin: 20px auto;
  
  & > div:nth-child(1) {
    flex: 1.8;
    position: relative;
    & > div:nth-child(1) {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
    }
  }

  .emoji-mart-preview {
    display: none;
  }
`;

const Panel2 = styled.div`
  width: 90%;
  margin: 10px auto;

  & > div:nth-child(1) {
    border: none !important;
  }

  .uni-panel {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    width: fit-content;
    @media (max-width: 991px) {
      font-weight: bold;
      font-size: 12px;
      color: #000000;
    }
    & > img {
      width: 48px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0;
    @media (max-width: 991px) {
      font-weight: bold;
      font-size: 12px;
      color: #000000;
    }
  }

  .download-panel {
    margin-right: 10px;
    & > button {
      display: flex;
      align-items: center;
      background: #F2F2F7;
      border-radius: 14px;
      width: 220px;
      height: 42px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #1771C7;
      @media (max-width: 991px) {
        width: 200px;
        font-weight: bold;
        font-size: 12px;
      }
      & > img {
        width: 14px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }
  
  .message-panel {
    & > button {
      display: flex;
      align-items: center;
      background: #F2F2F7;
      border-radius: 14px;
      padding: 5px 15px;
      height: 42px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #1771C7;
      @media (max-width: 991px) {
        font-weight: bold;
        font-size: 12px;
        padding: 5px;
      }
    }
  }

  .content {
    font-size: 14px;
    color: #1C1C1E;
    width: 64.5%;
    word-break: break-all;
    @media (max-width: 991px) {
      font-size: 11px;
      width: 100%;
    }
  }
  

`;

const ChatContainer = styled.div`
  margin-left: 5px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  //justify-content: center;
  @media(max-width: 991px) {
    background: #F2F2F7;
    padding: 20px 0;
    margin-left: 0;
  }
  .book-time {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #1771C7;
  }

  .all-in-beijing {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px auto;
  }

  .time-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 10px;

    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 22px;
      color: #1C1C1E;
      background: #FFFFFF;
      border: 1px solid #1C1C1E;
      border-radius: 8px;
      width: 117px;
      height: 32px;
      cursor: pointer;
    }
  }
  
  .registered {
    background: rgba(96, 167, 33, 0.41) !important;
  }
  
  .unuseable {
    background: #FFFFFF !important;
    border: 1px solid #BDBDBD !important;
    color: #BDBDBD !important;
    cursor: not-allowed !important;
  }
`;

const VideoPanel = styled.div`
  position: relative;
  height: 0;
  padding-top: 36.21%;
  @media(max-width: 991px) {
    padding-top: 56.21%;
  }
  & > div:nth-child(1) {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
  }
  iframe {
    position: absolute;
    top:      0;
    left:     0;
    bottom:   0;
    right:    0;
    width:    100%;
    height:   100%
  }
`;

const CustomModal = createGlobalStyle`
  .confirm-modal {

    .ant-modal-content {
      box-shadow: 0px 4px 10px rgba(204, 204, 204, 0.25);
      border-radius: 40px;
    }

    .ant-modal-confirm-btns {
      float: none;
      text-align: center;
    }

    .ant-btn-primary {
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
      width: 180px;
      height: 40px;
      background: #41B6E6;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: none;
    }

    .ant-modal-body {
      //height: 270px;
    }

    .ant-modal-confirm-body-wrapper {
      padding: 10px;
    }
  }
`;

const ConfirmContainer = styled.div`
  width: 80%;   
  margin: 0 auto;
  .content {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  
  .time {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-top: 5px;
  }

  .success-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      margin-left: 10px;
    }
  }
  
  .datetime {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
  
  .time-panel {
    display: flex;
    @media(max-width: 991px) {
      flex-flow: column;
    }
  }
  
  .dt {
    flex: 0 0 130px;
    @media(max-width: 991px) {
      flex: auto;
    }
  }
  
  .time-detail {
    display: flex;
    flex-flow: column;
  }
  
  .bj-time {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .link {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 10px auto;
  }
`;

const ItemsContainer = styled.div`
  margin-left: 5px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .middle-panel-right {
    //margin-left: auto;
    & > div {
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      padding: 5px 10px;
      width: 191px;
      height: 43px;
      border-radius: 14px;
      @media (max-width: 991px) {
        font-size: 10px;
        line-height: 14px;
        margin: 6px auto;
        padding: 5px;
        width: 150px;
        height: 28px;
      }

      &.nohave {
        background: #C4C4C4;
        color: #FFFFFF;
      }

      &.have {
        background: rgba(96, 167, 33, 0.41);
        color: #1C1C1E;
        background: #BDDAA4;
      }

      &.item1 {
        margin-top: 0;
      }
      //
      //&.item2 {
      //  border-radius: 10px 0px 0px 10px;
      //}

      &.item3 {
        margin-bottom: 0;
        & > img {
          margin-left: 10px;
          cursor: pointer;
          @media (max-width: 991px) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
`;

const noMsg = [81707,
  83555,
  84157,
  84170,
  84172,
  84203,
  84238,
];


function CieEvent({user, setUser}) {

  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const [uni, setUni] = useState({ courses: []});
  const copy = useCopyText();
  const history = useHistory();
  const timeRanges = uni.timeRanges || [];
  const urlParams = new URLSearchParams(window.location.search);
  const uniId = urlParams.get('uni-id');

  const getUni = times => {
    if (uniId) {
      fairService.cieLiveSession(uniId).then(res => {
        if (times) {
          handleConsultationSignUp2(times, res.data);
        }
        setUni(res.data);
      });
    }
  };

  useEffect(getUni, []);

  useEffect(() => {
    eventService.getEventById({ id }, 'en').then(res => setEvent(res), rej => message.error(rej));
  }, []);


  useEffect(() => {
    userService.eventLog(id).then(res => {});
    const fn = () => {
      confirm({
        title: '检测到网络状态由离线转为在线',
        content: '需要刷新页面才能正常使用聊天室，是否刷新',
        onOk() {
          location.reload();
        },
        onCancel() {},
      });
    }
    window.addEventListener('online', fn);
    return () => window.removeEventListener('online', fn);
  }, []);


  const notLogin = () => {
    if (!user) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };


  if (!_.isObject(event)) {
    return (
      <div style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spin />
      </div>
    );
  }


  const iframePanel = (
    <VideoPanel id='desktop-player-container'>
      { _.isEmpty(user) && <div onClick={() => setShowModal(true)}/> }
      <iframe
        id="desktop-player"
        // width='100%'
        // height={($('#desktop-player-container').width() || 0) * 0.5628}
        loading="lazy"
        src={`//live.udty.tech/watch/${event.vendorAssnId}?player`}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        controls
        scrolling="no"
      />
    </VideoPanel>
  );

  const handleConsultationSignUp = times => {
    if (notLogin()) return;
    Modal.info({
      centered: true,
      mask: false,
      maskClosable: true,
      content: <ConfirmContainer>
        <div className='content'>
          Are you sure you want to book the 11/20, {times.start}-{times.end} consultation？
        </div>
        <div className='time'>Beijing & Singapore time (UTC+8)</div>
      </ConfirmContainer>,
      icon: '',
      okText: 'Book Consultation',
      className: 'confirm-modal',
      width: 540,
      onOk: () => {
        fairService.cieConsultationSessionSignUp(uniId, {"end": times.end, "start": times.start}).then(res => {
          getUni(times);
        }, rej => message.error(rej))
      }
    })
  };

  const handleConsultationSignUp2 = (times, data) => {
    if (notLogin()) return;
    const uniData = data || uni;
    Modal.info({
      centered: true,
      mask: false,
      maskClosable: true,
      content: <ConfirmContainer>
        <div className='success-title'>
          Success <img src={imgs['Group 1992.png']} />
        </div>
        <div className='time-panel'>
          <span className='datetime dt'>Date & Time：</span>
          <span className='time-detail'>
            <span className='datetime'>11/20, {times.start}-{times.end}</span>
            <span className='bj-time'>Beijing & Singapore time (UTC+8)</span>
          </span>
        </div>
        <div className='link'>
          Meeting Link： &nbsp;
          <a target='_blank' href={uniData.consultationLink}>{uniData.consultationLink}</a>
        </div>
      </ConfirmContainer>,
      icon: '',
      okText: 'Copy Link',
      className: 'confirm-modal',
      width: 540,
      onOk: () => copy(uniData.consultationLink)
    })
  };

  const jumpToUni = () => {
    history.push(`/university/${event.universityId}`);
  };

  const handleDownLoad = () => {
    if (notLogin()) return
    if (uni.attachmentUrl) {
      eventService.eventAction('download', event.id).then(() => {});
      const file = uni.attachmentUrl;
      window.open(file);
    }
  };

  const handleMessage= () => {
    if (notLogin()) return
    window.open(`/message/${uni.universityId}`);
  };

  const isCie = uniId ? false: true;

  const tmp = user || {};


  const noMsgIcon = () => {
    if (tmp.user_type === 'university') {
      return true
    };
    if (!uni.verified) {
      return true;
    }
    return false;
  };

  if (window.innerWidth <= 991) {
    return (
      <>
        <CustomModal />
        { iframePanel }


        <Panel2>
          <div className='uni-panel' onClick={jumpToUni} style={{display: isCie ? 'none' : 'flex'}}>
            <img src={event.universityLogo} />
            <div>
              {event.universityName}
            </div>
          </div>

          <div className='title'>Resources</div>
          <div style={{display: 'flex'}}>
            { uni.attachmentUrl && (
              <div className='download-panel'>
                <button className='btn' onClick={handleDownLoad}>
                  <img src={imgs['download.svg']} />
                  DOWNLOAD BROCHURE
                </button>
              </div>
            )}
            <div className='message-panel'>
              {
                !noMsgIcon() && <button className='btn' onClick={handleMessage}>
                  SEND MESSAGE
                </button>
              }
            </div>
          </div>

          <div className='title'>Description</div>
          <div className='content'>
            {event.description}
          </div>
        </Panel2>

        <ItemsContainer>
          <div className='middle-panel-right'>
            <div className={uni.courses.includes('IGCSE English') ? 'item1 have' : 'item1 nohave'}>IGCSE English</div>
            <div className={uni.courses.includes('Cambridge AS Level') ? 'item2 have' : 'item2 nohave'}>Cambridge AS Level</div>
            <div className={uni.courses.includes('Cambridge A Level') ? 'item2 have' : 'item2 nohave'}>Cambridge A Level</div>
            <div className='item3'>
              Scholarships
              <img src={uni.scholarships ? imgs2['circle_check.svg'] : imgs2['Remove.png']} />
            </div>
          </div>
        </ItemsContainer>

        {/*<ChatContainer style={{opacity: uniId ? 1 : 0}}>*/}
        {/*  <div className='book-time'>Book Group Consultation Time<br/>*/}
        {/*    20th November 2021</div>*/}
        {/*  <div className='all-in-beijing'>All in Beijing & Singapore time (UTC+8)</div>*/}
        {/*  <div className='time-list'>*/}
        {/*    {timeRanges.map(j => (*/}
        {/*      <React.Fragment key={j.start}>*/}
        {/*        {j.usable && <span  key={j.start} onClick={() => handleConsultationSignUp({start: j.start, end: j.end})}>{j.start}-{j.end}</span>}*/}
        {/*        {!j.usable &&*/}
        {/*        <span*/}
        {/*          className={j.registered ? 'registered' : 'unuseable'}*/}
        {/*          key={j.start}*/}
        {/*          onClick={j.registered ? () => handleConsultationSignUp2({ start: j.start, end: j.end}) : () => {}}*/}
        {/*        >*/}
        {/*          {j.start}-{j.end}*/}
        {/*        </span>*/}
        {/*        }*/}
        {/*      </React.Fragment>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</ChatContainer>*/}

        <Modals setUser={setUser} show={showModal} setShow={setShowModal} t='signup'/>

      </>
    );
  }

  return (
    <>
      <Panel1>
        <CustomModal />
        { iframePanel }

        <ItemsContainer>
          <div className='middle-panel-right'>
            <div className={uni.courses.includes('IGCSE English') ? 'item1 have' : 'item1 nohave'}>IGCSE English</div>
            <div className={uni.courses.includes('Cambridge AS Level') ? 'item2 have' : 'item2 nohave'}>Cambridge AS Level</div>
            <div className={uni.courses.includes('Cambridge A Level') ? 'item2 have' : 'item2 nohave'}>Cambridge A Level</div>
            <div className='item3'>
              Scholarships
              <img src={uni.scholarships ? imgs2['circle_check.svg'] : imgs2['Remove.png']} />
            </div>
          </div>
        </ItemsContainer>

        {/*<ChatContainer style={{opacity: uniId ? 1 : 0}}>*/}
        {/*  <div className='book-time'>Book Group Consultation Time<br/>*/}
        {/*    20th November 2021</div>*/}
        {/*  <div className='all-in-beijing'>All in Beijing & Singapore time (UTC+8)</div>*/}
        {/*  <div className='time-list'>*/}
        {/*    {timeRanges.map(j => (*/}
        {/*      <React.Fragment key={j.start}>*/}
        {/*        {j.usable && <span  key={j.start} onClick={() => handleConsultationSignUp({start: j.start, end: j.end})}>{j.start}-{j.end}</span>}*/}
        {/*        {!j.usable &&*/}
        {/*        <span*/}
        {/*          className={j.registered ? 'registered' : 'unuseable'}*/}
        {/*          key={j.start}*/}
        {/*          onClick={j.registered ? () => handleConsultationSignUp2({ start: j.start, end: j.end}) : () => {}}*/}
        {/*        >*/}
        {/*          {j.start}-{j.end}*/}
        {/*        </span>*/}
        {/*        }*/}
        {/*      </React.Fragment>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</ChatContainer>*/}

      </Panel1>

      <Panel2>
        {/*<div>{formatDate()}</div>*/}
        {/*<div>{event.title}</div>*/}
        {/*<div style={{width: $('#desktop-player-container').width()}}>{uv} {formatMessage({ id: "viewers" })}</div>*/}
        {/*<Description event={event} notLogin={notLogin} noCn={true} isCie={uniId ? false: true}/>*/}
        <div className='uni-panel' onClick={jumpToUni} style={{display: isCie ? 'none' : 'flex'}}>
          <img src={event.universityLogo} />
          <div>
            {event.universityName}
          </div>
        </div>

        <div className='title'>Resources</div>
        <div style={{display: 'flex'}}>
          { uni.attachmentUrl && (
            <div className='download-panel'>
              <button className='btn' onClick={handleDownLoad}>
                <img src={imgs['download.svg']} />
                DOWNLOAD BROCHURE
              </button>
            </div>
          )}
          <div className='message-panel'>
            {
              !noMsgIcon() && <button className='btn' onClick={handleMessage}>
                SEND MESSAGE
              </button>
            }
          </div>
        </div>

        <div className='title'>Description</div>
        <div className='content'>
          {event.description}
        </div>


      </Panel2>

      <Modals setUser={setUser} show={showModal} setShow={setShowModal} t='signup'/>
    </>
  );
}

export default CieEvent;
