/* eslint-disable no-undef */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
// import * as echarts from 'echarts';
import Highcharts from 'highcharts/highmaps.js';

window.Highcharts = Highcharts;

let createdChart = null;
let timer1, timer2, timer3;
// https://www.figma.com/file/VmcVUvCnQfDtK9VE8ZSCVA/Analytics?node-id=1%3A6  设计稿
// https://www.echartsjs.com/zh/download-map.html  echarts由于版权无法使用，换下面的highcharts
// https://api.highcharts.com.cn/highmaps#colorAxis.dataClassColor
// https://www.highcharts.com.cn/demo/highmaps/geojson   demo
// 地图颜色配置 https://jshare.com.cn/github/highcharts/highcharts/tree/master/samples/maps/coloraxis/dataclasscolor/
// https://data.jianshukeji.com/jsonp?filename=geochina/china.json&callback=?  地图数据地址

class MapChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartoptions: {
                title: {
                    show: false,
                    text: ''
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    headerFormat: '',
                    // pointFormat: '{point.filename}: <b>{point.value}</b>',
                    pointFormatter: function() {
                        let name = this.filename;
                        switch(this.filename) {
                            case 'shanxi2':
                                name = 'shaanxi';
                                break;
                            case 'aomen':
                                name = 'macao';
                                break;
                            case 'xianggang':
                                name = 'hong Kong';
                                break;
                        }
                        name = name.charAt(0).toUpperCase()+name.slice(1);
                        let str = name + ': <b>' + this.value + '</b>';
                        return str
                    }
                },
                credits: {
                    enabled: false,
                },
                colors: [],
                colorAxis: {
                    tickPixelInterval: 10
                },
                series: [{
                    data: this.props.dataprop,
                    mapData: null,
                    joinBy: 'filename',
                    keys: ['filename', 'value'],
                    name: '',
                    states: {
                        hover: {
                            color: '#a4edba'
                        }
                    }
                }]
            }
        }
    }

    componentDidMount() {
        let self = this;
        let dataArr = [];
        // eslint-disable-next-line no-undef
        timer1 = setTimeout(() => {
            dataArr = self.parseData(self.props.dataprop);
        }, 500)
        // eslint-disable-next-line no-undef
        timer2 = setTimeout(()=> {
            $.getJSON('https://data.jianshukeji.com/jsonp?filename=geochina/china.json&callback=?', function (geojson) {
                self.setState({
                    geojson: geojson
                });

                self.state['chartoptions'].series[0].mapData = geojson;
                self.state['chartoptions'].series[0].data = dataArr;
                self.setState(this.state, ()=> {
                    createdChart = Highcharts.mapChart('eventMapChart', self.state.chartoptions)
                })
            })
        }, 1500);
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        let dataArr = self.parseData(nextProps.dataprop);
        self.state['chartoptions'].series[0].data = dataArr;
        // eslint-disable-next-line no-undef
        timer3 = setTimeout(()=> {
            self.setState(this.state, ()=> {
                createdChart = Highcharts.mapChart('eventMapChart', self.state.chartoptions)
            })
        }, 600)
    }

    componentWillUnmount() {
        timer1 && clearTimeout(timer1);
        timer2 && clearTimeout(timer2);
        timer3 && clearTimeout(timer3);
        createdChart && createdChart.destroy();
    }

    parseData = (arr) => {
        let newArr = [];
        if(arr && arr['domestic']) {
            let tmp = arr['domestic'];
            for(let i = 0; i< tmp.length; i++) {
                let region = Object.keys(tmp[i])[0].toLowerCase();
                switch(region) {
                    case 'shaanxi':
                        region = 'shanxi2';
                        break;
                    case 'hong kong':
                            region = 'xianggang';
                            break;
                    case 'macau':
                            region = 'aomen';
                            break;
                }
                const item = [region, parseInt(Object.values(tmp[i])[0])];
                newArr.push(item);
            }
            return newArr;
        }
    }

    render() {
        return (
            <div id="eventMapChart" style={{height: '240px', width: "100%"}}>
            </div>
        );
    }
}

export default injectIntl(MapChart);
