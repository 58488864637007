import React from "react";
import { Route, Redirect } from "react-router-dom";

import userHelper from "../helpers/user-helper.js";

const StudentRoutes = ({ component: Component, ...rest }) => {
  const user = userHelper.getUser();
  const userType = user && user.user_type ? user.user_type : "";
  return (
    <Route
      {...rest}
      render={props => {
        if (user == undefined) {
          return <Redirect to="/" />;
        }
        switch (user.user_type) {
          case "student":
            return <Component {...props} />;
          case "university":
            return <Redirect to="/university/profile" />;
          case "counselor":
            return <Redirect to="/highschool/explore" />;
          case "parent":
            return <Redirect to="/parent/explore" />;
          default:
            return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default StudentRoutes;
