import React, { useRef } from 'react';

function useScrollToBottom() {

  const ref = useRef(null);


  const scrollToBottom = () => {
    if (ref.current) {
      // ref.current.scrollIntoView({ block: 'nearest' });
      ref.current.parentNode.scrollTop = ref.current.offsetTop;
    }
  };

  return [ref, scrollToBottom];
}

export default useScrollToBottom;
