import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ChatItem from "./ChatItem";
import {sendMessage, smile} from "../../../helpers/images-helper";
import "emoji-mart/css/emoji-mart.css";
import "./emojiMenu/emojiMenuAnimation.css";
import { Picker } from "emoji-mart";
import moment from "moment-timezone";
import useScrollToBottom from "./useScrollToBottom";
import { Input, Popover, Upload, message as antMessage, Modal  } from 'antd';
import {emojiFilter} from "./emojiMenu/emojiFilter";
import _ from 'lodash';
import importAllImg from "../../../helpers/import-all-imgs";
import userHelper from "../../../helpers/user-helper";

const { TextArea } = Input;

const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));
const imgs2 = importAllImg(require.context('../../../../src/assets/img/msg', false, /\.(png|jpe?g|svg)$/));

const BottomContainer = styled.div`
  //background: #F2F2F7;
  padding: 10px;
  border-top: 1px solid #F6F7F8;
  @media (max-width: 991px) {
    border-top: 4px solid #F2F2F7;
  }
  .ant-input {
    background: #F2F2F7;
    width: 100%;
    border: none;
    padding: 13px;
    @media (max-width: 991px) {
      padding: 10px;
      padding-top: 5px;
      height: 35px;
    }
  }
  
  .ant-upload {
    width: auto;
    height: auto;
    background: white;
    border: none;
    margin: 0;
  }
  
  @media (max-width: 991px) {
    //position: fixed;
    //bottom: 0;
    //width: 100%;
  }
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      //flex: 1;
      margin-right: 15px;
      @media (max-width: 991px) {
        margin-right: 5px;
      }
      display: flex;
      img {
        cursor: pointer;
        @media (max-width: 991px) {
          width: 24px;
        }
      }
      & > img:nth-child(1) {
        margin: 0 15px;
        @media (max-width: 991px) {
          margin: 5px;
        }
      }
    }
    & > div:nth-child(2) {
      flex: 1;
      @media (max-width: 991px) {
        flex: 0 0 280px;
      }
    }
    & > div:nth-child(3) {
      display: flex;
      align-items: center;
      margin: 0 5px;
      & > img {
        width: 30px;
        @media (max-width: 991px) {
          width: 24px;
          margin-left: 5px;
        }
        &:nth-child(1) {
          margin-right: 5px;
        }
      }
    }
  }
`;

const ChatList = styled.div`
  flex: 1;
  overflow-y: auto;
  @media (max-width: 991px) {
    //margin-bottom: 95px;
  }
  .load-more {
    text-align: center;
    cursor: pointer;
    height: 14px;
    background: #D1D4D1;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
  }
  &::-webkit-scrollbar {
    background: #BDBDBD;
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: #1771C7;
  }
  
  .entered-panel {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1px;
    color: #C4C4C4;
    margin: 0.5em auto;
    & > img {
      margin-right: 3px;
    }
  }
`;

const TimeContainer = styled.div`
  text-align: center;
  letter-spacing: 0.1px;
  white-space: nowrap;
  //width: 100%;
  width: 80px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #F2F2F7;
  border-radius: 5px;
  margin: 0.5em auto;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;

  /* text/grey */

  color: #757575;

`;

const EmojiContainer = styled.div`
  .emoji-mart-preview {
    display: none;
  }
`

const CustomPopover = createGlobalStyle`
  .cus-popover  {
    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;

    }
  }
`;

const { confirm } = Modal;

function ChatContent({list, basicInfo, sendMsg, loadEvents, sendFile, isPrivate}) {

  const [message, setMessage] = useState('');
  const [messagesEndRef, scrollToBottom] = useScrollToBottom();
  const { scroll, hasMore, loadMore } = loadEvents;
  const textAreaRef = useRef(null);

  useEffect(() => {
    const fn = () => {
      confirm({
        title: '检测到网络状态由离线转为在线',
        content: '需要刷新页面才能正常使用聊天室，是否刷新',
        onOk() {
          location.reload();
        },
        onCancel() {},
      });
    }
    window.addEventListener('online', fn);
    return () => window.removeEventListener('online', fn);
  }, []);

  useEffect(() => {
    if (scroll) {
      scrollToBottom();
    }
  }, [list, scroll]);


  const renderTime = (commentItem, index) => {
    const dumpCommentsArray = [...list];
    const flag1 = dumpCommentsArray[index + 1] &&
      moment
        .duration(
          moment(
            commentItem.timestamp
          ).diff(
            dumpCommentsArray[
            index + 1
              ].timestamp
          )
        )
        .asHours() > 1
    const flag2 = index == 0 &&
      moment
        .duration(
          moment().diff(
            dumpCommentsArray[index]
              .timestamp
          )
        )
        .asHours() > 1;
    const flag3 = dumpCommentsArray[index - 1] &&
      moment
        .duration(
          moment(
            commentItem
              .timestamp
          ).diff(
            dumpCommentsArray[
            index - 1
              ].timestamp
          )
        )
        .asHours() > 1
    if (flag1 || flag2 || flag3) {
      return (
        <TimeContainer>
          {moment(
            commentItem.timestamp
          ).format(
            `${
              moment

                .duration(
                  moment().diff(
                    commentItem
                      .timestamp
                  )
                )
                .asHours() < 24
                ? ""
                : "MM-DD"
            } HH:mm`
          )}
        </TimeContainer>
      )
    } else {
      return null;
    }
  };


  const handleSendMsg = e => {
    e.preventDefault();
    if (message === '') return;
    sendMsg(message);
    setMessage('');
  };

  const handleBeforeUpload = file => {
    if (file.size >= 1000 * 1000 * 10) {
      antMessage.warn("file is limit by 10MB");
      return false;
    }
    return true;
  };

  const handleFileUpload = (info) => {
    if (info.file.status === "done") {
      const fileUrl = info.file.response.url;
      const fileName = info.file.response.fileName;
      sendFile({fileUrl, fileName});
    }
    if (info.file.status === "error") {
      antMessage.error("Error uploading the file")
    }
  };

  const scrollTextArea = () => {
    const textarea = textAreaRef.current?.resizableTextArea?.textArea;
    if (textarea) {
      textarea.scrollTop = textarea.scrollHeight;
      textarea.focus();
    }
  }

  const handleLoad = _.debounce(loadMore, 500);

  const emojiContent = (
    <EmojiContainer>
      <Picker
        emojisToShowFilter={ (emoji) => {
          if (typeof emojiFilter[emoji.unified] != "undefined") return true;
        }}
        color={"#1890ff"}
        onSelect={emojiObject => setMessage(message + emojiObject.native) }
      />
    </EmojiContainer>
  );

  return (
    <>
      <ChatList>
        { hasMore && <div className='load-more' onClick={handleLoad}>load more</div> }
        {list.map((i, index) => {
          return (
            // unique key need
            <div key={i.id + index}>
              { renderTime(i, index) }
              <ChatItem scrollTextArea={scrollTextArea}  user={basicInfo} itemInfo={i} setMessage={setMessage}　scrollToBottom={scrollToBottom} isLast={index === list.length - 1}/>
            </div>
          );
        }) }
        <div className='msg-end' ref={messagesEndRef} />
      </ChatList>
      <BottomContainer>
        <div>
          {/*{basicInfo.name}*/}
        </div>
        <div>
          <div>
            <Popover trigger="click" content={emojiContent} overlayClassName='cus-popover'>
              <img
                src={imgs2['smile.svg']}
              />
            </Popover>
            {isPrivate &&
              <Upload
                listType="picture-card"
                showUploadList={false}
                accept=".doc,.docx,application/pdf,.jpg,.png"
                action="/api/v3/common/uploadFileToOSS"
                onChange={handleFileUpload}
                beforeUpload={handleBeforeUpload}
                headers={{
                  Authorization: `Bearer ${userHelper.getUser().auth_token}`,
                }}
              >
                <img src={imgs2['paperclip.svg']} />
              </Upload>
            }

          </div>
          <div>
            <TextArea
              ref={textAreaRef}
              rows={2}
              placeholder="Send a message..."
              value={message}
              onChange={e => setMessage(e.target.value)}
              onPressEnter={handleSendMsg}
            />
          </div>
          <div>
            <img
              src={imgs2['Group 732.svg']}
              onClick={handleSendMsg}
            />
          </div>
        </div>
      </BottomContainer>
      <CustomPopover />
    </>
  );
}

export default ChatContent;
