import React, { useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import styled, { createGlobalStyle } from "styled-components";
import importAllImg from "../../../helpers/import-all-imgs";
import { useIntl } from 'react-intl';
import fairService from "../../../services/fairs/fair-service";

const ForContainer = styled.div`

  @media(max-width: 991px) {
    margin-top: 0;
    flex-flow: column;
    padding: 10px 10% 10px 4%;
  }
  & > div:last-child {
    //margin-left: auto;
  }
  .no-click {
    cursor: auto;
    color: #333 !important;
  }
  background: #FFF;
  padding: 26px 4% 36px 4%;
  display: flex;
  justify-content: center;
  column-gap: 50px;
  margin-top: 18px;
  
  .title {
    color: var(--brand-color-blue, #1771C7);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.8px;
    min-height: 65px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    @media(max-width: 991px) {
      min-height: auto;
      text-align: left;
      font-size: 14px;
      border-bottom-color: #D1D4D6;
      padding-left: 20px;
      padding-bottom: 20px;
    }
  }
  
  .content {
    padding-top: 15px;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #333;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    
    
    @media(max-width: 991px) {
      font-weight: 400;
      ul {
       padding-left: 20px; 
      }
    }
    
    li {
      margin-bottom: 15px;
      cursor: pointer;
      @media(max-width: 991px) {
        margin-bottom: 20px;
      }


      &:hover {
        color: #0b7dda;
        &.no-change {
          color: #333;
          cursor: auto;
        }
      }
    }
    ul > ul {
      list-style-type: disc;
      padding-left: 30px;
    }
  }

  .rs-right-btn {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: right;
    @media(max-width: 991px) {
      font-size: 14px;
    }
    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      &:hover {
        color: #0b7dda;
      }
      & > img {
        margin-left: 20px;
        @media(max-width: 991px) {
          width: 20px;
        }
      }
    }

    &:first-of-type {
      margin-bottom: 10px;
      @media(max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }
  .rs-right-text {
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 15px;
  }
  
  .img-panel {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    @media(max-width: 991px) {
      //display: none;
    }
    & > img {
      margin-bottom: 5px;
      width: 150px;
      height: 150px;
      margin-right: -8px;
      @media(max-width: 991px) {
        width: 90px;
        height: 90px;
      }
    }
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media(max-width: 991px) {
      text-align: right;
    }
    margin: 20px auto 30px auto;
  }
  
  .link {
    color: var(--brand-color-blue, #1771C7);
    @media(max-width: 991px) {
      display: none;
    }
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.8px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: right;
  }
  
  ul {
    list-style: none !important;
  }
`;

const CustomPopover = createGlobalStyle`
  .cus-popover  {
    left: 0 !important;
    right: 0 !important;
    //position: relative;
    z-index: 10;
    .ant-popover-content {
    }
    
    .ant-popover-inner-content {
      padding: 0;
    }
    
    .ant-popover-arrow {
      display: none;
    }
  }
  

`;

const imgs = importAllImg(require.context('../../../../src/assets/img/2023-home', false, /\.(png|jpe?g|svg)$/));


function ForPanel({children}) {

  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const fm = id => formatMessage({id});

  // const openSignup = () => {
  //   window.open('/student/signup?redirect=%2F')
  // };

  const isMobile = window.innerWidth <= 991;

  const openSignup = () => {
    if (isMobile) {
      const signBtn = document.querySelector('#signup');
      signBtn?.click();
    } else {
      const signBtn = document.querySelector('#login');
      signBtn?.click();
    }

  };



  const logClick = (value) => {
    const params = {
      type: 'click',
      value: value
    };
    fairService.fairPageEvent2(params, 'menu');
  };

  const handleNestedClick = (topValue, nestedValue, url) => {
    const fullValue = `${topValue}:${nestedValue}`;
    logClick(fullValue);
    window.open(url);
  };

  const forContent = (
    <ForContainer>
      <div>
        <div className='title'>{fm('FOR UNIVERSITIES')}</div>
        <div className='content'>
          <ul>
            <li onClick={() => handleNestedClick('FOR UNIVERSITIES', 'Verified HS Database', '/international-high-schools-china-asia-database')}>
              {fm('Verified HS Database')}
            </li>
            <li className='no-change'>{fm('Digital Recruitment Solutions')}</li>
            <ul>
              <li
                onClick={() => handleNestedClick('FOR UNIVERSITIES:Digital Recruitment Solutions', 'WeChat Official Account Management', '/WeChat-Official-Account-Management')}>
                {fm('WeChat Official Account Management')}
              </li>
              <li
                onClick={() => handleNestedClick('FOR UNIVERSITIES:Digital Recruitment Solutions', 'WeChat Mini-Program', '/WeChat-mini-program-functions-development')}>
                {fm('WeChat Mini-Program')}
              </li>
              <li
                onClick={() => handleNestedClick('FOR UNIVERSITIES:Digital Recruitment Solutions', 'Bilibili Account Management', '/bilibili-account-management')}>
                {fm('Bilibili Account Management')}
              </li>
              <li
                onClick={() => handleNestedClick('FOR UNIVERSITIES:Digital Recruitment Solutions', 'Red Book (Xiaohongshu)', '/redbook-xiao-hong-shu-management')}>
                {fm('Red Book (Xiaohongshu)')}
              </li>
              <li
                onClick={() => handleNestedClick('FOR UNIVERSITIES:Digital Recruitment Solutions', 'Satellite Web Development', '/chinese-website-development')}>
                {fm('Satellite Web Development')}
              </li>
            </ul>
            <li
              onClick={() => handleNestedClick('FOR UNIVERSITIES', 'Fairs on Udentity', '/chinese-student-recruitment/events-fairs')}>
              {fm('Fairs on Udentity')}
            </li>
            <li
              onClick={() => handleNestedClick('FOR UNIVERSITIES', 'Customized Events', 'https://forms.office.com/Pages/ResponsePage.aspx?id=IN5bNU2K50WTZC7ty9W8nngt9ldYVkpJkAoZ0V_DCk1UMEVMUkxBTDlQQTg5UUFJQk0yOVI0RlNTUi4u')}>
              {fm('Customized Events')}
            </li>
            <li onClick={() => handleNestedClick('FOR UNIVERSITIES', 'Udentity HS Tours', 'https://forms.office.com/Pages/ResponsePage.aspx?id=IN5bNU2K50WTZC7ty9W8nngt9ldYVkpJkAoZ0V_DCk1UN0ZXQU9FSFNISFYwNU9VTjFJWVVaWjBIVi4u')}>
              {fm('Udentity HS Tours')}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className='title'>
          {locale === 'en' ? <>FOR HIGH SCHOOLS & { !isMobile && <br/> } EDUCATORS</> : locale === 'zh' ? <>高中 & 老师</> : <>THẦY GIÁO</>}
        </div>
        <div className='content'>
          <ul>
            <li className='no-change'>{fm('College Exploration')}</li>
            <ul>
              <li onClick={() => handleNestedClick('FOR HIGH SCHOOLS & EDUCATORS:College Exploration', 'Recruitment Event Navigator', '/university-admissions-information-sessions')}>
                {fm('Recruitment Event Navigator')}
              </li>
              <li onClick={() => handleNestedClick('FOR HIGH SCHOOLS & EDUCATORS:College Exploration', 'Verified University Database', '/international-college-search')}>
                {fm('Verified University Database')}
              </li>
            </ul>
            <li onClick={() => handleNestedClick('FOR HIGH SCHOOLS & EDUCATORS', 'Summer Program Search', '/summer-programs-for-students')}>
              {fm('Summer Program Search')}
            </li>
            <li onClick={() => handleNestedClick('FOR HIGH SCHOOLS & EDUCATORS', 'HS Profile Consultation & Design', '/high-school-profiles')}>
              {fm('HS Profile Consultation & Design')}
            </li>
            <li onClick={() => handleNestedClick('FOR HIGH SCHOOLS & EDUCATORS', 'Customized College Events & Fairs', 'https://forms.office.com/Pages/ResponsePage.aspx?id=IN5bNU2K50WTZC7ty9W8nk2RWzN5QUtBltmgWk2LmZdUQ0oxNlA4TlZBUkNaOFVVU0VNR0JEWkM1OS4u')}>
              {fm('Customized College Events & Fairs')}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className='title'>
          {locale === 'en' ? <>FOR STUDENTS & { !isMobile && <br/> } PARENTS</> : locale === 'zh' ? <>学生 & 家长</> : <>HỌC SINH VÀ PHỤ HUYNH</>}
        </div>
        <div className='content'>
          <ul>
            <li className='no-change'>{fm('College Exploration')}</li>
            <ul>
              <li onClick={() => handleNestedClick('FOR STUDENTS & PARENTS:College Exploration', 'Recruitment Event Navigator', '/university-admissions-information-sessions')}>
                {fm('Recruitment Event Navigator')}
              </li>
              <li onClick={() => handleNestedClick('FOR STUDENTS & PARENTS:College Exploration', 'Verified University Database', '/international-college-search')}>
                {fm('Verified University Database')}
              </li>
            </ul>
            <li onClick={() => handleNestedClick('FOR STUDENTS & PARENTS', 'Summer Program Search', '/summer-programs-for-students')}>
              {fm('Summer Program Search')}
            </li>
          </ul>
        </div>
      </div>

      <div style={{marginLeft: !isMobile && locale === 'zh' ? 50 : 0}}>
        <div>
          <div className='rs-right-btn'>
            <span onClick={openSignup}>
              <span>{fm('Sign up for free')}</span>
              <img src={imgs['arrow circle right.svg']} />
            </span>
          </div>
          <div className='rs-right-btn'>
            <span onClick={() => handleNestedClick('FOR STUDENTS & PARENTS', 'Schedule a free consultation', 'https://arrangr.com/udentity')}>
              <span>{fm('Schedule a free consultation')}</span>
              <img src={imgs['arrow circle right.svg']} />
            </span>
          </div>
          <div className='img-panel'>
            <img src={imgs['image 2446.png']} />
            <div>
              {locale === 'en' ? <>Follow our <br/> WeChat Official Account</> : <>关注我们的<br/> 微信公众号</>}
            </div>
          </div>
          <div className='link' onClick={() => handleNestedClick('FOR STUDENTS & PARENTS', 'ABOUT UDENTITY', '/about-udentity')}>
            {fm('ABOUT UDENTITY')}
          </div>
          <div className='link' onClick={() => handleNestedClick('FOR STUDENTS & PARENTS', 'INSIGHTS', '/chinese-student-recruitment/strategy-solutions')}>
            {fm('INSIGHTS')}
          </div>
        </div>
      </div>
    </ForContainer>
  );

  return (
    <>
      <CustomPopover />
      <Popover placement="bottom"
               onVisibleChange={visible => setVisible(visible)}
               visible={visible} overlayClassName='cus-popover' content={forContent}>
        {children}
      </Popover>
    </>
  );
}

export default ForPanel
