import React from 'react';
import styled from "styled-components";
import importAllImg from "../../../helpers/import-all-imgs";
const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
  position: fixed;
  width: 30px;
  height: 30px;
  right: 17px;
  bottom: 30px;
  cursor: pointer;
  & > img { width: 100% }
`;

function ToTop() {
  if (window.innerWidth <= 991)  {
    return <Container onClick={() => window.scrollTo(0, 0)}><img src={imgs['Group 1310.svg']}/></Container>;
  } else {
    return null;
  }
}

export default ToTop;