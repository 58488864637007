import React, {useState, useRef, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { logo, logoName, translateNew } from "../../../helpers/images-helper";
import LangTag from "../../shared/public/LangTag";
import { useIntl } from 'react-intl';
import styled, { createGlobalStyle } from "styled-components";
import { NavLink, Route } from "react-router-dom";
import Modals from '../modals/Modals';
import './header.css'
import loadable from "@loadable/component";
import importAllImg from "../../../helpers/import-all-imgs";
import Notification from './Notification';
import { Popover } from 'antd';
import SolutionPanel from "./SolutionPanel";
import InsightsPanel from "./InsightsPanel";
import UserHeader from "./UserHeader";
import ForPanel from "./ForPanel";
import fairService from "../../../services/fairs/fair-service";
import useIsMobile from "../../../hooks/useIsMobile";

const imgs = importAllImg(require.context('../../../../src/assets/img/new-solutions', false, /\.(png|jpe?g|svg)$/));


const Container = styled.div`
  .lang-wrapper {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    cursor: pointer;
    & > img {
      margin-top: -10px;
      margin-right: 2px;
    }
    & > span > span {
      color: #1771C7 !important;
    }
    margin-right: 5px;
  }
  .link-icon {
    margin-left: 3px;
    margin-top: -3px;
    height: 19.7px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  height: 84px;
  //padding: 30px 30px 30px 50px;
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    flex: 3;
    column-gap: 22px;
    
    & > a, & > span {
      cursor: pointer;
      margin-right: 25px;
      text-decoration: none;
      color: rgb(0, 0, 0);
      position: relative;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      &:not(:first-child) {
        //display: inline-flex;
        height: 20px;
        //align-items: center;

      }


      /* Text/black */

      color: #1C1C1E;
      
      //&:not(:first-child)::after {
      //  height: 5px;
      //  content: "";
      //  position: absolute;
      //  background-color: rgb(23, 113, 199);
      //  width: 0;
      //  left: 0;
      //  bottom: -5px;
      //  transition: width 0.5s;
      //}
      //
      //&.selected, &:hover {
      //  &::after {
      //    width: 100%;
      //  }
      //}
      //
      //&:last-child {
      //  display: flex;
      //  align-items: center;
      //  & > img { margin-right: 5px; }
      //}
    }
  }
  
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 40px;
    justify-content: flex-end;
    & > button:nth-child(1) { margin-right: 40px;}
  }
`;

const MobileContainer = styled.div`
  border-radius: 0px 0px 8px 8px;
  //box-shadow: 4px 4px 4px #ededed;
  .sign-up-btns {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
    color: #1771C7;
    text-align: center;
    font-style: normal;
    line-height: normal;
    justify-content: space-between;
    align-items: center;
    min-width: 90px;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;

    & > div:nth-child(1) {
      margin-right: 10px;
    }
  }
  .lang-wrapper {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    cursor: pointer;
    & > img {
      margin-top: -10px;
      margin-right: 2px;
    }
    & > span {
      margin-left: 2px !important;
    }
    & > span > span {
      color: #1771C7 !important;
    }
    margin-right: 5px;
  }
  .lang-panel {
    display: inline-flex;
    align-items: center;

  }
  overflow-y: hidden;
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
  }
  
  .logo {
    margin: 0 10px;
  }
`;

const Panel = styled.div`
  //display: flex;
  height: ${props => props.show ? 'auto' : '0'};
  transition: all 0.5s;
  //flex-flow: column nowrap;
  //align-items: center;
  //padding-top: 10px;
  //padding: 0 5%;
  background: #F3F8FC;
  & > div {
    padding: 10px 5% 0 5%;

    background: #F3F8FC;

    & > div {
      padding-bottom: 10px;
      margin-bottom: 1px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid white;
      & > div {
        width: 40%;
        margin-left: -15%;
        text-align: right;

        & > a {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.1em;
          color: #000000;
          display: inline-flex;
          align-items: center;
          & > img {
            margin-right: -20px;
            margin-left: 10px;
          }

        }
      }

    }
  }
  & > div > a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: rgb(0, 0, 0);
    position: relative;
    & > img {
      margin-left: 5px;
    }
    //&::after {
    //  height: 5px;
    //  content: "";
    //  position: absolute;
    //  background-color: rgb(23, 113, 199);
    //  width: 0;
    //  left: 0;
    //  bottom: -5px;
    //  transition: width 0.5s;
    //}
    //
    //&.selected, &:hover {
    //  &::after {
    //    width: 100%;
    //  }
    //}

    &:last-child {
      display: flex;
      align-items: center;
      & > img { margin-right: 5px; }
    }
  }
`;


function Header({setUser, user}) {

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('signup');
  const [loginTab, setLoginTab] = useState(0)
  const [showPanel, setShowPanel] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const hasNoti = false;
  // const hasNoti = true;
  const { formatMessage, locale } = useIntl();
  const fm = id => formatMessage({id});

  const location = useLocation();

  const isMobile = useIsMobile();

  useEffect(() => {
    const params = {
      type: 'path',
      value: pathname
    }
    fairService.fairPageEvent2(
      params,
      'pv',
    );
  }, [pathname]);

  if (/\/event-wechat\/\d+/.test(pathname) || ['/science-wechat', '/science-rank-wechat', '/science-wechat-container', '/chat-list-mini'].includes(pathname)) {
    return null;
  }

  if (/\/private-wechat\/\d+/.test(pathname) || ['/npf-home', '/npf-projects', '/npf-contact', '/npf-blog'].includes(pathname)) return null;

  if (/\/npf-projects\/\d+/.test(pathname)) return null

  if (pathname === '/new-fair-wrapper') return null;

  if (user) {
    return (
      <>
        <UserHeader />
        { hasNoti && <Notification /> }
      </>
    )
  }

  // const isMobile = window.innerWidth <= 991;

  //

  const handleClick = (type, loginTab) => {
    setShowModal(true);
    setModalType(type);
    if (isMobile && type === 'login') {
      setLoginTab(loginTab);
    }
  };

  const navTo = path => {
    setShowPanel(false);
    setTimeout(() => history.push(path), 500);
  };


  if (isMobile) {
    return (
      <>
        <MobileContainer>
          <div>
            <ForPanel>
              <img  src={imgs['menu.svg']} />
            </ForPanel>
            <img className='logo' onClick={() => window.location.replace('/')} alt='Udentity logo' src={imgs['Logo (1).svg']}/>
            <div className='lang-wrapper'>
              <img alt='toggle lang icon' src={imgs['translate.svg']}/>
              <LangTag />
            </div>
            <div className='sign-up-btns'>
              <div id="login" onClick={() => handleClick('login', 0)}>
                {formatMessage({ id: "login.mobile" })}
              </div>
              <div id="signup" onClick={() => handleClick('login', 1)}>
                {formatMessage({ id: "signup.mobile" })}
              </div>
            </div>
          </div>

        </MobileContainer>
        { hasNoti && <Notification /> }
        <Modals show={showModal} setShow={setShowModal} t={modalType} setUser={setUser} loginTab={loginTab}/>
      </>
    );
  }

  return (
    <>
      <Container>
        <div>
          <a onClick={() => window.location.replace('/')} ><img alt='Udentity logo' src={logoName }/></a>
          {/*<InsightsPanel>*/}
          <ForPanel>
            <span>{fm('DIGITAL SOLUTIONS')}</span>
          </ForPanel>
          <ForPanel>
            <span>{fm('RECRUITMENT EVENTS')}</span>
          </ForPanel>
          <ForPanel>
            <span>{fm('DATABASES')}</span>
          </ForPanel>
          <ForPanel>
            <span>{fm('INSIGHTS')}</span>
          </ForPanel>
        </div>

        <div>
           <div className='lang-wrapper'>
              <img alt='toggle lang icon' src={translateNew}/>
              <LangTag />
           </div>
          <div id="login" className='login-btn' onClick={() => handleClick('login')}>
            {formatMessage({ id: "signup" })} / {formatMessage({ id: "LOGIN" })}
          </div>
        </div>
      </Container>
      { hasNoti && <Notification /> }
      <Modals show={showModal} setShow={setShowModal} t={modalType} setUser={setUser} />
    </>
  );

}

export default Header;
