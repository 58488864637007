import Cookies from 'universal-cookie';
import moment from "moment";
const cookies = new Cookies();

function getUser() {
    return cookies.get('udentity-user');
}

function getCookie(){
    return cookies.get('par.shangzhibo.sid')
}

function setUser(user) {
    if (window.localStorage) {
        try {
            localStorage.setItem('par.shangzhibo.sid', user.cookie_id);
        } catch (e) {
            if (e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                console.log(e);
            } else {
                console.log(e);
            }
        }
    }
    cookies.set("par.shangzhibo.sid", user.cookie_id, { domain: '.udty.tech', path: '/', expires: moment().add(10, "d").toDate() });
   	return cookies.set("udentity-user", user, { path: '/', expires: moment().add(10, "d").toDate() });
}

function removeUser() {
    localStorage.removeItem('udentity-uuid')
	cookies.remove("par.shangzhibo.sid", { domain: '.udty.tech', path: '/' });
    cookies.remove("shangzhibo.sid", { domain: 'live.udty.tech', path: '/' });
    return cookies.remove("udentity-user", { path: '/' });
}

function getLang() {
    return cookies.get('lang');
}

function setLang(lang) {
    return cookies.set('lang', lang);
}

function getUuid() {
    // return cookies.get('udentity-uuid') || '';
    return localStorage.getItem('udentity-uuid') || '';
}

function setUuid(uuid) {
    localStorage.setItem('udentity-uuid', uuid)
    // return cookies.set("udentity-uuid", uuid);
}


export default {
    getUser,
    setUser,
    removeUser,
    getCookie,
    getLang,
    setLang,
    getUuid,
    setUuid
}

