import userHelper from "../../../helpers/user-helper";
import {handleResponse} from "../../../helpers/api-helper";


function getDetail(id) {
  const url = `/api/v3/consultation/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function create(params) {
  const url = `/api/v3/consultation/create`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function update(params) {
  const url = `/api/v3/consultation/edit`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function cancel(id) {
  const url = `/api/v3/consultation/cancel/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function topTimeAnalysis() {
  const url = `/api/v3/consultation/topTimeAnalysis`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function userTypeAnalysis() {
  const url = `/api/v3/consultation/userTypeAnalysis`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function consultationsCalendar(year, month) {
  const url = `/api/v3/consultation/consultationsCalendar?year=${year}&month=${month}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function signUps(id) {
  const url = `/api/v3/consultation/signUps/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function signUps2(id) {
  const url = `/api/v3/consultation/signUps/${id}`;
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
}

function allSignUps(params) {
  const {date, keyword, pageNum, pageSize, type} = params
  let url = ''
  if (type) {
    url = `/api/v3/consultation/signUps?type=${type}&date=${date}&keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`;
  } else {
    url = `/api/v3/consultation/signUps?date=${date}&keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`;
  }
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function exportUsers(params) {

  const {date, keyword, pageNum, pageSize, type} = params
  let url = ''
  if (type) {
    url = `/api/v3/consultation/signUpsExport?type=${type}&date=${date}&keyword=${keyword}`;
  } else {
    url = `/api/v3/consultation/signUpsExport?date=${date}&keyword=${keyword}`;
  }
  const user = userHelper.getUser();
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");
  user &&
  user.auth_token &&
  myHeaders.append("Authorization", "Basic " + user.auth_token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    // body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}




export default {
  create,
  getDetail,
  cancel,
  update,
  topTimeAnalysis,
  userTypeAnalysis,
  consultationsCalendar,
  signUps,
  allSignUps,
  exportUsers
}
