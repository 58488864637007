import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import Popper from 'popper.js';
import bootstrap from 'bootstrap';
import $ from 'jquery';
import AOS from 'aos';

import 'aos/dist/aos.css';
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import {loadData} from "./app/utils";

window.$ = $;
AOS.init({duration : 2000});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
}

const renderPage = () => {
  ReactDOM.render(
    <Provider store={store().store}>
      <PersistGate loading={null} persistor={store().persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("app")
  );
};

(async () => {
  try {
    await loadData();
    renderPage();
  } catch (e) {
    console.error(e)
    renderPage();
  }
})();


