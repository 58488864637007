import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import importAllImg from '../../../helpers/import-all-imgs';
import { useIntl } from 'react-intl';
import eventService from "../../../services/student/event-service";
const imgs = importAllImg(require.context('../../../../src/assets/img/event', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
  margin-top: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #757575;

  @media (max-width: 991px) {
    padding: 0 5% 10px 5%;
    border-bottom: none;
  }
  .uni-panel {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    //cursor: pointer;
    width: fit-content;
    @media (max-width: 991px) {
      font-weight: bold;
      font-size: 12px;
      color: #000000;
    }
    & > img {
      width: 48px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  
  .title {
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0;
    @media (max-width: 991px) {
      font-weight: bold;
      font-size: 12px;
      color: #000000;
    }
  } 
  
  .content {
    font-size: 14px;
    color: #1C1C1E;
    @media (max-width: 991px) {
      font-size: 11px;
    }
  }
  
  .person_panel {
    display: flex;
    margin-bottom: 10px;
    & > div:nth-child(1) {
      flex: 4;
    }
    
    & > div:nth-child(2) {
      flex: 1;
      text-align: end;
      padding-right: 10px;
      & > img {
        height: 100%;
        max-height: 200px;
        @media (max-width: 991px) {
          max-height: 100px;
        }
      }
    }
  }
  
  .download-panel {
    & > button {
      display: flex;
      align-items: center;
      background: #F2F2F7;
      border-radius: 14px;
      width: 200px;
      height: 42px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #1771C7;
      @media (max-width: 991px) {
        width: 200px;
        font-weight: bold;
        font-size: 12px;
      }
      & > img {
        width: 14px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }
  
  .tag_panel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 11px !important;
    }
    & > span:first-child {
      font-weight: bold;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }
    
    & > span:not(:first-child) {
      color: #1771C7;
      background: #F2F2F7;
      border-radius: 27px;
      padding: 5px 10px;
      margin-left: 5px;
    }
  }
  
  .show_panel {
    margin-top: 10px;
    cursor: pointer;
    width: fit-content;
    font-weight: bold;
    font-size: 14px;
    color: #757575;
    @media (max-width: 991px) {
      font-size: 11px;
    }
  }
`;

function Description({event, notLogin, noCn, isCie}) {

  const [less, setLess] = useState(true);
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isCie) setLess(false);
  }, []);

  const handleDownLoad = () => {
    if (notLogin()) return
    if (event.attachments.length > 0) {
      eventService.eventAction('download', event.id).then(() => {});
      const file = event.attachments[0];
      window.open(file);
    }
  };

  const jumpToUni = () => {
    // eventService.eventAction('profile', event.id).then(() => {});
    history.push(`/university/${event.universityId}`);
  };


  return (
    <Container>

      <div className='uni-panel' style={{display: isCie ? 'none' : 'flex'}}>
        <img src={event.universityLogo} />
        <div>
          {event.universityName} <br/>
          {!noCn && event.universityNameChinese}
        </div>
      </div>

      { event.attachments && event.attachments.length > 0 && (<>
      <div className='title'>{formatMessage({ id: "resources" })}</div>
      <div className='download-panel'>
        <button className='btn' onClick={handleDownLoad}>
          <img src={imgs['download.svg']} />
          {formatMessage({ id: "download" })}
        </button>
      </div>
      </>)}

      <div className='title'>{formatMessage({ id: "eventdescription" })}</div>
      <div className='content'>
        { less ? event.description.slice(0, 120) +  (event.description.length > 120 ?  '...' : '' ): event.description }
      </div>

      { !less && (<>
      <div className='person_panel'>
        <div>
          { event.speakerName && <div className='title'>{event.speakerName}</div> }
          { event.jobTitle && <div className='title' style={{color: '#757575'}}>{event.jobTitle}</div> }
          { event.speakerBio && <div className='content'>{event.speakerBio}</div> }
        </div>
        <div>
          { event.speakerImage && <img src={event.speakerImage} /> }
        </div>
      </div>

      <div className='tag_panel' style={{display: isCie ? 'none' : 'flex'}}>
        <span>{formatMessage({ id: "tag" })}</span>
        {
          event.tags.map(i => <span key={i}>{i}</span>)
        }
      </div>
      </>)}

      <div className='show_panel' onClick={() => setLess(i => !i)}>
        { less ? formatMessage({id: 'showmore'}) : formatMessage({id: 'showless'}) }
      </div>
    </Container>


  );
}

export default Description;
