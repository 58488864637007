import {
  CHANGE_LANG,
  TOKEN_EXPIRED,
  DISPLAY_BANNER,
  ADMIN_STATUS
} from "./actionTypes";

export const changeLang = lang => ({
  type: CHANGE_LANG,
  payload: lang
});

export const changeTokenExpired = tokenExpired => ({
  type: TOKEN_EXPIRED,
  payload: tokenExpired
});
export const displayBanner = banner => ({
  type: DISPLAY_BANNER,
  payload: banner
});

export const displayAdmin = admin => ({
  type: ADMIN_STATUS,
  payload: admin
});
