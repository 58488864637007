import axios from 'axios'
import userHelper from './user-helper'

// 集中处理请求时所需配置
axios.interceptors.request.use(config => {
  const user = userHelper.getUser()
  config.headers['Content-Type'] = 'application/json'
  if(user && user.auth_token){
  config.headers.Authorization = `Bearer ${user.auth_token}`
  }
  return config
  
})


axios.interceptors.response.use(response => {
  return response.data
})

const get = api => (params, config) => axios.get(api, { params, ...config })
const post = api => (params, config) => axios.post(api, params, config)
const del = api => (params, config) => axios.delete(api, params, config)
const put = api => (params, config) => axios.put(api, params, config)

export { get, post, del, put }